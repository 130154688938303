import { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useLazyQuery } from '@apollo/client'
import { CircularProgress } from '@mui/material'
import { MColor, MFlexBlock, MText, MTextColor } from '@mprise/react-ui'
import { ResourceSubset, useAppSettingsContext } from '../../context/AppSettingsContext'
import { Card } from '../../components/Card'
import { PageHeader } from '../../components/Header'
import { MaterialIcon } from '../../components/Icon'
import { List, ListItem } from '../../components/List'
import { SearchInput } from '../../components/SearchInput'
import { Section, SectionList } from '../../components/Section'
import { useHistory } from '../../shared/use-history'
import { useDebounceValue } from '../../shared/debounce-value'
import { GET_RESOURCES } from '../../gql/resources'
import { QueryErrorMessage } from '../../shared/apollo'

export const SettingsResourceRoute = () => {
  const { t } = useTranslation()
  const h = useHistory()

  const [search, setSearch] = useState('')
  const debouncedSearch = useDebounceValue(search, 500)

  const [getResources, { data, loading, error, refetch }] = useLazyQuery(GET_RESOURCES, {
    onError: console.error,
  })

  const { company, resource: currentResource, setResource } = useAppSettingsContext()

  useEffect(() => {
    getResources({
      variables: {
        filter: {
          companyId: +company?.id!,
          ...(debouncedSearch && { searchString: debouncedSearch }),
          type: 'Person',
          blocked: false,
          removed: false,
        },
        limit: 50,
        offset: 0,
      },
    })
  }, [company?.id, debouncedSearch, getResources])

  const handleCancel = () => h.goBack()

  const handleSave = (selected: ResourceSubset) => {
    setResource(selected)
    h.goBack()
  }

  return (
    <>
      <PageHeader title={t('TITLE_SETTINGS')} onCancel={handleCancel} />

      <QueryErrorMessage query={{ error, refetch, loading }} />
      <SectionList>
        <Section>
          <Card header={t('TITLE_CHOOSE_RESOURCE')}>
            <div className='ml-search'>
              <SearchInput onChange={setSearch} />
            </div>
            <List>
              {data?.resources.page?.map((resource: any, idx: number) => (
                <ListItem
                  key={resource?.id ?? idx}
                  icon={<MaterialIcon value='person' style={{ fontSize: '2rem' }} />}
                  primary={resource?.name}
                  action={
                    currentResource?.id === resource?.id ? (
                      <MaterialIcon value='check' style={{ color: MColor.primary }} />
                    ) : null
                  }
                  onClick={() =>
                    handleSave({
                      id: resource.id,
                      name: resource.name ?? '',
                      code: resource.code ?? '',
                      cosmosKey: resource.cosmosKey ?? '',
                    })
                  }
                />
              ))}
            </List>
          </Card>
        </Section>
        {loading && (
          <MFlexBlock padding={[4]} justifyContent='center'>
            <CircularProgress size={30} color='inherit' />
          </MFlexBlock>
        )}
        {!data?.resources?.page?.length && !loading ? (
          <MFlexBlock padding={[4]} justifyContent='center'>
            <MText block textVariant='content bold' textColor={MTextColor.disabled}>
              {t('NO_RESULTS')}
            </MText>
          </MFlexBlock>
        ) : null}
      </SectionList>
    </>
  )
}
