import { gql } from '@apollo/client'

export const REPORT_JOB_INVENTORY_PUTAWAY = gql`
  mutation registerJobInventoryPutAway(
    $workItemId: ID!
    $taskId: ID!
    $taskPutAwayId: ID!
    $values: TaskPutAwayInput!
  ) {
    registerJobInventoryPutAway(
      workItemId: $workItemId
      taskId: $taskId
      taskPutAwayId: $taskPutAwayId
      values: $values
    ) {
      taskPutAwayId
      autoFinished
    }
  }
`
