import { useTranslation } from 'react-i18next'
import { MColor } from '@mprise/react-ui'
import { NumberInputMethod, useAppSettingsContext } from '../../context/AppSettingsContext'
import { Card } from '../../components/Card'
import { PageHeader } from '../../components/Header'
import { MaterialIcon } from '../../components/Icon'
import { List, ListItem } from '../../components/List'
import { Section, SectionList } from '../../components/Section'
import { useHistory } from '../../shared/use-history'

export const SettingsDefaultNumberInputRoute = () => {
  const { t } = useTranslation()
  const h = useHistory()

  const { defaultNumberInputMethod, setDefaultNumberInputMethod } = useAppSettingsContext()

  const handleCancel = () => h.goBack()

  const handleSave = (selected: NumberInputMethod) => {
    setDefaultNumberInputMethod(selected)
    h.goBack()
  }

  return (
    <>
      <PageHeader title={t('TITLE_SETTINGS')} onCancel={handleCancel} />

      <SectionList>
        <Section>
          <Card header={t('SETTING_DEFAULT_NUMBER_INPUT')}>
            <List>
              {Object.values(NumberInputMethod).map(numberInputMethod => (
                <ListItem
                  key={numberInputMethod}
                  icon={<MaterialIcon value='calculate' style={{ fontSize: '2rem' }} />}
                  primary={t(numberInputMethod)}
                  action={
                    defaultNumberInputMethod === numberInputMethod ? (
                      <MaterialIcon value='check' style={{ color: MColor.primary }} />
                    ) : null
                  }
                  onClick={() => handleSave(numberInputMethod)}
                />
              ))}
            </List>
          </Card>
        </Section>
      </SectionList>
    </>
  )
}
