import { gql } from '@apollo/client'

export const START_TIME_REG = gql`
  mutation startTimeReg($workItemId: ID!, $taskId: ID!, $resourceId: ID!) {
    startTimeReg(resourceIds: [$resourceId], anonymousResourceCount: 0, workItemIds: [$workItemId], taskIds: [$taskId])
  }
`

export const STOP_TIME_REG = gql`
  mutation stopTimeReg($workItemId: ID!, $taskId: ID!) {
    stopTimeReg(workItemIds: [$workItemId], taskIds: [$taskId])
  }
`
