import { gql } from '@apollo/client'

export const BULK_REPORT_JOB_INVENTORY_PUTAWAY = gql`
  mutation bulkRegisterJobInventoryPutAway(
    $workItemId: Int!
    $taskId: Int!
    $reports: [TaskPutAwayBatchInput!]!
  ) {
    bulkRegisterJobInventoryPutAway(
      workItemId: $workItemId
      taskId: $taskId
      reports: $reports
    ) {
      autoFinished
    }
  }
`
