import { Formik, MAudio, withFormikCompareFix } from '@mprise/react-ui'
import { useTranslation } from 'react-i18next'
import { useHistory } from '../../../shared/use-history'
import { useLocalState } from '../../../shared/local-state'
import { FlashAlerts } from '../../../shared/flash-alerts'
import { SavingSwitchPanel } from '../../../shared/saving-switch-panel'
import { MutationErrorMessage } from '../../../shared/apollo'
import { ValidationIssues } from '../../../mprise-light/ValidationIssues'
import { useAppSettingsContext } from '../../../context/AppSettingsContext'
import { DialogFormik } from '../../../mprise-light/DialogFormik'
import { useParams } from 'react-router'
import { Helmet } from 'react-helmet'
import { fail } from '../../../shared/typescript'
import { useApolloClient, useMutation, useQuery } from '@apollo/client'
import { WORKTASK_BY_ID_WITH_TASK_RESULTS } from '../../../gql/query/workItems/workTaskByIdWithTaskResults'
import { STOP_TASK } from '../../../gql/mutation/statusChange/statusChange'
import { Maybe } from '../../../shared/enums'
import { CropMaintenanceForm } from './CropMaintenanceForm'
import { CHANGE_ACTUAL_QUANTITY } from '../../../gql/mutation/changeActualQuantity'
import { STOP_TIME_REG } from '../../../gql/mutation/timeReg'
import { useEffect } from 'react'
import { parseFloatQuantity } from '../../../shared/formats'

export interface CropMaintenanceEntryForm {
  quantity: Maybe<number>
  reportedQuantity: Maybe<number>
}

export const CropMaintenanceRoute = () => {
  const h = useHistory()
  const schema = CropMaintenanceForm.useSchema()
  const { t } = useTranslation()
  const alerts = FlashAlerts.useAlert()
  const apollo = useApolloClient()
  const cache = apollo.cache
  const resourceId = useAppSettingsContext().resource?.id ?? h.push('/')
  const { numberFormat } = useAppSettingsContext()

  const workItemId = useParams().workItemId!
  const taskId = useParams().taskId!
  const [stopTaskMutation, stopTaskState] = useMutation(STOP_TASK)

  const [stopTimeReg] = useMutation(STOP_TIME_REG)

  const [changeActualQuantity, changeActualQuantityState] = useMutation(CHANGE_ACTUAL_QUANTITY)

  const taskQuery = useQuery(WORKTASK_BY_ID_WITH_TASK_RESULTS, {
    variables: {
      where: [{ field: 'id', options: { eq: +taskId } }],
      filter: { mandatoryType: 'JOB_WORK_ORDER' },
    },
  })
  const task = taskQuery.data?.nworkTask
  const plannedQuantity = task?.workItem.plannedQuantity ?? 0
  const reportedQuantity = task?.workItem.actualQuantity ?? 0
  const workItemCosmosKey = task?.workItem.cosmosKey
  const taskCosmosKey = task?.cosmosKey

  const [initialValues] = useLocalState((): CropMaintenanceEntryForm => {
    return withFormikCompareFix({
      quantity: plannedQuantity - reportedQuantity,
      reportedQuantity: reportedQuantity ?? 0,
    })
  }, [task])

  const handleClose = async (autoFinished?: boolean, e?: React.FormEvent<Element>) => {
    e?.preventDefault()
    e?.stopPropagation()

    if (workItemId && taskId && !autoFinished) {
      if (!resourceId) {
        fail('expects resource id')
      }

      await stopTaskMutation({
        variables: {
          workItemId: +workItemId,
          taskId: +taskId,
          currentResourceId: +resourceId,
        },
        refetchQueries: [WORKTASK_BY_ID_WITH_TASK_RESULTS],
      }).then(response => {
        const task = response.data.stopTask
        stopTimeReg({
          variables: {
            workItemId: task.workItem.cosmosKey,
            taskId: task.cosmosKey,
          },
        })
      })
    }
    h.goBack()
  }

  const handleSubmit = async (
    form: CropMaintenanceEntryForm,
    actions: Formik.FormikHelpers<CropMaintenanceEntryForm>,
  ) => {
    if (workItemId && taskId) {
      const newReportedQuantity = (form.reportedQuantity ?? 0) + parseFloatQuantity(form.quantity, numberFormat)

      const result = await changeActualQuantity({
        variables: { workItemId: +workItemId, actualQuantity: newReportedQuantity },
      })
      if (result.data) {
        MAudio.scanSuccess()
        alerts.push(t('SUCCESS_MESSAGE'), `success`)

        const autoFinished = result.data.changeActualQuantity?.autoFinished ?? false
        if (workItemCosmosKey && taskCosmosKey && autoFinished) {
          cache.evict({ id: 'ROOT_QUERY', fieldName: 'searchWorkItems' })
          stopTimeReg({
            variables: {
              workItemId: workItemCosmosKey,
              taskId: taskCosmosKey,
            },
          })
          handleClose(autoFinished)
        } else {
          actions.setFieldValue('reportedQuantity', newReportedQuantity)
        }
      }
    }
  }

  return (
    <>
      <Helmet title={t('CROP_MAINTENANCE')} />
      <Formik.Formik enableReinitialize initialValues={initialValues} validationSchema={schema} onSubmit={handleSubmit}>
        <DialogFormik minWidth='xl' title={t('CROP_MAINTENANCE')} onClose={e => handleClose(false, e)} open={true}>
          <SavingSwitchPanel mutation={[changeActualQuantityState, stopTaskState]}>
            <ValidationIssues />
            <MutationErrorMessage mutation={[changeActualQuantityState, stopTaskState]} />
            {task && <CropMaintenanceForm task={task} />}
          </SavingSwitchPanel>
        </DialogFormik>
      </Formik.Formik>
    </>
  )
}
