import { CircularProgress, Fade } from '@mui/material'
import { mdiRefresh } from '@mdi/js'
import Icon from '@mdi/react'
import { px } from '@mprise/react-ui'
import { SwitchTransition } from 'react-transition-group'

export const RefreshIcon = ({ size = 1, loading }: { size?: number; loading: Boolean }) => {
  return (
    <SwitchTransition>
      <Fade key={Number(loading)}>
        {loading ? (
          <CircularProgress color='inherit' size={px(24 * size)} />
        ) : (
          <Icon size={px(24 * size)} path={mdiRefresh} />
        )}
      </Fade>
    </SwitchTransition>
  )
}
