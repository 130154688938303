import { Collapse } from '@mui/material'
import { MColor, MField, MFlex, MText } from '@mprise/react-ui'
import IconWarning from '@mui/icons-material/Warning'

export const ValidationFriendlyWarning = ({
  open,
  text,
  color,
}: {
  open: boolean
  text: string
  color?: MColor
}): JSX.Element => {
  return (
    <Collapse in={open}>
      <MField.Container vertical>
        <MField.Content>
          <MFlex alignItems='center'>
            <IconWarning style={{ color: color ?? MColor.medium }} />
            <MText block textColor={color ?? MColor.medium} style={{ paddingLeft: '0.5rem' }}>
              {text}
            </MText>
          </MFlex>
        </MField.Content>
      </MField.Container>
    </Collapse>
  )
}
