import React, { FormEvent, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { LocalDate } from '@js-joda/core'
import { Locale as JsJodaLocale } from '@js-joda/locale_en-us'
import { Button } from '@mui/material'
import { Breakpoint } from '@mui/material'
import { DateCalendar, LocalizationProvider } from '@mui/x-date-pickers'
import { MBlock, MColor, MDialog, MFlexBlock } from '@mprise/react-ui'
import { DialogHeaderBack } from '../components/Header'
import { MuiAdapterJoda } from '@mprise/react-ui/dist/utils/MuiAdapterJoda'

// Copied and adjusted from react-ui MDateDialog.

export const DateDialog = ({
  title,
  value,
  open,
  onClose,
  minWidth = 'sm',
}: {
  title: React.ReactNode
  value?: LocalDate
  open: boolean
  onClose: (value?: LocalDate) => void
  minWidth?: number | Breakpoint
}) => {
  const { t } = useTranslation()

  const [local, setLocal] = useState<LocalDate | undefined>(undefined)
  const handleClear = () => {
    onClose(undefined)
  }
  const handleToday = () => {
    onClose(LocalDate.now())
  }
  const handleClose = () => {
    onClose(value)
  }
  const handleSubmit = (e: FormEvent) => {
    e.preventDefault()
    e.stopPropagation()
    onClose(local)
  }
  const handleChange = (date: LocalDate | null) => {
    setLocal(date!)
    onClose(date!)
  }
  useEffect(() => {
    setLocal(value)
  }, [value, open])

  return (
    <MDialog.Dialog minWidth={minWidth} open={open} onClose={handleClose}>
      <MDialog.Form onSubmit={handleSubmit}>
        <DialogHeaderBack title={title} onClose={handleClose} />
        <MDialog.Content style={{ padding: 0, zIndex: 0 }}>
          {/* @ts-ignore */}
          <LocalizationProvider dateAdapter={MuiAdapterJoda} adapterLocale={JsJodaLocale.US}>
            <MBlock padding={2} bgColor={MColor.white} justifyContent='center'>
              <div style={{ overflow: 'hidden' }}>
                <DateCalendar value={local ?? LocalDate.now()} onChange={handleChange} />
              </div>
            </MBlock>
          </LocalizationProvider>
        </MDialog.Content>
        <MDialog.Footer>
          <MFlexBlock justifyContent='center' gap={2}>
            <Button variant='contained' onClick={handleClear}>
              {t('CLEAR')}
            </Button>
            <Button variant='contained' onClick={handleToday}>
              {t('TODAY')}
            </Button>
          </MFlexBlock>
        </MDialog.Footer>
      </MDialog.Form>
    </MDialog.Dialog>
  )
}
