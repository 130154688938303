import { gql } from '@apollo/client'

export const CARRIERS_BY_FILTER = gql`
  query carriersByFilter($filter: PositionCarrierFilter, $limit: Int, $offset: Int) {
    positionCarriers(filter: $filter, limit: $limit, offset: $offset) {
      page {
        id
        name
        code
      }
      total
    }
  }
`

export const CARRIER_BY_CODE = gql`
  query carrierByCode($filter: PositionCarrierFilter) {
    positionCarrier(filter: $filter) {
      id
      name
      code
    }
  }
`
