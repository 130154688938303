import { gql } from '@apollo/client'

export const FragmentJobInventoryPutAway = gql`
  fragment FragmentJobInventoryPutAway on WorkJobInventoryPutAway {
    id
    fulfilled
    planned {
      id
      position {
        id
        code
        name
      }
      quantity
      quantityUnit
      quantityPerArea
      quantityPerAreaUnit
      quantityPerUnitOfMeasure
      sorting
      spacingMethod {
        id
        description
        code
      }
    }
    reported {
      id
      resource {
        id
      }
      position {
        id
        code
        name
      }
      quantity
      quantityUnit
      quantityPerArea
      quantityPerAreaUnit
      quantityPerUnitOfMeasure
      sorting
    }
  }
`
