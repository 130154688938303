import { useRouteError } from 'react-router'
import { Button } from '@mui/material'
import { MColor, MFlex, MText } from '@mprise/react-ui'
import { Caption } from './components/Caption'
import { DialogHeader } from './components/Header'
import { MaterialIcon } from './components/Icon'

export function ErrorBoundary() {
  const error = useRouteError() as { message: string }

  function clearState() {
    localStorage.clear()
    sessionStorage.clear()
    window.location.href = '/'
  }

  function refresh() {
    window.location.reload()
  }

  return (
    <>
      <DialogHeader title={'Oops!'} />
      <Caption icon={<MaterialIcon value='error' style={{ fontSize: '5rem', margin: '1rem' }} />}>
        An error occured
      </Caption>
      <MFlex justifyContent='space-evenly'>
        <Button
          style={{ backgroundColor: MColor.medium, color: MColor.paper, margin: '1rem', width: '10rem' }}
          variant='contained'
          onClick={clearState}
        >
          Clear state
        </Button>
        <Button
          style={{ backgroundColor: MColor.primary, color: MColor.paper, margin: '1rem', width: '10rem' }}
          variant='contained'
          onClick={refresh}
        >
          Reload
        </Button>
      </MFlex>
      <MFlex justifyContent='center' style={{ padding: '2rem' }}>
        <MText block>Error: {error?.message}</MText>
      </MFlex>
    </>
  )
}
