import { LocalDate, LocalDateTime, LocalTime, ZonedDateTime } from '@js-joda/core'

export type Maybe<T> = T | null
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K]
}
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>
}
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>
}

export type Scalar_Date = LocalDate
export type Scalar_Time = LocalTime
export type Scalar_DateTime = LocalDateTime

export type Scalar_LocalDate = LocalDate
export type Scalar_LocalTime = LocalTime
export type Scalar_LocalDateTime = LocalDateTime

export type Scalar_ZonedDateTime = ZonedDateTime

export enum AppName {
  GreenhouseApp = 'GREENHOUSE_APP',
  WorkApp = 'WORK_APP',
  InspectApp = 'INSPECT_APP',
}

export enum TeamResourceMode {
  List = 'LIST',
  Count = 'COUNT',
}

export enum WorkPriority {
  None = 'NONE',
  Low = 'LOW',
  Medium = 'MEDIUM',
  High = 'HIGH',
}

export enum WorkResultType {
  ItemConsumption = 'ITEM_CONSUMPTION',
  JobInventoryConsumption = 'JOB_INVENTORY_CONSUMPTION',
  ItemInventoryPicking = 'ITEM_INVENTORY_PICKING',
  ItemInventoryPutaway = 'ITEM_INVENTORY_PUTAWAY',
  JobInventoryPicking = 'JOB_INVENTORY_PICKING',
  JobInventoryPutaway = 'JOB_INVENTORY_PUTAWAY',
  JobInventoryOutput = 'JOB_INVENTORY_OUTPUT',
  ItemOutput = 'ITEM_OUTPUT',
}

export enum WorkItemType {
  AdHoc = 'AD_HOC',
  JobWorkOrder = 'JOB_WORK_ORDER',
  JobPickOrder = 'JOB_PICK_ORDER',
}

export enum WorkStatus {
  AwaitingHours = 'AWAITING_HOURS',
  NotAssigned = 'NOT_ASSIGNED',
  Todo = 'TODO',
  InProgress = 'IN_PROGRESS',
  OnHold = 'ON_HOLD',
  Done = 'DONE',
  Closed = 'CLOSED',
  Pending = 'PENDING',
  Rejected = 'REJECTED',
}

export enum WorkAssignment {
  Assigned = 'ASSIGNED',
  NotAssigned = 'NOT_ASSIGNED',
}

export enum WorkItemTemplateTaskOption {
  PickPrefillDefaultPosition = 'PICK_PREFILL_DEFAULT_POSITION',
  PickPrefillDefaultJob = 'PICK_PREFILL_DEFAULT_JOB',
  PickPrefillDefaultOutputPosition = 'PICK_PREFILL_DEFAULT_OUTPUT_POSITION',
  PickBlockChangingOutputPosition = 'PICK_BLOCK_CHANGING_OUTPUT_POSITION',
  PickSpecifyResource = 'PICK_SPECIFY_RESOURCE',
  PickPrefillRemainingQuantity = 'PICK_PREFILL_REMAINING_QUANTITY',
  PickAllowDeletionResultLines = 'PICK_ALLOW_DELETION_RESULT_LINES',
  OffloadCarriersGh = 'OFFLOAD_CARRIERS_GH',
  PutOnCarriersGh = 'PUT_ON_CARRIERS_GH',
  EnableMultiLocationScanGh = 'ENABLE_MULTI_LOCATION_SCAN_GH',
  MoveCarriersToLocationGh = 'MOVE_CARRIERS_TO_LOCATION_GH',
  AdvancedAreaRegistrationGh = 'ADVANCED_AREA_REGISTRATION_GH',
  AutoFillConsumptionQuantityGh = 'AUTO_FILL_CONSUMPTION_QUANTITY_GH',
  AutoStartStopTasksGh = 'AUTO_START_STOP_TASKS_GH',
  AllowOverruleLotReservationGh = 'ALLOW_OVERRULE_LOT_RESERVATION_GH',
  CropMaintenanceGh = 'CROP_MAINTENANCE_GH',
  BypassJobInventoryGh = 'BYPASS_JOB_INVENTORY_GH',
}

export enum ResourceReportOption {
  None = 'NONE',
  ConsumptionLines = 'CONSUMPTION_LINES',
  Timesheet = 'TIMESHEET',
}
export interface Scalars {
  ID: string
  String: string
  Boolean: boolean
  Int: number
  Float: number
  Date: unknown
  /** The `DateTime` scalar represents an ISO-8601 compliant date time type. */
  DateTime: unknown
  /** Date custom scalar type */
  LocalDate: Scalar_LocalDate
  /** Date custom scalar type */
  LocalDateTime: Scalar_LocalDateTime
  /** Date custom scalar type */
  LocalTime: Scalar_LocalTime
  /** A time string at UTC, such as 10:15:30Z, compliant with the `full-time` format outlined in section 5.6 of the RFC 3339profile of the ISO 8601 standard for representation of dates and times using the Gregorian calendar. */
  Time: unknown
  Uuid: string
  ZonedDate: unknown
  /** Date custom scalar type */
  ZonedDateTime: Scalar_ZonedDateTime
  ZonedTime: unknown
}
