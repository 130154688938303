import { useTranslation } from 'react-i18next'
import { AppLanguage, availableLanguages, useAppSettingsContext } from '../../context/AppSettingsContext'
import { Card } from '../../components/Card'
import { PageHeader } from '../../components/Header'
import { MaterialIcon } from '../../components/Icon'
import { List, ListItem } from '../../components/List'
import { Section, SectionList } from '../../components/Section'
import { useHistory } from '../../shared/use-history'
import { MColor } from '@mprise/react-ui'

export const SettingsLanguageRoute = () => {
  const { t } = useTranslation()
  const h = useHistory()

  const { language, setLanguage } = useAppSettingsContext()

  const handleCancel = () => h.goBack()

  const handleSave = (selected: AppLanguage) => {
    setLanguage(selected)
    h.goBack()
  }

  return (
    <>
      <PageHeader title={t('TITLE_SETTINGS')} onCancel={handleCancel} />

      <SectionList>
        <Section>
          <Card header={t('TITLE_CHOOSE_LANGUAGE')}>
            <List>
              {availableLanguages
                .map(x => ({ id: x, name: t(`LANGUAGE_${x.toUpperCase()}`) }))
                .map(x => (
                  <ListItem
                    key={x.id}
                    icon={<MaterialIcon value='language' style={{ fontSize: '2rem' }} />}
                    primary={x.name}
                    action={
                      language.id === x.id ? <MaterialIcon value='check' style={{ color: MColor.primary }} /> : null
                    }
                    onClick={() => handleSave(x)}
                  />
                ))}
            </List>
          </Card>
        </Section>
      </SectionList>
    </>
  )
}
