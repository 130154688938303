import { gql } from '@apollo/client'

export const OUTBOUND_BY_WORKITEM_ID = gql`
  query outboundByWorkItemId($workItemId: Int!) {
    outboundJobInventoryByWorkItemId(workItemId: $workItemId) {
      id
      quantity
      carrierCode
      sortingCode
    }
  }
`
