import { useTranslation } from 'react-i18next'
import { MDialog, MDialogContentText } from '../../mprise-light/Dialog'
import { MColor, MText } from '@mprise/react-ui'
import IconWarning from '@mui/icons-material/Warning'
import { Maybe } from '../enums'

export const IncorrentItemDialog = ({
  expectedItem,
  scannedItem,
  onClose,
}: {
  expectedItem?: Maybe<string>
  scannedItem?: Maybe<string>
  onClose: () => void
}) => {
  const { t } = useTranslation()

  return (
    <MDialog open={true} title={t(`INCORRECT_ITEM`)} onSubmit={onClose} onClose={onClose}>
      <MDialogContentText>
        <div style={{ padding: '1rem' }}>
          <div style={{ display: 'flex', paddingBottom: '1rem', gap: '1rem', justifyContent: 'center' }}>
            <IconWarning style={{ color: MColor.medium }} />
            <MText block textVariant='content bold'>
              {t('INCORRECT_ITEM')}
            </MText>
          </div>
          <MText block>{`${t('EXPECTED_ITEM')}: ${expectedItem}`}</MText>
          <MText block>{`${t('SCANNED_ITEM')}: ${scannedItem}`}</MText>
        </div>
      </MDialogContentText>
    </MDialog>
  )
}
