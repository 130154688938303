import { gql } from '@apollo/client'

export const SEARCH_WORKITEMS_WITH_FILTER = gql`
  query searchWorkItemsWithFilter($filter: SearchWorkItemsFilter, $limit: Int, $offset: Int) {
    searchWorkItems(filter: $filter, limit: $limit, offset: $offset) {
      page {
        id
        number
        name
        itemDescription
        status
        scheduledDate
        jobs {
          id
          code
        }
      }
      total
    }
  }
`
