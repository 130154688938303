import { gql } from '@apollo/client'

export const JOB_INVENTORY_BY_FILTER = gql`
  query jobInventoryDetailsByFilter($filter: JobInventoryDetailFilter!) {
    jobInventoryDetails(filter: $filter) {
      id
      job {
        id
        name
        code
        item {
          id
          name
        }
      }
      position {
        id
        name
        code
      }
      pillar
      remainingQuantity
      sortingCode
      productionPhase {
        id
        productionPhase
        startingDate
      }
      unitOfMeasure
      carrierCode
      remQtyPerUnitOfMeasure
    }
  }
`
