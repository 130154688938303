import { gql } from '@apollo/client'

export const REPORT_TRANSFER_TO_POSITION = gql`
  mutation transferPositionJobInventoryDetailGH(
    $originJobInventoryDetailId: ID!
    $destinationPositionId: ID!
    $quantity: Float!
    $resourceId: ID!
    $destinationPhase: String
    $workItemId: ID
    $pillar: Int
    $spacingMethodId: Int
  ) {
    transferPositionJobInventoryDetailGH(
      originJobInventoryDetailId: $originJobInventoryDetailId
      destinationPositionId: $destinationPositionId
      quantity: $quantity
      resourceId: $resourceId
      destinationPhase: $destinationPhase
      workItemId: $workItemId
      pillar: $pillar
      spacingMethodId: $spacingMethodId
    ) {
      updatedOrigin {
        id
        remainingQuantity
      }
      updatedDestination {
        id
        remainingQuantity
      }
      workItemId
      autoFinished
    }
  }
`
