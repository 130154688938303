import React, { useEffect } from 'react'
import { useLazyQuery } from '@apollo/client'
import { MFlexBlock, MSelectItem, ToMSelectItem } from '@mprise/react-ui'
import { MaterialIcon } from '../../components/Icon'
import { useAppSettingsContext } from '../../context/AppSettingsContext'
import { PaginatedSearchAndSelect } from '../../mprise-light/PaginatedSearchAndSelect'
import { QueryErrorMessage } from '../apollo'
import { useDebounceValue } from '../debounce-value'
import { useLocalState } from '../local-state'
import { defined } from '../typescript'
import { DialogWithBackButton } from '../../components/Dialog'
import { REASONS_BY_FILTER } from '../../gql/reasons'
import { Maybe } from '../enums'

export type Reason = {
  id: number
  code?: Maybe<string>
  name?: Maybe<string>
}

const toSelectItem = ToMSelectItem<Reason>({
  id: x => x.id as unknown as string,
  icon: () => <MaterialIcon value='delete_forever' />,
  primary: x => x.name ?? '',
  secondary: x => x.code ?? null,
})

export const SelectReasonDialog = ({
  title,
  open,
  onClose,
  onSave,
}: {
  title: React.ReactNode
  open: boolean
  onClose: () => void
  onSave: (values: any) => void
}) => {
  const [search, setSearch] = useLocalState('', [open])
  const debouncedSearch = useDebounceValue(search, 500)
  const { company } = useAppSettingsContext()
  const companyId = company?.id!

  const [reasonsByFilter, { data, loading, error, fetchMore, refetch }] = useLazyQuery(REASONS_BY_FILTER)

  useEffect(() => {
    if (open) {
      reasonsByFilter({
        variables: {
          filter: {
            companyId: +companyId,
            ...(debouncedSearch && { searchString: debouncedSearch }),
            removed: false,
            type: 'SCRAP',
          },
          limit: 20,
          offset: 0,
        },
      })
    }
  }, [open, debouncedSearch, reasonsByFilter, companyId])

  const reasons = data?.reasons?.page?.filter(defined).map(toSelectItem) ?? null

  const handleSelect = async (item: MSelectItem<Reason> | null) => {
    onSave(item?.data ?? null)
  }

  const handleSubmit: React.FormEventHandler = async e => {
    e.stopPropagation()
    e.preventDefault()
    onClose()
  }

  return (
    <MFlexBlock alignItems='center' style={{ position: 'relative' }}>
      <DialogWithBackButton open={open} title={title} onClose={onClose} onSubmit={handleSubmit}>
        <QueryErrorMessage query={{ error, loading, refetch }} />
        <PaginatedSearchAndSelect
          items={reasons}
          search={search}
          value={null}
          loading={loading}
          onChange={handleSelect}
          fetchMore={fetchMore}
          onSearch={setSearch}
          showSwitchKeyboardOption={true}
          fetchMoreDataAccessor={(data: any) => data?.reasons?.page}
        />
      </DialogWithBackButton>
    </MFlexBlock>
  )
}
