import { gql } from '@apollo/client'

export const LOT_BY_FILTER = gql`
  query Lot($filter: LotFilter) {
    lot(filter: $filter) {
        id
        lotNumber
        variantCode
        item {
          id
          name
        }
      }
    }
`