import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { IconButton } from '@mui/material'
import { Formik, MDialog, MFieldConnector, MFlexBlock, MFlexItem, MText, withFormikCompareFix } from '@mprise/react-ui'
import { useLocalState } from '../../shared/local-state'
import { DialogFormik } from '../../mprise-light/DialogFormik'
import { Section } from '../../components/Section'
import { MaterialIcon } from '../../components/Icon'
import { FieldSelectionQuantity } from '../../shared/form/SelectionFieldQuantity'
import { TableColumn, TableEntryBase } from './InlineTable'
import { MDialogContentText } from '../../mprise-light/Dialog'

interface UpdateRowQuantityForm {
  quantity: number
}

export const UpdateTableRowDialog = <T extends TableEntryBase>({
  entry,
  updateEntry,
  deleteEntry,
  columns,
  unitOfMeasure,
  open,
  handleClose,
}: {
  entry: T
  updateEntry: (entry: T) => void
  deleteEntry: (id: number | string) => void
  columns: TableColumn<T>[]
  unitOfMeasure: string
  open: boolean
  handleClose: () => void
}) => {
  const { t } = useTranslation()

  const [initialValues] = useLocalState<UpdateRowQuantityForm>(
    withFormikCompareFix({
      quantity: entry.quantity ?? 0,
    }),
    [],
  )

  const [showDeleteDialog, setShowDeleteDialog] = useState<boolean>(false)

  const handleDelete = () => setShowDeleteDialog(true)
  const handleStopDelete = () => {
    setShowDeleteDialog(false)
    handleClose()
  }

  return (
    <>
      <Formik.Formik
        initialValues={initialValues}
        onSubmit={async form => {
          const userInput = {
            ...entry,
            quantity: +form.quantity,
          }
          updateEntry(userInput)
          handleClose()
        }}
      >
        <DialogFormik minWidth='xs' title={t('EDIT')} onClose={handleClose} open={open}>
          <Section>
            <UpdateQuantityForm
              entry={entry}
              columns={columns}
              unitOfMeasure={unitOfMeasure}
              handleDelete={handleDelete}
            />
          </Section>
        </DialogFormik>
      </Formik.Formik>
      <DeleteTableRowDialog
        entry={entry}
        columns={columns}
        open={showDeleteDialog}
        handleClose={handleStopDelete}
        deleteEntry={deleteEntry}
      />
    </>
  )
}

const UpdateQuantityForm = <T extends TableEntryBase>({
  entry,
  columns,
  unitOfMeasure,
  handleDelete,
}: {
  entry: T
  columns: TableColumn<T>[]
  unitOfMeasure: string
  handleDelete: () => void
}) => {
  const fc = Formik.useFormikContext<UpdateRowQuantityForm>()

  useEffect(() => {
    const quantity = fc.values.quantity
    if (quantity < 0) {
      fc.setValues({ quantity: 0 })
    }
  }, [fc.values.quantity])

  useEffect(() => {
    fc.setValues({ quantity: entry.quantity })
  }, [entry.quantity])

  return (
    <>
      <MFlexItem style={{ padding: '0.5rem', borderBottom: '1px #ccd1d4 solid' }}>
        <EntryInfo entry={entry} columns={columns} />
      </MFlexItem>
      <MFlexBlock style={{ padding: '0.5rem' }} justifyContent='space-between'>
        <MFlexItem style={{ paddingBottom: '1rem' }}>
          <Formik.Field component={MFieldConnector} name='quantity'>
            <FieldSelectionQuantity unitOfMeasure={unitOfMeasure} />
          </Formik.Field>
        </MFlexItem>
        <MFlexItem style={{ padding: '0.5rem', alignSelf: 'center' }}>
          <IconButton onClick={handleDelete}>
            <MaterialIcon value='delete' style={{ fontSize: `2rem` }} />
          </IconButton>
        </MFlexItem>
      </MFlexBlock>
    </>
  )
}

const DeleteTableRowDialog = <T extends TableEntryBase>({
  entry,
  columns,
  open,
  handleClose,
  deleteEntry,
}: {
  entry: T
  columns: TableColumn<T>[]
  open: boolean
  handleClose: () => void
  deleteEntry: (id: number | string) => void
}) => {
  const { t } = useTranslation()

  const handleSubmit = () => {
    deleteEntry(entry.id)
    handleClose()
  }

  return (
    <MDialog open={open} title={t('REMOVE_FROM_SELECTION')} onSubmit={handleSubmit} onClose={handleClose}>
      <MDialogContentText>
        {t('QUESTION_ARE_YOU_SURE')}
        <EntryInfo entry={entry} columns={columns} />
      </MDialogContentText>
    </MDialog>
  )
}

const EntryInfo = <T,>({ entry, columns }: { entry: T; columns: TableColumn<T>[] }) => {
  return (
    <MFlexItem style={{ margin: '0.5rem', display: 'grid' }}>
      {columns?.map(column => {
        if (column.propertyName === 'quantity') {
          return <></>
        }
        const value = column.isNumber
          ? entry[column.propertyName]
          : (entry[column.propertyName] as number).toLocaleString('nl-NL')
        return (
          <MFlexItem key={column.label}>
            <MText inline textVariant='inherit'>
              {`${column.label}: `}
            </MText>
            <MText inline>{value as string}</MText>
          </MFlexItem>
        )
      })}
    </MFlexItem>
  )
}
