import { gql } from '@apollo/client'
import { FragmentItemConsumption } from '../fragment/itemConsumption'

export const REPORT_ITEM_COMSUMPTION = gql`
  mutation registerItemConsumption(
    $workItemId: ID!
    $taskId: ID!
    $itemConsumptionId: ID!
    $values: WorkItemConsumptionInput
  ) {
    registerItemConsumption(
      workItemId: $workItemId
      taskId: $taskId
      itemConsumptionId: $itemConsumptionId
      values: $values
    ) {
      itemConsumption(frontend: true) {
        ...FragmentItemConsumption
      }
    }
  }
  ${FragmentItemConsumption}
`
