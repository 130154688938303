import { Formik, MAudio, withFormikCompareFix } from '@mprise/react-ui'
import { useTranslation } from 'react-i18next'
import { useHistory } from '../../shared/use-history'
import { useLocalState } from '../../shared/local-state'
import { ScrapForm } from './ScrapForm'
import { FlashAlerts } from '../../shared/flash-alerts'
import { SavingSwitchPanel } from '../../shared/saving-switch-panel'
import { MutationErrorMessage } from '../../shared/apollo'
import { ValidationIssues } from '../../mprise-light/ValidationIssues'
import { useAppSettingsContext } from '../../context/AppSettingsContext'
import { DialogFormik } from '../../mprise-light/DialogFormik'
import { Helmet } from 'react-helmet'
import { fail } from '../../shared/typescript'
import { useMutation } from '@apollo/client'
import { REPORT_SCRAP } from '../../gql/mutation/reportScrap'
import { Maybe } from '../../shared/enums'
import { parseFloatQuantity } from '../../shared/formats'
import { JobInventoryDetail } from '../../shared/interfaces'

export interface ScrapEntryForm {
  job: Maybe<{ id: string; name: string; code: Maybe<string> }> | undefined
  fromPosition: Maybe<{ id: string; name: string; code: Maybe<string> }>
  jobInventoryDetail: Maybe<JobInventoryDetail>
  reason: Maybe<{ id: string; name: string; code: Maybe<string> }>
  originalQuantity: Maybe<number>
  quantity: Maybe<number>
  workItemId: Maybe<string>
}

export const ScrapRoute = () => {
  const h = useHistory()
  const { t } = useTranslation()
  const schema = ScrapForm.useSchema()
  const alerts = FlashAlerts.useAlert()

  const { resource, numberFormat } = useAppSettingsContext()
  const resourceId = resource?.id ?? h.push('/')

  const handleClose = () => {
    h.goBack()
  }
  const [reportScrap, reportScrapState] = useMutation(REPORT_SCRAP)

  const [initialValues] = useLocalState((): ScrapEntryForm => {
    return withFormikCompareFix({
      job: {
        id: '',
        name: '',
        code: '',
      },
      fromPosition: { id: '', name: '', code: '' },
      jobInventoryDetail: null,
      reason: {
        id: '',
        name: '',
        code: '',
      },
      originalQuantity: null,
      quantity: null,
      workItemId: null,
    })
  }, [])

  return (
    <>
      <Helmet title={t('SCRAP')} />

      <Formik.Formik
        initialValues={initialValues}
        validationSchema={schema}
        onSubmit={async (form, actions) => {
          actions.resetForm()

          if (!resourceId) {
            fail('expects resource id')
          }

          const jobInventoryDetailId = form.jobInventoryDetail!.id
          const reasonId = form.reason!.id
          const quantity = form.quantity!

          const result = await reportScrap({
            variables: {
              jobInventoryDetailId: +jobInventoryDetailId,
              reasonId: +reasonId,
              quantity: parseFloatQuantity(quantity, numberFormat),
              resourceId: +resourceId,
            },
          })
          if (result.data) {
            MAudio.scanSuccess()
            alerts.push(t('SUCCESS_MESSAGE'), `success`)
          }
        }}
      >
        <DialogFormik minWidth='xl' title={t('SCRAP')} onClose={handleClose} open={true}>
          <SavingSwitchPanel mutation={reportScrapState}>
            <ValidationIssues />
            <MutationErrorMessage mutation={reportScrapState} />
            <ScrapForm />
          </SavingSwitchPanel>
        </DialogFormik>
      </Formik.Formik>
    </>
  )
}
