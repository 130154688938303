import { CircularProgress, Fade } from '@mui/material'
import { MColor, MFlexBlock, MText, px } from '@mprise/react-ui'
import React from 'react'
import { SwitchTransition } from 'react-transition-group'

type LoadingValueInterface = {
  loading: boolean
  data: unknown
}

export const LoadingSwitchPanel = ({
  loadingAnyway,
  query,
  children,
}: {
  loadingAnyway?: boolean
  query: LoadingValueInterface | Array<LoadingValueInterface>
  children: React.ReactNode
}) => {
  const loading = loadingAnyway || [query].flat().some(x => x.loading && !x.data)
  return (
    <SwitchTransition>
      <Fade key={Number(loading)} timeout={150}>
        {loading ? (
          <MFlexBlock justifyContent='center' alignItems='center' padding={10} gap={5}>
            <MText block textColor={MColor.primary}>
              <CircularProgress color='inherit' size={px(48)} />
            </MText>
            {!loading || loading === true ? null : (
              <MText block textColor={MColor.primary}>
                {loading}
              </MText>
            )}
          </MFlexBlock>
        ) : (
          <div>{children}</div>
        )}
      </Fade>
    </SwitchTransition>
  )
}
