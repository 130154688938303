import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router'
import { Helmet } from 'react-helmet'
import { useHistory } from '../../shared/use-history'
import { MColor, MFlex, MFlexBlock, MFlexItem, MText, MTextColor, px } from '@mprise/react-ui'
import { Badge, CircularProgress, IconButton, Toolbar } from '@mui/material'
import { useApolloClient, useLazyQuery, useMutation, useQuery } from '@apollo/client'
import { mdiFilter } from '@mdi/js'
import { Icon } from '@mdi/react'
import { QueryErrorMessage } from '../../shared/apollo'
import { ScanningSetting, useAppSettingsContext } from '../../context/AppSettingsContext'
import { FilterDialog, getSearchVariables } from './FilterDialog'
import { useDebounceValue } from '../../shared/debounce-value'
import { DialogHeaderBack } from '../../components/Header'
import { Section } from '../../components/Section'
import { StyledTab, StyledTabs } from '../../components/tabs'
import { RefreshIcon } from '../../components/RefreshIcon'
import { SEARCH_WORKITEMS_WITH_FILTER } from '../../gql/query/workItems/searchWorkitems'
import { SearchBox } from '../../shared/util/search'
import { WorkItemCard } from './WorkItemCard'
import { NextPageView } from '../../shared/NextPageView'
import { WorkItemTemplateTaskOption } from '../../shared/enums'
import { redirectTask } from './redirect-task'
import { WORKITEM_FOR_REDIRECT } from '../../gql/query/workItems/workItemForRedirect'
import { START_TASK } from '../../gql/mutation/statusChange/statusChange'
import { WorkStatus } from '../../shared/enums'
import { fail } from '../../shared/typescript'
import { START_TIME_REG } from '../../gql/mutation/timeReg'

type PageTabType = 'my' | 'all'

export const TasksRoute = () => {
  const tab = (useParams().tab ?? 'my') as PageTabType
  const h = useHistory()
  const { t } = useTranslation()
  const apollo = useApolloClient()

  const [search, setSearch] = useState(sessionStorage.getItem('searchText') ?? '')

  const filter = FilterDialog.useDialogState()

  const searchInputRef = useRef<HTMLInputElement>(null)

  const appSettings = useAppSettingsContext()
  const companyId = appSettings.company?.id ?? h.push('/')
  const currentResource = appSettings.resource ?? h.push('/')

  const handleClose = () => {
    h.goBack()
  }

  useEffect(() => {
    searchInputRef.current?.focus()
    searchInputRef.current?.setSelectionRange(0, searchInputRef.current?.value?.length)
  }, [tab])

  const handleChangeTab = (_: React.ChangeEvent<{}>, newValue: number) => {
    h.push(`/tasks/${newValue}`, { replace: true })
  }

  const handleClickTask = async (c: any) => {
    const autoStarted = await handleAutoStart(c.id)
    if (!autoStarted) {
      h.push(`/tasks/details/${c.id}`)
    }
  }

  const handleSearchChange = (text: string) => {
    if (search !== text) {
      setSearch(text)
      sessionStorage.setItem('searchText', text)
    }
  }

  const searchVariables = useDebounceValue(
    getSearchVariables({
      tab: tab,
      query: search,
      filter: filter.values,
      companyId: +companyId,
      resourceId: +(currentResource?.id ?? ''),
    }),
    500,
  )

  const { data, loading, error, fetchMore } = useQuery(SEARCH_WORKITEMS_WITH_FILTER, {
    variables: {
      filter: { ...searchVariables, removed: false },
      limit: 20,
      offset: 0,
    },
  })

  const refetch = () => {
    if (!loading) {
      apollo.reFetchObservableQueries()
    }
  }

  const workItems = data?.searchWorkItems?.page ?? []
  const count = data?.searchWorkItems?.total ?? 0
  const { scanSetting } = useAppSettingsContext()
  const [scannedStatus, setScanStatus] = useState(scanSetting)
  const handleSearchBoxClick = () => {
    if (scanSetting !== ScanningSetting.KEYBOARD) {
      setScanStatus(ScanningSetting.KEYBOARD)
    }
  }

  const hasFilter =
    filter.values.status.length !== FilterDialog.emptyForm.status.length ||
    !filter.values.status.every((status: WorkStatus) => FilterDialog.emptyForm.status.includes(status)) ||
    filter.values.startDate !== FilterDialog.emptyForm.startDate ||
    filter.values.endDate !== FilterDialog.emptyForm.endDate

  useEffect(() => {
    if (search && workItems.length === 1 && search === workItems[0].jobs[0].code) {
      handleAutoStart(workItems[0].id)
    }
  }, [search, workItems])

  const [getWorkItem] = useLazyQuery(WORKITEM_FOR_REDIRECT)
  const [startTaskMutation] = useMutation(START_TASK)

  const [startTimeReg] = useMutation(START_TIME_REG)

  const handleAutoStart = async (workItemId: string): Promise<boolean> => {
    return await getWorkItem({
      variables: { where: [{ field: 'id', options: { eq: +workItemId } }] },
    })
      .then(async x => {
        const workItem = x.data.nworkItem
        const workItemId = workItem.id
        const task = workItem.tasks[0]
        const taskOptions = task.taskOptions
        const autoStart = taskOptions.includes(WorkItemTemplateTaskOption.AutoStartStopTasksGh)

        if (autoStart && task.status !== WorkStatus.Done) {
          if (!currentResource?.id) {
            fail('expects resource id')
          }
          await startTaskMutation({
            variables: {
              workItemId: +workItemId,
              taskId: +task.id,
              currentResourceId: +currentResource?.id,
            },
          }).then(response => {
            const task = response.data.startTask
            startTimeReg({
              variables: {
                workItemId: task.workItem.cosmosKey,
                taskId: task.cosmosKey,
                resourceId: currentResource.cosmosKey,
              },
            })
          })
          redirectTask(workItem, task, h)
          sessionStorage.setItem('searchText', '')
          return true
        }
        return false
      })
      .catch(() => {
        return false
      })
  }

  return (
    <>
      <Helmet title={t('TASKS')} />

      <DialogHeaderBack title={t('TASKS')} onClose={handleClose} />
      <FilterDialog
        open={filter.open}
        initialValues={filter.values}
        onClose={filter.handleClose}
        onSave={filter.handleSave}
      />
      <Section>
        <StyledTabs value={tab} onChange={handleChangeTab} sx={{ m: '0 0.5rem' }}>
          <StyledTab label={<WorkItemTab tab='my' total={count} loading={loading} activeTab={tab} />} value='my' />
          <StyledTab label={<WorkItemTab tab='all' total={count} loading={loading} activeTab={tab} />} value='all' />
        </StyledTabs>
        <Toolbar>
          <MFlex grow={1} alignItems='center'>
            <MFlexItem grow={10} shrink={2} />
            <MFlexItem basis={px(480)} grow={1} shrink={1}>
              <SearchBox
                value={search}
                onChange={handleSearchChange}
                scanningSetting={scannedStatus}
                autoFocus={true}
                showSwitchKeyboardOption={false}
                status={0}
                onClick={handleSearchBoxClick}
                ref={searchInputRef}
              />
            </MFlexItem>
            <IconButton onClick={filter.handleOpen}>
              <Icon size={1} path={mdiFilter} color={hasFilter ? MColor.medium : MColor.dim} />
            </IconButton>
            <IconButton onClick={refetch}>
              <RefreshIcon loading={loading} />
            </IconButton>
          </MFlex>
        </Toolbar>
        <QueryErrorMessage query={{ loading, error, refetch }} />
        {workItems?.length
          ? workItems.map((item: any) => <WorkItemCard key={item.id} workItem={item} onClick={handleClickTask} />)
          : null}
        <NextPageView
          offset={workItems?.length}
          fetchMore={fetchMore}
          pageSize={20}
          fetchMoreDataAccessor={(data: any) => data.searchWorkItems?.page}
        />
      </Section>
      {loading && (
        <MFlexBlock padding={[4]} justifyContent='center'>
          <CircularProgress size={30} color='inherit' />
        </MFlexBlock>
      )}
      {!workItems?.length && !loading ? (
        <MFlexBlock padding={[4]} justifyContent='center'>
          <MText block textVariant='content bold' textColor={MTextColor.disabled}>
            {t('NO_RESULTS')}
          </MText>
        </MFlexBlock>
      ) : null}
    </>
  )
}

const WorkItemTab = ({
  total,
  loading,
  tab,
  activeTab,
}: {
  total?: number
  loading?: boolean
  tab: PageTabType
  activeTab: PageTabType
}) => {
  const { t } = useTranslation()

  const count = loading ? <CircularProgress color='inherit' size='1rem' /> : <>{total ?? '-'}</>

  return (
    <Badge
      color='primary'
      max={9999}
      showZero
      badgeContent={tab === activeTab ? count : null}
      overlap='rectangular'
      sx={{ '& .MuiBadge-badge': { top: '-5px' } }}
    >
      {tab === 'all' ? t('ALL_TASKS') : t('MY_TASKS')}
    </Badge>
  )
}
