import React from 'react'
import { useTranslation } from 'react-i18next'
import { Collapse, Divider, IconButton } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { MBlock, MColor, MFlexBlock, MFlexItem, MText, ScanningSetting, SearchBox } from '@mprise/react-ui'
import { defined } from '../typescript'
import { JobInventoryDetailCard } from './JobInventoryDetailCard'
import { useLocalState } from '../local-state'
import { useDebounceValue } from '../debounce-value'
import { mdiFilter, mdiRestart } from '@mdi/js'
import Icon from '@mdi/react'
import { List, ListItem } from '../../components/List'
import { MaterialIcon } from '../../components/Icon'
import { DialogWithBackButton } from '../../components/Dialog'
import { JobInventoryDetail } from '../interfaces'

type FilterType = {
  positions: string[]
  phases: string[]
  sortingCodes: string[]
  carrierCodes: string[]
  remQtyPerUnitOfMeasure: string[]
}

export const SelectJobInventoryDetailDialog = ({
  title,
  open,
  includeCarrierCodes = false,
  onClose,
  onSave,
  jobInventoryDetails,
}: {
  title: React.ReactNode
  open: boolean
  includeCarrierCodes: boolean
  onClose: () => void
  onSave: (values: JobInventoryDetail) => void
  jobInventoryDetails: JobInventoryDetail[]
}) => {
  const { t } = useTranslation()

  const handleSelect = (jobInventoryDetail: JobInventoryDetail) => {
    onSave(jobInventoryDetail)
  }

  const handleSubmit: React.FormEventHandler = async e => {
    e.stopPropagation()
    e.preventDefault()
    onClose()
  }

  const [search, setSearch] = useLocalState(``, [open])
  const debouncedSearch = useDebounceValue(search, 500).toLowerCase()
  const handleSearchChange = (text: string) => {
    if (search !== text) {
      setSearch(text)
    }
  }

  const [filter, setFilter] = useLocalState<FilterType>(
    {
      positions: [],
      phases: [],
      sortingCodes: [],
      carrierCodes: [],
      remQtyPerUnitOfMeasure: [],
    },
    [open],
  )

  const handleChangeFilter = (filter: FilterType) => {
    setFilter(filter)
  }
  // Filter all the JobInventoryDetails of the selected Job based on the chosen filter options
  const filteredJIDs = filterJobInventoryDetails()
  const count = filteredJIDs.length

  // sort searchable list to JobInventoryDetails
  const filterByStep = filteredJIDs.sort((a: any, b: any) => {
    if (a.productionPhase < b.productionPhase) {
      return -1
    } else if (a.productionPhase > b.productionPhase) {
      return 1
    }
    return 0
  })
  return (
    <DialogWithBackButton open={open} title={title} onClose={onClose} onSubmit={handleSubmit}>
      <SearchAndFilterCombo
        jobInventoryDetails={jobInventoryDetails}
        search={search}
        filter={filter}
        count={count}
        handleSearchChange={handleSearchChange}
        handleChangeFilter={handleChangeFilter}
      />

      <MFlexBlock vertical style={{ overflow: `auto`, position: 'relative' }}>
        {filteredJIDs.length > 0 ? (
          filterByStep.map(jobInventoryDetail => (
            <JobInventoryDetailCard
              key={jobInventoryDetail.id}
              jobInventoryDetail={jobInventoryDetail}
              handleSelect={handleSelect}
            />
          ))
        ) : (
          <MBlock padding={20}>
            <MText block>{t(`NOTIFICATION_NO_INVENTORY_DETAILS`)}</MText>
          </MBlock>
        )}
      </MFlexBlock>
    </DialogWithBackButton>
  )

  /*
  Function to filter all the JobInventoryDetails of the selected Job based on the chosen filter options
   */
  function filterJobInventoryDetails() {
    const hasSearchTxt = debouncedSearch !== ''
    const hasFilterPositions = filter.positions.length > 0
    const hasFilterPhases = filter.phases.length > 0
    const hasFilterSortingCodes = filter.sortingCodes.length > 0
    const hasFilterCarrierCodes = filter.carrierCodes.length > 0

    return jobInventoryDetails.filter(jid => {
      if (!hasSearchTxt && !hasFilterPositions && !hasFilterPhases && !hasFilterSortingCodes) {
        return true
      } else {
        return (
          (hasSearchTxt
            ? jid.position.code?.toLowerCase().includes(debouncedSearch) ||
              jid.productionPhase.productionPhase.toLowerCase().includes(debouncedSearch) ||
              jid.sortingCode?.toLowerCase().includes(debouncedSearch) ||
              jid.carrierCode?.toLowerCase().includes(debouncedSearch)
            : true) &&
          (hasFilterPositions ? filter.positions.includes(jid.position.code!) : true) &&
          (hasFilterPhases ? filter.phases.includes(jid.productionPhase.productionPhase) : true) &&
          (hasFilterSortingCodes ? (jid.sortingCode ? filter.sortingCodes.includes(jid.sortingCode) : false) : true) &&
          (hasFilterCarrierCodes ? (jid.carrierCode ? filter.carrierCodes.includes(jid.carrierCode!) : false) : true)
        )
      }
    })
  }
}

const SearchAndFilterCombo = ({
  jobInventoryDetails,
  search,
  filter,
  count,
  handleSearchChange,
  handleChangeFilter,
}: {
  jobInventoryDetails: JobInventoryDetail[]
  search: string
  filter: FilterType
  count: number
  handleSearchChange: (text: string) => void
  handleChangeFilter: (filter: FilterType) => void
}) => {
  const { t } = useTranslation()

  const [openFilter, setOpenFilter] = useLocalState(false)
  const handleOpenFilter = (e: any) => {
    e.stopPropagation()
    e.preventDefault()
    setOpenFilter(!openFilter)
  }

  const handleClear = (e: any) => {
    e.stopPropagation()
    e.preventDefault()
    handleChangeFilter({ positions: [], phases: [], sortingCodes: [], carrierCodes: [], remQtyPerUnitOfMeasure: [] })
  }

  // Filter-options: unique set of the values present in the JobInventoryDetails
  const positions = Array.from(new Set(jobInventoryDetails.map(jid => jid.position.code).filter(defined))).sort()
  const phases = Array.from(new Set(jobInventoryDetails.map(jid => jid.productionPhase.productionPhase)))
    .filter(defined)
    .sort()
  const sortingCodes = Array.from(new Set(jobInventoryDetails.map(jid => jid.sortingCode).filter(defined))).sort()
  const carrierCodes = Array.from(new Set(jobInventoryDetails.map(jid => jid.carrierCode).filter(defined))).sort()
  const hasFilter =
    filter.positions.length > 0 ||
    filter.phases.length > 0 ||
    filter.sortingCodes.length > 0 ||
    filter.carrierCodes.length > 0
  const classes = useStyles()

  return (
    <>
      <MFlexBlock
        vertical
        grow={1}
        style={{
          backgroundColor: '#ffffff',
          padding: '0.25rem 0.75rem 0.75rem 0.75rem',
          boxSizing: 'border-box',
          position: 'sticky',
          top: '4.3rem',
          width: '100%',
          zIndex: 99,
        }}
      >
        <MFlexBlock style={{ justifyContent: 'center', alignItems: 'center' }}>
          <MFlexItem grow={0} shrink={1}>
            <SearchBox
              value={search}
              onChange={handleSearchChange}
              scanningSetting={ScanningSetting.KEYBOARD}
              showSwitchKeyboardOption={true}
              status={0}
            />
          </MFlexItem>
          <Divider className={classes.divider} orientation='vertical' />
          <MFlexItem style={{ display: 'flex' }}>
            <IconButton style={{ padding: '4px' }} onClick={handleOpenFilter}>
              <Icon
                style={{ backgroundColor: '#ebeff2', borderRadius: '50%', padding: '10px' }}
                path={mdiFilter}
                size={1}
                color={hasFilter ? MColor.medium : MColor.dim}
              />
            </IconButton>
            <IconButton style={{ padding: '12px 6px', marginRight: '8px' }}>{count}</IconButton>
          </MFlexItem>
        </MFlexBlock>
        <Collapse in={openFilter} className={classes.filterDiv}>
          <IconButton onClick={handleClear} className={classes.ButtonClearJidIcon}>
            <Icon path={mdiRestart} size={1} color={MColor.dim} />
          </IconButton>
          {positions.length > 0 && (
            <FilterGroup
              title={t(`POSITIONS`)}
              type={'positions'}
              values={positions}
              filter={filter}
              handleChangeFilter={handleChangeFilter}
            />
          )}
          {phases.length > 0 && (
            <FilterGroup
              title={t(`PHASES`)}
              type={'phases'}
              values={phases}
              filter={filter}
              handleChangeFilter={handleChangeFilter}
            />
          )}
          {sortingCodes.length > 0 && (
            <FilterGroup
              title={t(`SORTING_CODES`)}
              type={'sortingCodes'}
              values={sortingCodes}
              filter={filter}
              handleChangeFilter={handleChangeFilter}
            />
          )}
          {carrierCodes.length > 0 && (
            <FilterGroup
              title={t(`CARRIER_CODES`)}
              type={'carrierCodes'}
              values={carrierCodes}
              filter={filter}
              handleChangeFilter={handleChangeFilter}
            />
          )}
        </Collapse>
      </MFlexBlock>
    </>
  )
}

const FilterGroup = ({
  title,
  type,
  values,
  filter,
  handleChangeFilter,
}: {
  title: string
  type: 'positions' | 'phases' | 'sortingCodes' | 'carrierCodes'
  values: string[]
  filter: FilterType
  handleChangeFilter: (filter: FilterType) => void
}) => {
  const [openFilterGroup, setOpenFilterGroup] = useLocalState(false)
  const handelTitelClick = () => {
    setOpenFilterGroup(!openFilterGroup)
  }
  const handleClickFilterValue = (value: string) => {
    // Check if filter contains value: if true remove from filter. If false, add value to filter
    const newFilterArray = filter[type].includes(value!)
      ? filter[type].filter(x => x !== value)
      : [...filter[type], value!]
    if (type === 'positions') {
      handleChangeFilter({
        ...filter,
        positions: newFilterArray,
      })
    } else if (type === 'phases') {
      handleChangeFilter({
        ...filter,
        phases: newFilterArray,
      })
    } else if (type === 'sortingCodes') {
      handleChangeFilter({
        ...filter,
        sortingCodes: newFilterArray,
      })
    } else if (type === 'carrierCodes') {
      handleChangeFilter({
        ...filter,
        carrierCodes: newFilterArray,
      })
    }
  }

  return (
    <List>
      <MText
        onClick={handelTitelClick}
        block
        textColor={MColor.dim}
        style={{ backgroundColor: '#ffffff', marginTop: '0.8rem', marginLeft: '0.5rem' }}
      >
        {title}
      </MText>
      <MFlexItem className={'select-jid-filterGroup'}>
        <Collapse in={openFilterGroup}>
          {values.map(value => (
            <MFlexItem className={'select-jid-filterItems'} key={value}>
              <ListItem
                key={value}
                primary={value}
                action={
                  filter[type].includes(value) ? (
                    <MaterialIcon
                      style={{ color: `#009a44`, fontSize: '1.3rem', marginRight: '0.8rem' }}
                      value='check'
                    />
                  ) : null
                }
                onClick={() => handleClickFilterValue(value)}
              />
            </MFlexItem>
          ))}
        </Collapse>
      </MFlexItem>
    </List>
  )
}

const useStyles = makeStyles(() => ({
  filterDiv: {
    border: '2px solid #eeeeee',
    borderRadius: '5px',
    padding: '3px',
    borderTop: '0px',
    fontWeight: 'bold',
  },
  ButtonClearJidIcon: {
    float: 'right',
  },
  divider: {
    height: 28,
    margin: 4,
  },
}))
