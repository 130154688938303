import { Section } from '../../components/Section'
import { TaskDetails } from '../../routes/tasks/TaskItem'
import { Maybe } from '../enums'

export const TaskDetailsSection = ({
  task,
  reportedQuantityOverride,
}: {
  task: any
  reportedQuantityOverride?: Maybe<number>
}) => {
  return (
    <Section>
      <div className='gh-form-task-card'>
        <div className='gh-form-task-header'>
          {task?.order ?? `?`}. {task?.name ?? ``}
        </div>
        <TaskDetails task={task} reportedQuantityOverride={reportedQuantityOverride} />
      </div>
    </Section>
  )
}
