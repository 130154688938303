import { useState } from 'react'
import { MFlexBlock, MFlexItem, MText } from '@mprise/react-ui'
import { Section } from '../../components/Section'
import { Collapse, IconButton } from '@mui/material'
import { formatQuantity } from '../../shared/formats'
import { useAppSettingsContext } from '../../context/AppSettingsContext'
import { MaterialIcon } from '../../components/Icon'
import { UpdateTableRowDialog } from './UpdateTableRowDialog'
import { mdiChevronRight } from '@mdi/js'
import Icon from '@mdi/react'

export type TableColumn<T> = {
  label: string
  propertyName: keyof T
  isNumber?: boolean
}

export type TableEntryBase = {
  id: number | string
  quantity: number
}

//Intended to be generic, currently still using hardcoded quantity and unitOfMeasure fields though.
export const InlineTable = <T extends TableEntryBase>({
  columns,
  values,
  unitOfMeasure,
  updateEntry,
  deleteEntry,
}: {
  columns: TableColumn<T>[]
  values: T[]
  unitOfMeasure: string
  updateEntry: (entry: T) => void
  deleteEntry: (id: number | string) => void
}) => {
  const [isExpanded, setIsExpanded] = useState(false)
  const toggleExpanded = () => setIsExpanded(current => !current)
  return (
    <Section>
      <MFlexBlock vertical margin={[5, 0, 0, 0]}>
        <TableHeaderRow columns={columns} values={values} isExpanded={isExpanded} toggleExpanded={toggleExpanded} />
        <Collapse in={isExpanded}>
          {values?.map((row, i) => (
            <TableRow
              key={i}
              entry={row}
              columns={columns}
              unitOfMeasure={unitOfMeasure}
              updateEntry={updateEntry}
              deleteEntry={deleteEntry}
            />
          ))}
        </Collapse>
      </MFlexBlock>
    </Section>
  )
}

const TableRow = <T extends TableEntryBase>({
  entry,
  columns,
  unitOfMeasure,
  updateEntry,
  deleteEntry,
}: {
  entry: T
  columns: TableColumn<T>[]
  unitOfMeasure: string
  updateEntry: (entry: T) => void
  deleteEntry: (id: number | string) => void
}) => {
  const [showEditDialog, setShowUpdateDialog] = useState<boolean>(false)

  const handleEdit = () => setShowUpdateDialog(true)
  const handleStopEdit = () => {
    setShowUpdateDialog(false)
  }

  return (
    <MFlexBlock
      alignItems='center'
      justifyContent='space-between'
      padding={[0, 4]}
      style={{ borderBottom: '1px #ccd1d4 solid' }}
    >
      {columns.map(column => {
        const value = column.isNumber
          ? entry[column.propertyName]
          : (entry[column.propertyName] as number).toLocaleString('nl-NL')
        return <RowTextItem text={value as string | number} width={35} key={column.label} />
      })}
      <IconButton onClick={handleEdit}>
        <MaterialIcon value='edit' style={{ fontSize: `1.5rem` }} />
      </IconButton>
      <UpdateTableRowDialog
        entry={entry}
        updateEntry={updateEntry}
        deleteEntry={deleteEntry}
        columns={columns}
        unitOfMeasure={unitOfMeasure}
        open={showEditDialog}
        handleClose={handleStopEdit}
      />
    </MFlexBlock>
  )
}

const RowTextItem = ({ text, width }: { text: string | number; width?: number }) => {
  return (
    <MFlexItem style={{ width: `${width ?? 40}%` }}>
      <MText inline textColor={'black'} style={{ fontSize: '16px' }}>
        {text}
      </MText>
    </MFlexItem>
  )
}

const TableHeaderRow = <T,>({
  columns,
  values,
  isExpanded,
  toggleExpanded,
}: {
  columns: TableColumn<T>[]
  values: T[]
  isExpanded: boolean
  toggleExpanded: () => void
}) => {
  const { numberFormat } = useAppSettingsContext()

  return (
    <MFlexBlock alignItems='center' padding={[2, 4]} style={{ backgroundColor: '#e0e0e0' }} onClick={toggleExpanded}>
      {columns.map(column => {
        if (column.propertyName === 'id') {
          return <></>
        }
        const count = column.isNumber
          ? formatQuantity(
              values.reduce((a, b) => a + (b[column.propertyName] as number), 0),
              numberFormat,
            )
          : values.length
        return <RowTextItem text={`${column.label} (${count})`} width={35} key={column.label} />
      })}
      <IconButton size='small' style={{ marginLeft: 'auto' }}>
        <Icon path={mdiChevronRight} size={1} rotate={isExpanded ? 90 : 0} style={{ transition: 'transform 0.3s' }} />
      </IconButton>
    </MFlexBlock>
  )
}
