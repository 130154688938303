import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useLazyQuery } from '@apollo/client'
import { MFlexBlock } from '@mprise/react-ui'
import { Section } from '../../components/Section'
import { defined } from '../../shared/typescript'
import { useAppSettingsContext } from '../../context/AppSettingsContext'
import { useHistory } from '../../shared/use-history'
import { JOB_INVENTORY_BY_FILTER } from '../../gql/query/jobInventories/jobInventoryByFilter'
import { PhasesSection } from '../job/PhasesSection'
import { MDialog } from '../../mprise-light/Dialog'

export const EditJobPhasesDialog = ({ jobWithPhases, onClose }: { jobWithPhases: any; onClose: () => void }) => {
  const h = useHistory()
  const { t } = useTranslation()

  const { company: currentCompany } = useAppSettingsContext()
  const companyId = currentCompany?.id ?? h.push('/')

  const jobId = jobWithPhases?.id

  const [getJobInventoryDetails, jobInventoryDetailsQuery] = useLazyQuery(JOB_INVENTORY_BY_FILTER)

  useEffect(() => {
    if (jobId && companyId) {
      // Keep same args as in FieldJobInventorySmart, so that this request can read from the frontend cache.
      getJobInventoryDetails({
        variables: {
          filter: {
            companyId: +companyId,
            jobId: +jobId,
            positionId: null,
            includeCarrierCodes: true,
          },
        },
      })
    }
  }, [companyId, getJobInventoryDetails, jobId])

  const jobInventoryDetails = jobInventoryDetailsQuery.data?.jobInventoryDetails?.filter(defined) ?? []
  const unfilteredPhases = jobWithPhases?.jobProductionPhases ?? []
  const phases = unfilteredPhases
    .filter((phase: any) => phase.noOfSortSubLines === 0 || phase.noOfSortSubLines === null)
    .sort((a: any, b: any) => a.parentLineNo - b.parentLineNo)

  return (
    <MDialog open={true} title={t('JOB_PHASES')} onClose={onClose} minWidth='xl'>
      <Section>
        <MFlexBlock vertical padding={2}>
          <PhasesSection
            phases={phases}
            jobInventoryDetails={jobInventoryDetails}
            unfilteredPhases={unfilteredPhases}
          />
        </MFlexBlock>
      </Section>
    </MDialog>
  )
}
