import { useTranslation } from 'react-i18next'
import { MColor } from '@mprise/react-ui'
import { ScanningSetting, useAppSettingsContext } from '../../context/AppSettingsContext'
import { Card } from '../../components/Card'
import { PageHeader } from '../../components/Header'
import { MaterialIcon } from '../../components/Icon'
import { List, ListItem } from '../../components/List'
import { Section, SectionList } from '../../components/Section'
import { useHistory } from '../../shared/use-history'

export const SettingsScanningRoute = () => {
  const { t } = useTranslation()
  const h = useHistory()

  const { scanSetting, setScanSetting } = useAppSettingsContext()

  const handleCancel = () => h.goBack()

  const handleSave = (selected: ScanningSetting) => {
    setScanSetting(selected)
    h.goBack()
  }

  return (
    <>
      <PageHeader title={t('TITLE_SETTINGS')} onCancel={handleCancel} />

      <SectionList>
        <Section>
          <Card header={t('CHOOSE_SCANNING_METHOD')}>
            <List>
              {Object.values(ScanningSetting)
                .map(x => ({ id: x, name: x }))
                .map(x => (
                  <ListItem
                    key={x.id}
                    icon={<MaterialIcon value={getScanningIconByScanningSetting(x.id)} style={{ fontSize: '2rem' }} />}
                    primary={t(`SCANNING_${x.name}`)}
                    action={
                      scanSetting === x.id ? <MaterialIcon value='check' style={{ color: MColor.primary }} /> : null
                    }
                    onClick={() => handleSave(x.id)}
                  />
                ))}
            </List>
          </Card>
        </Section>
      </SectionList>
    </>
  )
}

const getScanningIconByScanningSetting = (setting: ScanningSetting): string => {
  switch (setting) {
    case ScanningSetting.CAMERA:
      return 'photo_camera'
    case ScanningSetting.KEYBOARD:
      return 'keyboard'
    case ScanningSetting.SCANNER:
      return 'qr_code_scanner'
    default:
      return 'qr_code_scanner'
  }
}
