import { gql } from '@apollo/client'
import { FragmentJobInventoryPutAway } from '../../fragment/jobInventoryPutAway'

export const GET_AREA_REGISTRATION_TASKS = gql`
  query getAreaRegistrationTasks($jobCode: String!) {
    getAreaRegistrationTasks(jobCode: $jobCode) {
      id
      name
      order
      status
      taskOptions
      workItem {
        id
        number
        itemDescription
        item {
          id
          name
        }
        phaseCode
        plannedQuantity
        unitOfMeasure
        jobs {
          id
          code
          name
        }
        plannedPositions {
          id
          code
          name
        }
        jobInventoryPutAway(frontend: true) {
          ...FragmentJobInventoryPutAway
        }
      }
    }
  }

  ${FragmentJobInventoryPutAway}
`
