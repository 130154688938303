import { IconButton } from '@mui/material'
import { mdiBarcodeScan } from '@mdi/js'
import { Icon } from '@mdi/react'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ScannerDialog } from './scanner-dialog'
import { BarcodeCompletedHandler } from '@mprise/react-ui'

export const InstantScanButton = ({
  scanDialogTitle,
  onScanned,
  shouldOpen,
  onClose,
}: {
  scanDialogTitle?: string
  onScanned: BarcodeCompletedHandler
  shouldOpen?: boolean
  onClose?: () => void
}) => {
  const { t } = useTranslation()
  const [open, setOpen] = useState(false)
  const handleOpen = () => setOpen(true)
  const handleClose = () => {
    setOpen(false)
    if (onClose) {
      onClose()
    }
  }
  const handleCompleted: BarcodeCompletedHandler = args => {
    handleClose()
    onScanned(args)
  }
  const [title, setTitle] = useState<string>('')
  useEffect(() => {
    if (scanDialogTitle) {
      setTitle(scanDialogTitle)
    }
  }, [scanDialogTitle])

  useEffect(() => {
    if (shouldOpen) {
      setOpen(true)
    }
  }, [shouldOpen])

  return (
    <>
      {open && (
        <ScannerDialog open={open} scanDialogTitle={title} onCancelled={handleClose} onCompleted={handleCompleted} />
      )}
      <IconButton aria-label={t('search')} onClick={handleOpen}>
        <Icon size={1} path={mdiBarcodeScan} />
      </IconButton>
    </>
  )
}
