import { gql } from '@apollo/client'

export const MUTATE_GROUPED_JOB_INVENTORIES = gql`
  mutation mutateGroupedJobInventoriesGH(
    $originJobInventoryIds: [Int!]!
    $destinations: [JobInventoryDestinationInput!]!
    $resourceId: Int!
    $destinationPhase: String
  ) {
    mutateGroupedJobInventoriesGH(
      originJobInventoryIds: $originJobInventoryIds
      destinations: $destinations
      resourceId: $resourceId
      destinationPhase: $destinationPhase
    ) {
      problem
      succeeded
      failed
      destinationJidIds
    }
  }
`
