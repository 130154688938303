import { gql } from '@apollo/client'

export const BULK_REDUCE_JOB_INVENTORY = gql`
  mutation bulkReduceJobInventory($jobInventoryIdsAndQuantities: [JobInventoryIdAndQuantityInput!]!) {
    bulkReduceJobInventoryGH(jobInventoryIdsAndQuantities: $jobInventoryIdsAndQuantities) {
      problem
      succeeded
      failed
    }
  }
`
