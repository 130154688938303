import React from 'react'
import { useTranslation } from 'react-i18next'
import { Helmet } from 'react-helmet'
import { useMutation } from '@apollo/client'
import { Formik, MAudio, withFormikCompareFix } from '@mprise/react-ui'
import { useHistory } from '../../shared/use-history'
import { useLocalState } from '../../shared/local-state'
import { JobMutationsForm } from './JobMutationsForm'
import { FlashAlerts } from '../../shared/flash-alerts'
import { SavingSwitchPanel } from '../../shared/saving-switch-panel'
import { MutationErrorMessage } from '../../shared/apollo'
import { ValidationIssues } from '../../mprise-light/ValidationIssues'
import { NumberFormatItem, useAppSettingsContext } from '../../context/AppSettingsContext'
import { DialogFormik } from '../../mprise-light/DialogFormik'
import { REPORT_TRANSFER_TO_POSITION } from '../../gql/mutation/reportTransferToPosition'
import { parseFloatQuantity } from '../../shared/formats'
import { Maybe } from '../../shared/enums'
import { JobInventoryDetail, JobProductionPhase, Position } from '../../shared/interfaces'
import { fail } from '../../shared/typescript'
import { SortingFormEntry } from '../../shared/form/FieldComboSortToPosition'
import { REPORT_SORT_AND_TRANSFER } from '../../gql/mutation/reportSortAndTransferToPosition'
import { MUTATE_GROUPED_JOB_INVENTORIES } from '../../gql/mutation/mutateGroupedJobInventories'

export interface JobMutationsEntryForm {
  job: Maybe<{ id: string; name: string; code: Maybe<string>; item?: Maybe<{ id: string; name?: Maybe<string> }> }>
  toPosition: Maybe<Position>
  toPhase?: Maybe<JobProductionPhase>
  sortingEntries: Array<SortingFormEntry>
  selectedJobInventories: Array<JobInventoryDetail>
  originalQuantity: Maybe<number>
  quantity: Maybe<number>
}

export const JobMutationsRoute = () => {
  const h = useHistory()
  const { t } = useTranslation()
  const schema = JobMutationsForm.useSchema()
  const alerts = FlashAlerts.useAlert()
  const { resource, numberFormat } = useAppSettingsContext()
  const resourceId = resource?.id ?? h.push('/')

  const handleClose = async (e?: React.FormEvent<Element>) => {
    e?.preventDefault()
    e?.stopPropagation()
    h.goBack()
  }

  const [reportTransferToPosition, reportTransferToPositionState] = useMutation(REPORT_TRANSFER_TO_POSITION)
  const [reportSortAndTransfer, reportSortAndTransferState] = useMutation(REPORT_SORT_AND_TRANSFER)
  const [mutateGroupedJobInventories, mutateGroupedJobInventoriesState] = useMutation(MUTATE_GROUPED_JOB_INVENTORIES)

  const [initialValues] = useLocalState((): JobMutationsEntryForm => {
    return withFormikCompareFix({
      job: { id: '', name: '', code: '' },
      toPosition: { id: '', name: '', code: '' },
      sortingEntries: [],
      selectedJobInventories: [],
      originalQuantity: null,
      quantity: null,
      isWorkItemTransfer: false,
    })
  }, [])

  const handleSubmit = async (form: JobMutationsEntryForm, actions: Formik.FormikHelpers<JobMutationsEntryForm>) => {
    actions.resetForm()

    if (!resourceId) {
      fail('expects resource id')
    }

    const sortingEntries = form.sortingEntries

    if (form.selectedJobInventories.length > 1) {
      const response = await mutateGroupedJobInventories({
        variables: {
          originJobInventoryIds: form.selectedJobInventories.map(x => +x.id),
          destinations: getSortAndTransferDestinations(sortingEntries, numberFormat),
          resourceId: +resourceId,
          destinationPhase: form.toPhase?.productionPhase,
        },
      })
      if (response.data) {
        MAudio.scanSuccess()
        alerts.push(t('SUCCESS_MESSAGE'), 'success')
      }
    } else {
      const originJobInventoryDetailId = form.selectedJobInventories[0]!.id
      const jobHasSortings = !!sortingEntries.length
      if (jobHasSortings) {
        const response = await reportSortAndTransfer({
          variables: {
            originJobInventoryDetailId: +originJobInventoryDetailId,
            sortAndTransferDestinations: getSortAndTransferDestinations(
              sortingEntries.filter(x => x.quantity && x.quantity > 0 && x.toPosition?.id),
              numberFormat,
            ),
            destinationPhase: form.toPhase?.productionPhase,
            resourceId: +resourceId,
          },
        })
        if (response.data) {
          MAudio.scanSuccess()
          alerts.push(t('SUCCESS_MESSAGE'), 'success')
        }
      } else {
        const response = await reportTransferToPosition({
          variables: {
            originJobInventoryDetailId: +originJobInventoryDetailId,
            destinationPositionId: +form.toPosition!.id,
            quantity: parseFloatQuantity(form.quantity!, numberFormat),
            resourceId: +resourceId,
            destinationPhase: form.toPhase?.productionPhase,
            workItemId: null,
          },
        })
        if (response.data) {
          MAudio.scanSuccess()
          alerts.push(t('SUCCESS_MESSAGE'), 'success')
        }
      }
    }
  }

  return (
    <>
      <Helmet title={t('JOB_MUTATIONS')} />

      <Formik.Formik initialValues={initialValues} validationSchema={schema} onSubmit={handleSubmit}>
        <DialogFormik minWidth='xl' title={t('JOB_MUTATIONS')} onClose={handleClose} open={true}>
          <SavingSwitchPanel
            mutation={[reportTransferToPositionState, reportSortAndTransferState, mutateGroupedJobInventoriesState]}
          >
            <ValidationIssues />
            <MutationErrorMessage
              mutation={[reportTransferToPositionState, reportSortAndTransferState, mutateGroupedJobInventoriesState]}
            />
            <JobMutationsForm />
          </SavingSwitchPanel>
        </DialogFormik>
      </Formik.Formik>
    </>
  )
}

function getSortAndTransferDestinations(sortingEntries: SortingFormEntry[], numberFormat: NumberFormatItem) {
  return sortingEntries.map((x: any) => ({
    sorting: x.sortingCode,
    quantity: parseFloatQuantity(x.quantity, numberFormat),
    positionId: +x.toPosition.id,
  }))
}
