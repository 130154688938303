import React, { useEffect } from 'react'
import { MAvatar, MDialog, MSelectItem, ToMSelectItem } from '@mprise/react-ui'
import { useLocalState } from '../local-state'
import { useDebounceValue } from '../debounce-value'
import { useLazyQuery } from '@apollo/client'
import { LOTS_BY_FILTER } from '../../gql/query/lot/lotsByFilter'
import { PaginatedSearchAndSelect } from '../../mprise-light/PaginatedSearchAndSelect'
import { QueryErrorMessage } from '../apollo'
import { Lot } from '../interfaces'

const toSelectItem = ToMSelectItem<Lot>({
  id: x => x.id ?? '',
  icon: () => <MAvatar.Icon.TrackingId />,
  primary: x => x.lotNumber ?? '',
  secondary: x => (x.item?.name && x.variantCode ? `${x.item.name} - ${x.variantCode}` : null),
})

export const SelectLotNumberDialog = ({
  title,
  open,
  itemId,
  plannedVariantCode,
  companyId,
  plannedLotNumbers = [],
  allowOverruleLots,
  onClose,
  onSave,
}: {
  title: React.ReactNode
  open: boolean
  itemId: string
  plannedVariantCode: string
  companyId: string
  plannedLotNumbers?: string[]
  allowOverruleLots: boolean
  onClose: () => void
  onSave: (values: Lot | null) => void
}) => {
  const [search, setSearch] = useLocalState(``, [open])
  const debouncedSearch = useDebounceValue(search, 500)

  const [lotsByFilter, { data, loading, error, fetchMore, refetch }] = useLazyQuery(LOTS_BY_FILTER)

  const useStaticLotsList = plannedLotNumbers?.length && !allowOverruleLots

  useEffect(() => {
    if (open && !useStaticLotsList) {
      lotsByFilter({
        variables: {
          filter: {
            companyId: +companyId,
            ...(debouncedSearch && { searchString: debouncedSearch }),
            open: true,
            itemId: +itemId,
            variantCode: plannedVariantCode,
          },
          limit: 20,
          offset: 0,
        },
      })
    }
  }, [open, debouncedSearch, lotsByFilter, companyId])

  const handleSelect = async (item: MSelectItem<Lot> | null) => {
    onSave(item?.data ?? null)
  }

  const handleSubmit: React.FormEventHandler = async e => {
    e.stopPropagation()
    e.preventDefault()
    onClose()
  }

  const selectableLots = useStaticLotsList
    ? plannedLotNumbers.map(lotNumber => ({ id: '', lotNumber }) as unknown as Lot).map(toSelectItem) ?? []
    : data?.lots?.page?.map(toSelectItem) ?? []

  return (
    <MDialog minWidth='sm' open={open} title={title} onClose={onClose} onSubmit={handleSubmit}>
      <QueryErrorMessage query={{ error, refetch, loading }} />
      <PaginatedSearchAndSelect
        items={selectableLots}
        search={search}
        value={null}
        loading={loading}
        onChange={handleSelect}
        fetchMore={fetchMore}
        onSearch={setSearch}
        showSwitchKeyboardOption={true}
        fetchMoreDataAccessor={(data: any) => data.lots}
      />
    </MDialog>
  )
}
