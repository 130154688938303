import { FieldTitle } from '../../components/FieldTitle'
import { useTranslation } from 'react-i18next'
import { suppressEnter } from '../util/util'
import { FieldDecimal } from '../../mprise-light/FieldDecimal'

export const FieldDecimalQuantity = ({
  disabled,
  title,
  max,
}: {
  disabled?: boolean
  title?: string
  max?: number
}) => {
  const { t } = useTranslation()

  return (
    <>
      <FieldTitle title={title ?? t(`QUANTITY`)} />
      <div className='gh-field-quantity'>
        <FieldDecimal onKeyDown={suppressEnter} max={max} step={0.5} disabled={disabled} />
      </div>
    </>
  )
}
