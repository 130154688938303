import React, { ForwardedRef, useEffect, useImperativeHandle, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { mdiMagnify } from '@mdi/js'
import Icon from '@mdi/react'
import { Chip, CircularProgress, Divider, IconButton, Input } from '@mui/material'
import { makeStyles } from '@mui/styles'
import CloseIcon from '@mui/icons-material/Close'
import IconSearch from '@mui/icons-material/Search'
import IconCheck from '@mui/icons-material/Check'
import { BarcodeCompletedHandler, MColor, MFlexBlock, useMField } from '@mprise/react-ui'
import { InstantScanButton } from '../mprise-light/instant-scan-button'
import { FieldTitle } from '../components/FieldTitle'
import { ScanningSetting } from '../context/AppSettingsContext'

/* Copied from MFieldBarcode mprise/react-ui to adjust css */
let lookupCounter = 0

export const FieldBarcode = React.forwardRef(
  <T extends unknown>(
    {
      title,
      valueAsText,
      scanningSetting,
      disabled,
      onError,
      onExplore,
      onLookup,
      onSuccess,
      scanDialogTitle,
    }: {
      title?: string
      scanningSetting: ScanningSetting
      valueAsText: (item: T) => string
      disabled?: boolean
      onError?: (text: string, error: Error | null) => void
      onExplore: () => void
      onLookup: (text: string) => Promise<T | null> | T | null
      onSuccess?: (text: string, item: T | null) => void
      scanDialogTitle?: string
    },
    ref: ForwardedRef<HTMLInputElement>,
  ) => {
    const { t } = useTranslation()
    const f = useMField()
    const valueText = f.value ? valueAsText(f.value) : null
    const [text, setText] = useState(``)
    const [focused, setFocused] = useState(false)
    const [success, setSuccess] = useState(false)

    const enableInstantScan = scanningSetting === ScanningSetting.CAMERA

    const inputRef = useRef<HTMLInputElement>(null)
    useImperativeHandle(ref, () => inputRef.current as HTMLInputElement)

    useEffect(() => {
      setText(valueText ?? ``)

      // setSucces(true) to show IconCheck when input is prefilled or selected from dialog.
      if (valueText && !valueText.includes(t(`PLACEHOLDER_NOT_SET`))) {
        setSuccess(true)
      }
      setTimeout(() => {
        if (inputRef.current) {
          inputRef.current.setSelectionRange(0, inputRef.current.value.length)
        }
      })
    }, [valueText])

    const lookupState = useRef({
      started: 0,
      finished: 0,
    })
    const [_lookupVersion, setLookupVersion] = useState({})
    const doLookupStart = () => {
      const counter = ++lookupCounter
      lookupState.current.started = counter
      setLookupVersion({})
      return counter
    }
    const doLookupFinish = (counter: number) => {
      if (lookupState.current.started === counter) {
        lookupState.current.finished = counter
      }
      setLookupVersion({})
      return lookupState.current.finished === counter
    }
    const lookup = async (text: string) => {
      const counter = doLookupStart()
      try {
        const item = await onLookup?.(text)
        if (doLookupFinish(counter)) {
          f.onChange?.(item)
        }
        if (item) {
          setSuccess(true)
        }
        onSuccess?.(text, item)
      } catch (ex) {
        if (onError) {
          // onError(text, ex)
        } else {
          console.error(`[MFieldBarcode] lookup`, ex)
        }
      } finally {
        doLookupFinish(counter)
      }
    }

    const classes = useStyles()
    const enableClear = !!text || (f.value ?? null) !== null
    const isLoading = lookupState.current.started !== lookupState.current.finished
    const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
      setSuccess(false)
      setText(e.target.value)
    }

    const handleFocus = (e: React.FocusEvent<HTMLTextAreaElement | HTMLInputElement>) => {
      e?.preventDefault()
      setFocused(true)
      e.currentTarget.setSelectionRange(0, e.currentTarget.value.length)
      if (scanningSetting !== ScanningSetting.KEYBOARD)
        disableKeyboardPopup(true)
    }

    const handleBlur = () => {
      setFocused(false)
      setText(valueText ?? ``)
      if (scanningSetting !== ScanningSetting.KEYBOARD)
        disableKeyboardPopup(false)
    }
    const handleKeyPress = (e: React.KeyboardEvent<HTMLTextAreaElement | HTMLInputElement>) => {
      e.currentTarget.readOnly = false
      if (e.key === `Enter`) {
        e.preventDefault()
        e.currentTarget.setSelectionRange(0, e.currentTarget.value.length)
        setSuccess(false)
        lookup(text)
      }
    }

    const preventDefault = (e: React.SyntheticEvent) => {
      e.preventDefault()
    }
    const handleEnterButton = () => {
      inputRef.current?.focus()
      inputRef.current?.setSelectionRange(0, inputRef.current.value.length)
      setSuccess(false)
      lookup(text)
    }
    const handleClear = () => {
      setSuccess(false)
      setText(``)
      f.onChange?.(null)
      inputRef.current?.focus()
    }
    const handleScanComplete: BarcodeCompletedHandler = e => {
      if (inputRef.current)
        inputRef.current.readOnly = false
      setSuccess(false)
      setText(e.code)
      lookup(e.code)
      setTimeout(() => {
        inputRef.current?.focus()
        inputRef.current?.setSelectionRange(0, inputRef.current.value.length)
      })
    }

    const disableKeyboardPopup = (disable: boolean) => {
      const input = inputRef.current
      if (input) {
        input.readOnly = disable
        disable ? 
          input.classList.add('inputDisabled') :
          input.classList.remove('inputDisabled')
      }
    }

    const inputMode = getInputMode(scanningSetting)

    const enableEnterButton =
      [ScanningSetting.KEYBOARD, ScanningSetting.CAMERA].includes(scanningSetting) && focused && text && !isLoading

    return (
      <>
        <MFlexBlock
          bgColor={MColor.white}
          variant='rounded'
          alignItems='center'
          grow={4}
          gap={2}
          padding={0}
          style={{ justifyContent: 'space-between' }}
        >
          <FieldTitle title={title ?? ''} />
          <MFlexBlock>
            <Divider className={classes.divider} orientation='vertical' flexItem />
            {enableInstantScan && <InstantScanButton onScanned={handleScanComplete} />}
            <IconButton onClick={onExplore}>
              <Icon path={mdiMagnify} size={1} />
            </IconButton>
          </MFlexBlock>
        </MFlexBlock>

        <MFlexBlock bgColor={MColor.white} variant='rounded' alignItems='center' grow={4} gap={2} padding={0}>
          <Input
            disabled={disabled}
            className={text !== '' ? classes.inputField : classes.inputBlink}
            style={disabled ? { backgroundColor: '#dfe5ec' } : {}}
            name={f.name ?? undefined}
            id={f.id ?? undefined}
            value={focused ? text : valueText}
            onChange={handleChange}
            onFocus={handleFocus}
            disableUnderline
            onBlur={handleBlur}
            inputProps={{
              onKeyPress: handleKeyPress,
              inputMode: inputMode,
            }}
            endAdornment={[
              enableEnterButton ? (
                <Chip
                  clickable
                  icon={<IconSearch />}
                  label='ENTER'
                  size='small'
                  onMouseDown={preventDefault}
                  onClick={handleEnterButton}
                  key={1}
                />
              ) : null,
              isLoading ? (
                <IconButton size='small' key={2}>
                  <CircularProgress size='1em' />
                </IconButton>
              ) : success ? (
                <IconButton
                  onMouseDown={preventDefault}
                  onClick={handleClear}
                  style={{ color: MColor.primary }}
                  size='small'
                  key={2}
                >
                  <IconCheck />
                </IconButton>
              ) : enableClear ? (
                <IconButton onMouseDown={preventDefault} onClick={handleClear} size='small' key={2}>
                  <CloseIcon />
                </IconButton>
              ) : null,
            ]}
            fullWidth
            inputRef={inputRef}
          />
        </MFlexBlock>
      </>
    )
  },
)

// Blinking input field when no text is entered and on focus
const useStyles = makeStyles(() => ({
  divider: {
    height: 28,
    marginTop: 8,
    marginRight: 4,
    marginLeft: 4,
  },
  inputField: {
    border: '1px solid #839ab4',
    backgroundColor: '#ffffff',
    borderRadius: '0.2rem',
    paddingLeft: '0.6rem',
  },
  inputBlink: {
    border: '1px solid #839ab4',
    backgroundColor: '#ffffff',
    borderRadius: '0.2rem',
    paddingLeft: '0.6rem',
    '&.Mui-focused': {
      animation: `$blink 0.7s ${true ? 'infinite' : '0'}`,
    },
  },
  inputDisabled: {
    pointerEvents: 'none',
  },
  '@keyframes blink': {
    '0%': {
      backgroundColor: '#ffffff',
    },
    '100%': {
      backgroundColor: '#dfe5ec',
    },
  },
}))

export function getInputMode(setting: ScanningSetting): 'text' | 'none' {
  switch (setting) {
    case ScanningSetting.KEYBOARD:
      return 'text'
    case ScanningSetting.SCANNER:
      return 'none'
    case ScanningSetting.CAMERA:
      return 'none'
    default:
      return 'text'
  }
}
