import React from 'react'
import { useTranslation } from 'react-i18next'
import { SwitchTransition } from 'react-transition-group'
import { CircularProgress, Fade } from '@mui/material'
import { MColor, MFlexBlock, MText, px } from '@mprise/react-ui'
import { useFormikContext } from 'formik'

type QueryingValueInterface = {
  loading: boolean
}

export const SavingSwitchPanel = ({
  savingAnyway,
  mutation,
  children,
}: {
  savingAnyway?: boolean
  mutation: QueryingValueInterface | Array<QueryingValueInterface>
  children: React.ReactNode
}) => {
  const { t } = useTranslation()
  const saving = savingAnyway || [mutation].flat().some(x => x.loading)

  const isSubmitting = useFormikContext().isSubmitting

  return (
    <SwitchTransition>
      <Fade key={Number(saving)} timeout={100}>
        {saving ? (
          <MFlexBlock justifyContent='center' alignItems='center' padding={10} gap={5}>
            <MText block textColor={MColor.primary}>
              <CircularProgress color='inherit' size={px(24)} />
            </MText>
            <MText block textColor={MColor.primary}>
              {isSubmitting ? t('SAVING_CHANGES') : t('LOADING')}
            </MText>
          </MFlexBlock>
        ) : (
          <div>{children}</div>
        )}
      </Fade>
    </SwitchTransition>
  )
}
