import React, { useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useMutation } from '@apollo/client'
import { Formik, MAudio, ValidationIssues, withFormikCompareFix } from '@mprise/react-ui'
import { FlashAlerts } from '../../shared/flash-alerts'
import { useAppSettingsContext } from '../../context/AppSettingsContext'
import { useLocalState } from '../../shared/local-state'
import { DialogFormik } from '../../mprise-light/DialogFormik'
import { useHistory } from '../../shared/use-history'
import { AreaRegistrationForm } from './AreaPlanningForm'
import { Maybe, WorkItemTemplateTaskOption } from '../../shared/enums'
import { REPORT_TRANSFER_TO_POSITION } from '../../gql/mutation/reportTransferToPosition'
import { parseFloatQuantity } from '../../shared/formats'
import { SavingSwitchPanel } from '../../shared/saving-switch-panel'
import { MutationErrorMessage } from '../../shared/apollo'
import { JobInventoryDetail } from '../../shared/interfaces'
import { fail } from '../../shared/typescript'
import { AARConfirmFinishDialog } from './AARConfirmFinishDialog'
import { FINISH_TASK } from '../../gql/mutation/statusChange/statusChange'
import { FormikProps } from 'formik'
import { STOP_TIME_REG } from '../../gql/mutation/timeReg'
import { REPORT_JOB_INVENTORY_PUTAWAY } from '../../gql/mutation/reportJobInventoryPutAway'
import { BULK_REDUCE_JOB_INVENTORY } from '../../gql/mutation/bulkReduceJobInventory'

export interface AreaRegistrationEntryForm {
  job: Maybe<{ id: string; name: string; code: Maybe<string>; item?: Maybe<{ id: string; name?: Maybe<string> }> }>
  toPosition: Maybe<{ id: string; name: string; code: Maybe<string> }>
  toSubPosition: Maybe<{
    id: string
    name: string
    code: Maybe<string>
    index: number
    sub: string
    startPillar: number
    endPillar: number
  }>
  pillar: Maybe<number>
  spacingMethod: Maybe<{ id: Maybe<number>; description: string; code: Maybe<string> }>
  jobInventoryDetail: Maybe<JobInventoryDetail>
  originalQuantity: Maybe<number>
  quantity: Maybe<number> | undefined
  reportedQuantity: Maybe<number> | undefined
  workItemId: Maybe<string> | undefined
  task: any
  isWorkItemTransfer: boolean
  subPositions: Array<any>
  phaseCode?: Maybe<string>
}

export const emptyFormAAR = {
  job: { id: '', name: '', code: '' },
  toPosition: { id: '', name: '', code: '' },
  toSubPosition: null,
  pillar: null,
  spacingMethod: { id: null, description: '', code: '' },
  jobInventoryDetail: null,
  originalQuantity: null,
  quantity: undefined,
  reportedQuantity: null,
  workItemId: null,
  task: null,
  isWorkItemTransfer: false,
  subPositions: [],
  phaseCode: null,
  fromPosition: null,
}

// does not clear position, sub, pillar and spacingMethod
export const partialEmptyFormAAR = {
  job: { id: '', name: '', code: '' },
  jobInventoryDetail: null,
  originalQuantity: null,
  quantity: undefined,
  workItemId: null,
  task: null,
  isWorkItemTransfer: false,
  phaseCode: null,
  fromPosition: null,
}

export const AreaRegistrationRoute = () => {
  const formRef = useRef<FormikProps<AreaRegistrationEntryForm>>(null)
  const h = useHistory()
  const { t } = useTranslation()
  const schema = AreaRegistrationForm.useSchema()
  const alerts = FlashAlerts.useAlert()
  const resourceId = useAppSettingsContext().resource?.id ?? h.push('/')
  const { numberFormat } = useAppSettingsContext()

  const [showFinishConfirmDialog, setShowFinishConfirmDialog] = useState<boolean>(false)
  const [savedFormValues, setSavedFormValues] = useState<Maybe<AreaRegistrationEntryForm>>(null)
  const [dialogWorkItemId, setDialogWorkItemId] = useState<string | null>(null)
  const [dialogTaskId, setDialogTaskId] = useState<string | null>(null)

  const [finishTask, finishTaskState] = useMutation(FINISH_TASK)
  const [stopTimeReg] = useMutation(STOP_TIME_REG)

  const [initialValues] = useLocalState((): AreaRegistrationEntryForm => {
    return withFormikCompareFix(emptyFormAAR)
  }, [])

  const [reportTransferToPosition, reportTransferToPositionState] = useMutation(REPORT_TRANSFER_TO_POSITION)
  const [reportJobInventoryPutAway, reportJobInventoryPutAwayState] = useMutation(REPORT_JOB_INVENTORY_PUTAWAY)
  const [bulkReduceJobInventory, bulkReduceJobInventoryState] = useMutation(BULK_REDUCE_JOB_INVENTORY)

  const handleClose = async (e: React.FormEvent<Element>) => {
    e.preventDefault()
    e.stopPropagation()
    h.goBack()
  }

  const handleSubmit = async (
    form: AreaRegistrationEntryForm,
    actions: Formik.FormikHelpers<AreaRegistrationEntryForm>,
  ) => {
    if (!resourceId) {
      fail('expects resource id')
    }
    const savedForm = { ...form }
    const originJobInventoryDetailId = form.jobInventoryDetail!.id
    const destinationPositionId = form.toSubPosition!.id
    const pillar = form.pillar
    const sorting = form.jobInventoryDetail?.sortingCode
    const isWorkItemTransfer = form.isWorkItemTransfer
    const workItemId = form.workItemId
    const quantity = form.quantity!
    const phaseCode = form.phaseCode
    const spacingMethodId = form.spacingMethod?.id
    const taskId = form.task?.id

    const formattedQuantity = parseFloatQuantity(quantity, numberFormat)
    const reportedQuantity = (form?.reportedQuantity ?? 0) + formattedQuantity

    if (isWorkItemTransfer && workItemId && taskId) {
      setDialogWorkItemId(workItemId)
      setDialogTaskId(taskId)
    }

    let success = true
    if (isWorkItemTransfer && workItemId) {
      const task = form.task
      const taskId = task?.id
      const taskOptions = task?.taskOptions ?? []
      const bypassJobInventory = taskOptions.includes(WorkItemTemplateTaskOption.BypassJobInventoryGh)

      if (bypassJobInventory) {
        await handleReportJobInventoryPutAway(workItemId!, task)
      } else {
        const result = await handlereportTransferToPosition()
        success &&= !!result.data
      }

      const plannedQuantity = form.task.workItem.plannedQuantity as number
      const isFulfilled = reportedQuantity >= plannedQuantity

      if (isFulfilled) {
        await handleFinishTask(workItemId!, taskId, resourceId, finishTask, stopTimeReg)
        actions.resetForm()
        actions.setValues({ ...savedForm, ...partialEmptyFormAAR })
      } else {
        setSavedFormValues({ ...form })
        setShowFinishConfirmDialog(true)
      }
    } else {
      const result = await handlereportTransferToPosition()
      success &&= !!result.data
    }

    if (success) {
      MAudio.scanSuccess()
      alerts.push(t('SUCCESS_MESSAGE'), `success`)

      if (form.jobInventoryDetail?.remainingQuantity && form.quantity) {
        const newRemainingQuantity = form.jobInventoryDetail.remainingQuantity - form.quantity

        actions.resetForm()

        if (newRemainingQuantity <= 0) {
          actions.setValues({ ...savedForm, ...partialEmptyFormAAR })
        } else {
          // partial registrations
          const newForm = {
            ...savedForm,
            jobInventoryDetail: {
              ...savedForm.jobInventoryDetail,
              remainingQuantity: newRemainingQuantity,
            } as JobInventoryDetail,
            quantity: newRemainingQuantity,
            reportedQuantity: reportedQuantity,
          }

          actions.setValues(newForm)
        }
      }
    }

    async function handleReportJobInventoryPutAway(workItemId: string, task: any) {
      const jobInventoryPutAways = task?.workItem.jobInventoryPutAway
      const jobInventoryPutAway =
        jobInventoryPutAways.length === 1
          ? jobInventoryPutAways[0]
          : jobInventoryPutAways.find(
              (x: any) => x.planned.sorting === sorting && x.planned.position.id === destinationPositionId,
            ) ?? jobInventoryPutAways.find((x: any) => x.planned.sorting === sorting)

      await reportJobInventoryPutAway({
        variables: {
          workItemId: +workItemId!,
          taskId: +task.id,
          taskPutAwayId: +jobInventoryPutAway.id,
          values: {
            resourceId: +resourceId,
            positionId: +destinationPositionId,
            pillar: pillar,
            sorting: sorting,
            quantity: formattedQuantity,
            quantityUnit: form.jobInventoryDetail?.unitOfMeasure,
            quantityPerArea: jobInventoryPutAway.planned.quantityPerArea,
            quantityPerAreaUnit: jobInventoryPutAway.planned.quantityPerAreaUnit,
          },
        },
      }).then(response => {
        success &&= !!response.data

        bulkReduceJobInventory({
          variables: {
            jobInventoryIdsAndQuantities: [
              {
                jobInventoryDetailId: originJobInventoryDetailId,
                quantity: formattedQuantity,
              },
            ],
          },
        })
      })
    }

    async function handlereportTransferToPosition() {
      return await reportTransferToPosition({
        variables: {
          originJobInventoryDetailId: +originJobInventoryDetailId,
          destinationPositionId: +destinationPositionId,
          quantity: formattedQuantity,
          destinationPhase: phaseCode,
          resourceId: +resourceId!,
          workItemId: workItemId ? +workItemId : null,
          pillar: pillar,
          spacingMethodId: spacingMethodId,
        },
      })
    }
  }

  const handleConfirmFinishTask = async (workItemId: any, taskId: any) => {
    if (workItemId && taskId && resourceId) {
      await handleFinishTask(workItemId, taskId, resourceId, finishTask, stopTimeReg)
      formRef.current?.resetForm()
      if (savedFormValues) {
        formRef.current?.setValues({ ...savedFormValues, ...partialEmptyFormAAR })
        setSavedFormValues(null)
      }
    }
  }

  return (
    <>
      <Formik.Formik initialValues={initialValues} validationSchema={schema} onSubmit={handleSubmit} innerRef={formRef}>
        <DialogFormik minWidth='xl' title={t('AREA_REGISTRATION')} onClose={handleClose} open={true}>
          <SavingSwitchPanel
            mutation={[
              reportTransferToPositionState,
              reportJobInventoryPutAwayState,
              bulkReduceJobInventoryState,
              finishTaskState,
            ]}
          >
            <ValidationIssues />
            <MutationErrorMessage
              mutation={[
                reportTransferToPositionState,
                reportJobInventoryPutAwayState,
                bulkReduceJobInventoryState,
                finishTaskState,
              ]}
            />
            <AreaRegistrationForm />
          </SavingSwitchPanel>
        </DialogFormik>
      </Formik.Formik>
      {showFinishConfirmDialog && (
        <AARConfirmFinishDialog
          open={showFinishConfirmDialog}
          onConfirmFinish={() => {
            setShowFinishConfirmDialog(false)
            handleConfirmFinishTask(dialogWorkItemId, dialogTaskId)
          }}
          onCancelFinish={() => {
            setShowFinishConfirmDialog(false)
            setSavedFormValues(null)
          }}
        />
      )}
    </>
  )
}
async function handleFinishTask(
  workItemId: string,
  taskId: string,
  resourceId: string,
  finishTask: any,
  stopTimeReg: any,
) {
  await finishTask({
    variables: {
      workItemId: +workItemId,
      taskId: +taskId,
      currentResourceId: +resourceId,
    },
  }).then((response: { data: { finishTask: any } }) => {
    const task = response.data.finishTask
    stopTimeReg({
      variables: {
        workItemId: task.workItem.cosmosKey,
        taskId: task.cosmosKey,
      },
    })
  })
}
