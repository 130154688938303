import React, { useEffect } from 'react'
import { MAvatar, MSelectItem, ToMSelectItem } from '@mprise/react-ui'
import { useAppSettingsContext } from '../../context/AppSettingsContext'
import { PaginatedSearchAndSelect } from '../../mprise-light/PaginatedSearchAndSelect'
import { QueryErrorMessage } from '../apollo'
import { useDebounceValue } from '../debounce-value'
import { useLocalState } from '../local-state'
import { defined } from '../typescript'
import { DialogWithBackButton } from '../../components/Dialog'
import { JOBS_BY_FILTER } from '../../gql/query/jobs/jobsByFilter'
import { useLazyQuery } from '@apollo/client'
import { Maybe } from '../enums'

type JobValue = {
  id: string
  code?: Maybe<string>
  name?: Maybe<string>
}

const toSelectItem = ToMSelectItem<JobValue>({
  id: x => x.id,
  icon: () => <MAvatar.Icon.Job />,
  primary: x => x.name ?? ``,
  secondary: x => x.code ?? null,
})

export const SelectJobDialog = ({
  title,
  open,
  onClose,
  onSave,
  itemConstraint,
}: {
  title: React.ReactNode
  open: boolean
  onClose: () => void
  onSave: (values: any) => void
  itemConstraint?: Maybe<{ id: string; name?: Maybe<string> }>
}) => {
  const { company } = useAppSettingsContext()
  const companyId = company?.id

  const [search, setSearch] = useLocalState('', [open])
  const debouncedSearch = useDebounceValue(search, 500)

  const [jobsByFilter, { error, loading, data: jobsByFilterData, fetchMore, refetch }] = useLazyQuery(JOBS_BY_FILTER)

  useEffect(() => {
    jobsByFilter({
      variables: {
        filter: {
          companyId: +companyId!,
          status: 'In production',
          removed: false,
          searchString: debouncedSearch,
          applyDataPermissions: true,
        },
        limit: 20,
        offset: 0,
      },
    })
  }, [debouncedSearch])

  const handleSelect = async (item: MSelectItem<JobValue> | null) => {
    onSave(item?.data ?? null)
  }

  const handleSubmit: React.FormEventHandler = async e => {
    e.stopPropagation()
    e.preventDefault()
    onClose()
  }

  const jobs = jobsByFilterData?.jobs?.page?.filter(defined).map(toSelectItem) ?? null

  return (
    <DialogWithBackButton open={open} title={title} onClose={onClose} onSubmit={handleSubmit}>
      <QueryErrorMessage query={{ error, refetch, loading }} />
      <PaginatedSearchAndSelect
        items={jobs}
        search={search}
        value={null}
        loading={loading}
        onChange={handleSelect}
        fetchMore={fetchMore}
        onSearch={setSearch}
        showSwitchKeyboardOption={true}
        fetchMoreDataAccessor={(data: any) => data?.jobs?.page}
      />
    </DialogWithBackButton>
  )
}
