import { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useLazyQuery } from '@apollo/client'
import { CircularProgress } from '@mui/material'
import { MColor, MFlexBlock, MText, MTextColor } from '@mprise/react-ui'
import { CompanySubset, useAppSettingsContext } from '../../context/AppSettingsContext'
import { Card } from '../../components/Card'
import { PageHeader } from '../../components/Header'
import { MaterialIcon } from '../../components/Icon'
import { List, ListItem } from '../../components/List'
import { SearchInput } from '../../components/SearchInput'
import { Section, SectionList } from '../../components/Section'
import { defined } from '../../shared/typescript'
import { useHistory } from '../../shared/use-history'
import { useDebounceValue } from '../../shared/debounce-value'
import { GET_COMPANIES } from '../../gql/companies'
import { QueryErrorMessage } from '../../shared/apollo'

export const SettingsCompanyRoute = () => {
  const { t } = useTranslation()
  const h = useHistory()

  const [search, setSearch] = useState('')
  const debouncedSearch = useDebounceValue(search, 500)

  const [getCompanies, { data, loading, error, refetch }] = useLazyQuery(GET_COMPANIES, {
    onError: console.error,
  })

  const { company, setCompany } = useAppSettingsContext()

  useEffect(() => {
    getCompanies(
      debouncedSearch
        ? {
            variables: {
              filter: {
                name: debouncedSearch,
              },
            },
          }
        : undefined,
    )
  }, [debouncedSearch, getCompanies])

  const handleCancel = () => h.goBack()

  const handleSave = (selected: CompanySubset) => {
    if (selected?.id !== company?.id) {
      setCompany(selected)
    }
    h.goBack()
  }

  return (
    <>
      <PageHeader title={t('TITLE_SETTINGS')} onCancel={handleCancel} />

      <QueryErrorMessage query={{ error, refetch, loading }} />
      <SectionList>
        <Section>
          <Card header={t('TITLE_CHOOSE_COMPANY')}>
            <div className='ml-search'>
              <SearchInput onChange={setSearch} />
            </div>
            <List>
              {(data?.companies ?? []).filter(defined).map((c: CompanySubset) => (
                <ListItem
                  key={c.id}
                  icon={<MaterialIcon value='place' style={{ fontSize: '2rem' }} />}
                  primary={c.name}
                  action={
                    company?.id === c.id ? <MaterialIcon value='check' style={{ color: MColor.primary }} /> : null
                  }
                  onClick={() => handleSave({ id: c.id, name: c.name ?? '' })}
                />
              ))}
            </List>
          </Card>
        </Section>
        {loading && (
          <MFlexBlock padding={[4]} justifyContent='center'>
            <CircularProgress size={30} color='inherit' />
          </MFlexBlock>
        )}
        {!data?.companies?.length && !loading ? (
          <MFlexBlock padding={[4]} justifyContent='center'>
            <MText block textVariant='content bold' textColor={MTextColor.disabled}>
              {t('NO_RESULTS')}
            </MText>
          </MFlexBlock>
        ) : null}
      </SectionList>
    </>
  )
}
