import { MJoinChildren } from '@mprise/react-ui'
import { useHistory } from '../../shared/use-history'
import { useParams } from 'react-router'
import { Card } from '../../components/Card'
import { DialogHeaderBack } from '../../components/Header'
import { useEffect, useState } from 'react'
import { TaskItem } from './TaskItem'
import { useTranslation } from 'react-i18next'
import { useLazyQuery } from '@apollo/client'
import { WORKITEM_DETAILS_WITH_TASKS } from '../../gql/query/workItems/workItemDetailsWithTask'
import { WorkStatus } from '../../shared/enums'
import { TimeRegDataCard } from './TimeRegDataCard'
import { defined } from '../../shared/typescript'

export const WorkItemDetailsRoute = () => {
  const h = useHistory()
  const { t } = useTranslation()

  const workItemId = useParams().id ?? ''

  const [getWorkItem, workItemQueryResult] = useLazyQuery(WORKITEM_DETAILS_WITH_TASKS, {
    fetchPolicy: 'cache-and-network',
    variables: { where: [{ field: 'id', options: { eq: +workItemId } }] },
  })

  useEffect(() => {
    getWorkItem()
  }, [])

  const workItem = workItemQueryResult.data?.nworkItem ?? null
  const [selectedTaskId, setSelectedTaskId] = useState<string>('')

  useEffect(() => {
    if (workItem?.tasks && workItem.tasks.length > 0) {
      const hasClosedTask = workItem.tasks.some((task: { status: WorkStatus }) => task.status === WorkStatus.Closed)
      if (hasClosedTask) {
        const lastTask = workItem.tasks[workItem.tasks.length - 1]
        setSelectedTaskId(lastTask!.id)
        return
      }
      const firstTodoOrInProgressTask = workItem.tasks.find(
        (task: { status: WorkStatus }) => task?.status === WorkStatus.Todo || task?.status === WorkStatus.InProgress,
      )
      setSelectedTaskId(firstTodoOrInProgressTask?.id ?? workItem.tasks[0]!.id)
    }
  }, [workItem])

  const handleToggleTask = ({ taskId }: { taskId: string }) => {
    setSelectedTaskId(x => (x === taskId ? `` : taskId))
  }

  const timeRegData = workItem?.tasks?.map((x: any) => x?.timeReg)?.filter(defined)

  return (
    <>
      <DialogHeaderBack title={t(`TASK_DETAILS`)} onClose={() => h.goBack()} />
      <div className='gh-taskDetails-page-subtitle'>
        {workItem ? `${workItem?.number} - ${workItem?.name}` : `\u00A0`}
      </div>
      <Card>
        <MJoinChildren divider={() => null}>
          {workItem?.tasks
            ?.slice()
            .sort((a: { order: number }, b: { order: number }) => a.order - b.order)
            .map((task: { id: string }, idx: any) => (
              <TaskItem
                key={`${task.id}-${idx}`}
                taskId={task.id}
                workItemId={workItem.id}
                type={workItem.type}
                open={selectedTaskId === task.id}
                loading={workItemQueryResult.loading}
                onToggle={handleToggleTask}
              />
            ))}
        </MJoinChildren>
        {timeRegData?.length > 0 && <TimeRegDataCard timeRegData={timeRegData} />}
      </Card>
    </>
  )
}
