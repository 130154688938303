import { useTranslation } from 'react-i18next'
import { Helmet } from 'react-helmet'
import { useMutation } from '@apollo/client'
import { Formik, MAudio, withFormikCompareFix } from '@mprise/react-ui'
import { useHistory } from '../../shared/use-history'
import { useLocalState } from '../../shared/local-state'
import { PhaseForm } from './PhaseForm'
import { MutationErrorMessage } from '../../shared/apollo'
import { SavingSwitchPanel } from '../../shared/saving-switch-panel'
import { FlashAlerts } from '../../shared/flash-alerts'
import { ValidationIssues } from '../../mprise-light/ValidationIssues'
import { useAppSettingsContext } from '../../context/AppSettingsContext'
import { DialogFormik } from '../../mprise-light/DialogFormik'
import { fail } from '../../shared/typescript'
import { REPORT_TRANSITION_TO_PHASE } from '../../gql/mutation/reportTransitionToPhase'
import { Maybe } from '../../shared/enums'
import { parseFloatQuantity } from '../../shared/formats'
import { JobInventoryDetail, JobProductionPhase } from '../../shared/interfaces'

export interface PhaseEntryForm {
  job: Maybe<{ id: string; name: string; code: Maybe<string> }>
  fromPosition: Maybe<{ id: string; name: string; code: Maybe<string> }>
  jobInventoryDetail: Maybe<JobInventoryDetail>
  phase: Maybe<JobProductionPhase> | undefined
  originalQuantity: Maybe<number>
  quantity: Maybe<number>
  workItemId: Maybe<string>
}

export const PhaseRoute = () => {
  const h = useHistory()
  const { t } = useTranslation()
  const schema = PhaseForm.useSchema()
  const alerts = FlashAlerts.useAlert()

  const { resource, numberFormat } = useAppSettingsContext()
  const resourceId = resource?.id ?? h.push('/')

  const handleClose = () => {
    h.goBack()
  }

  const [reportTransitionToPhase, reportTransitionToPhaseState] = useMutation(REPORT_TRANSITION_TO_PHASE)

  const [initialValues] = useLocalState((): PhaseEntryForm => {
    return withFormikCompareFix({
      job: {
        id: '',
        name: '',
        code: '',
      },
      fromPosition: { id: '', name: '', code: '' },
      jobInventoryDetail: null,
      phase: null,
      originalQuantity: null,
      quantity: null,
      workItemId: null,
    })
  }, [])

  return (
    <>
      <Helmet title={t('PHASE_TRANSITION')} />

      <Formik.Formik
        initialValues={initialValues}
        validationSchema={schema}
        onSubmit={async (form, actions) => {
          actions.resetForm()

          if (!resourceId) {
            fail('expects resource id')
          }

          const originJobInventoryDetailId = form.jobInventoryDetail!.id
          const destinationPhase = form.phase!.productionPhase
          const destinationSortingCode =
            form.phase?.sortingCode && form.phase.sortingCode.length > 0 ? form.phase.sortingCode : null
          const quantity = form.quantity!

          const result = await reportTransitionToPhase({
            variables: {
              originJobInventoryDetailId: +originJobInventoryDetailId,
              destinationPhase: destinationPhase,
              destinationSortingCode: destinationSortingCode,
              quantity: parseFloatQuantity(quantity, numberFormat),
              resourceId: +resourceId,
            },
          })
          if (result.data) {
            MAudio.scanSuccess()
            alerts.push(t('SUCCESS_MESSAGE'), `success`)
          }
        }}
      >
        <DialogFormik minWidth='xl' title={t('PHASE_TRANSITION')} onClose={handleClose} open={true}>
          <SavingSwitchPanel mutation={reportTransitionToPhaseState}>
            <ValidationIssues />
            <MutationErrorMessage mutation={reportTransitionToPhaseState} />
            <PhaseForm />
          </SavingSwitchPanel>
        </DialogFormik>
      </Formik.Formik>
    </>
  )
}
