import { MColor, MTag, MTextColor, TagSize } from '@mprise/react-ui'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { WorkStatus } from '../shared/enums'

const activeColor: Record<WorkStatus, MColor> = {
  [WorkStatus.AwaitingHours]: MColor.status_on_hold,
  [WorkStatus.NotAssigned]: MColor.status_todo,
  [WorkStatus.Todo]: MColor.border,
  [WorkStatus.InProgress]: MColor.status_in_progress,
  [WorkStatus.OnHold]: MColor.status_on_hold,
  [WorkStatus.Done]: MColor.status_done,
  [WorkStatus.Closed]: MColor.dim,
  [WorkStatus.Pending]: MColor.status_todo,
  [WorkStatus.Rejected]: MColor.high,
}
const inactiveColor = MColor.border

export const TagStatus = ({
  outline,
  disabled,
  status,
  size,
  onClick,
}: {
  outline?: boolean
  disabled?: boolean
  status: WorkStatus
  size?: TagSize
  onClick?: React.MouseEventHandler
}) => {
  const { t } = useTranslation()
  const style = { cursor: onClick ? `pointer` : undefined }

  const primaryColor = disabled ? inactiveColor : activeColor[status]
  return (
    <MTag
      outline={outline}
      primary={primaryColor}
      secondary={primaryColor === inactiveColor ? MTextColor.dark : MColor.white}
      size={size}
      blockProps={{ onClick, style }}
    >
      {t(`Status.${status}`)}
    </MTag>
  )
}
