import { Button, CircularProgress } from '@mui/material'
import { MColor, MFlexBlock, MText, px } from '@mprise/react-ui'
import { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useInView } from 'react-intersection-observer'

/* Copied from mprise/react-ui. Because import failed.
 */

export const NextPageView = ({
  offset,
  fetchMore,
  pageSize,
  fetchMoreDataAccessor,
}: {
  offset: number | null
  fetchMore: (fetchMoreOptions: any) => Promise<any>
  pageSize: number
  fetchMoreDataAccessor: (data: any) => any
}) => {
  const { t } = useTranslation()

  const __requested = useRef<number | null>(null)
  const [requested, setRequested] = useState<number | null>(null)
  const { ref, inView } = useInView()
  const [hasNextPage, setHasNextPage] = useState(false)

  useEffect(() => {
    if (offset) {
      setHasNextPage(offset % pageSize === 0)
    }
  }, [offset])

  const handleFetch = () => {
    if (__requested.current === offset) {
      // Already requested
    } else {
      __requested.current = offset
      setRequested(offset)
      if (offset) {
        fetchMore({
          variables: {
            offset: offset,
          },
        }).then(fetchMoreResult => {
          const length = fetchMoreDataAccessor(fetchMoreResult.data)?.length ?? 0

          if (length < pageSize) {
            setHasNextPage(false)
          }
        })
      }
    }
  }

  useEffect(() => {
    if (inView) {
      handleFetch()
    }
  }, [inView])

  if (!offset || !hasNextPage) {
    return null
  }

  return (
    <div ref={ref}>
      {requested === offset ? (
        <div style={{ minHeight: '100vh' }}>
          <MFlexBlock justifyContent='center' alignItems='center' padding={10} gap={5}>
            <MText block textColor={MColor.primary}>
              <CircularProgress color='inherit' size={px(48)} />
            </MText>
            <MText block textColor={MColor.primary}>
              {t(`NOTIFICATION_NEXT_PAGE_LOADING`)}
            </MText>
          </MFlexBlock>
        </div>
      ) : (
        <MFlexBlock justifyContent='center' alignItems='start' padding={10} gap={5}>
          <Button variant='outlined' color='primary' onClick={handleFetch}>
            {t(`ACTION_LOAD_NEXT_PAGE`)}
          </Button>
        </MFlexBlock>
      )}
    </div>
  )
}
