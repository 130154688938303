import React, { useEffect } from 'react'
import { useLazyQuery } from '@apollo/client'
import { MAvatar, MSelectItem, ToMSelectItem } from '@mprise/react-ui'
import { useAppSettingsContext } from '../../context/AppSettingsContext'
import { PaginatedSearchAndSelect } from '../../mprise-light/PaginatedSearchAndSelect'
import { QueryErrorMessage } from '../apollo'
import { useDebounceValue } from '../debounce-value'
import { useLocalState } from '../local-state'
import { defined } from '../typescript'
import { DialogWithBackButton } from '../../components/Dialog'
import { CARRIERS_BY_FILTER } from '../../gql/carriers'
import { Maybe } from '../enums'

export type CarrierValue = {
  id: string
  name?: Maybe<string>
  code?: Maybe<string>
}

const toSelectItem = ToMSelectItem<CarrierValue>({
  id: x => x.id,
  icon: () => <MAvatar.Icon.Position />,
  primary: x => x.code ?? '',
})

export const SelectCarrierCodeDialog = ({
  title,
  open,
  onClose,
  onSave,
}: {
  title: React.ReactNode
  open: boolean
  onClose: () => void
  onSave: (values: any) => void
}) => {
  const [search, setSearch] = useLocalState(``, [open])
  const debouncedSearch = useDebounceValue(search, 500)
  const { company } = useAppSettingsContext()
  const companyId = company?.id

  const [carriersByFilter, { data, loading, error, fetchMore, refetch }] = useLazyQuery(CARRIERS_BY_FILTER)

  useEffect(() => {
    if (open) {
      carriersByFilter({
        variables: {
          filter: {
            companyId: +companyId!,
            ...(debouncedSearch && { searchString: debouncedSearch }),
            removed: false,
          },
          limit: 20,
          offset: 0,
        },
      })
    }
  }, [open, debouncedSearch, carriersByFilter, companyId])

  const handleSelect = async (item: MSelectItem<CarrierValue> | null) => {
    onSave(item?.data ?? null)
  }

  const handleSubmit: React.FormEventHandler = async e => {
    e.stopPropagation()
    e.preventDefault()
    onClose()
  }

  const carrierCodes = data?.positionCarriers?.page?.filter(defined).map(toSelectItem) ?? null

  return (
    <DialogWithBackButton open={open} title={title} onClose={onClose} onSubmit={handleSubmit}>
      <QueryErrorMessage query={{ error, refetch, loading }} />
      <PaginatedSearchAndSelect
        items={carrierCodes}
        search={search}
        value={null}
        loading={loading}
        onChange={handleSelect}
        fetchMore={fetchMore}
        onSearch={setSearch}
        showSwitchKeyboardOption={true}
        fetchMoreDataAccessor={(data: any) => data?.positionCarriers?.page}
      />
    </DialogWithBackButton>
  )
}
