import { useTranslation } from 'react-i18next'
import { CircularProgress } from '@mui/material'
import { makeStyles } from '@mui/styles'
import {
  BarcodeInputStatus,
  MColor,
  MFlex,
  MFlexBlock,
  MFlexItem,
  MSelectItem,
  MText,
  px,
  ScanningSetting,
  SearchBox,
} from '@mprise/react-ui'
import { SelectList } from './SelectList'
import { NextPageView } from './NextPageView'

/* Copied from mprise/react-ui. 
   Removed multi-select option.
*/
export function PaginatedSearchAndSelect<T>({
  value,
  items,
  search,
  loading,
  onSearch,
  onChange,
  fetchMore,
  showSwitchKeyboardOption,
  fetchMoreDataAccessor,
}: {
  value: MSelectItem<T> | null
  items: Array<MSelectItem<T>> | null
  search: string
  loading: boolean
  onSearch: (search: string) => void
  onChange: (value: MSelectItem<T> | null) => void
  fetchMore: (fetchMoreOptions: any) => Promise<any>
  showSwitchKeyboardOption: boolean
  fetchMoreDataAccessor: (data: any) => any
}) {
  const classes = useStyles()

  return (
    <MFlex vertical gap={2}>
      <MFlexItem shrink={0} grow={0} className={classes.searchBox}>
        <SearchBox
          onChange={onSearch}
          status={BarcodeInputStatus.NONE}
          scanningSetting={ScanningSetting.KEYBOARD}
          value={search}
          showSwitchKeyboardOption={showSwitchKeyboardOption}
        />
      </MFlexItem>
      <MFlexItem shrink={1} grow={1} style={{ overflow: `auto` }}>
        {loading ? <LoadingMessage /> : <SelectList items={items} value={value} onClick={onChange} />}
        {items ? (
          <NextPageView
            offset={items.length}
            fetchMore={fetchMore}
            pageSize={20}
            fetchMoreDataAccessor={fetchMoreDataAccessor}
          />
        ) : null}
      </MFlexItem>
    </MFlex>
  )
}

const LoadingMessage = () => {
  const { t } = useTranslation()
  return (
    <MFlexBlock justifyContent='center' alignItems='center' padding={5} gap={5}>
      <MText block textColor={MColor.primary}>
        <CircularProgress color='inherit' size={px(24)} />
      </MText>
      <MText block textColor={MColor.primary}>
        {t(`NOTIFICATION_PAGE_LOADING`)}
      </MText>
    </MFlexBlock>
  )
}

const useStyles = makeStyles(() => ({
  searchBox: {
    backgroundColor: '#ffffff',
    padding: '0.25rem 0.75rem 0.75rem 0.75rem',
    position: 'sticky',
    top: '69px',
    zIndex: 99,
  },
}))
