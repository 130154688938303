import { gql } from '@apollo/client'

export const REPORT_TRANSFER_CARRIERS_TO_POSITION = gql`
  mutation transferCarriersToPosition(
    $jobInventoryIdsAndQuantities: [JobInventoryIdAndQuantityInput!]!
    $destinationPositionId: ID!
    $resourceId: ID!
    $destinationPhase: String
    $workItemId: ID
  ) {
    transferCarriersToPositionGH(
      jobInventoryIdsAndQuantities: $jobInventoryIdsAndQuantities
      destinationPositionId: $destinationPositionId
      resourceId: $resourceId
      destinationPhase: $destinationPhase
      workItemId: $workItemId
    ) {
      problem
      succeeded
      failed
      destinationJidIds
      workItemId
      autoFinished
    }
  }
`
