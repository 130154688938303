import React from 'react'
import ReactDOM from 'react-dom/client'
import { App } from './App'
import './index.css'
import reportWebVitals from './reportWebVitals'
import { ApolloClient, ApolloProvider, createHttpLink, InMemoryCache } from '@apollo/client'
import { Settings } from './settings'
import { MpriseAuthProvider, MpriseIdentityServer } from '@mprise/react-auth'
import { MpriseTheme } from '@mprise/react-ui'
import { FlashAlerts } from './shared/flash-alerts'
import { MpriseMuiThemeProvider } from './material-ui-theme'
import { setContext } from '@apollo/client/link/context'
import { Splash } from './splash'

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)

const fetchTokenFromStorage = () => {
  const token = sessionStorage.getItem('oidc.user:' + Settings.login + ':' + Settings.clientId)

  if (token) {
    const tokenInfo = JSON.parse(token)
    return tokenInfo['access_token']
  } else {
    return ''
  }
}

const httpLink = createHttpLink({
  uri: Settings.gateway,
})

const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  const token = fetchTokenFromStorage()
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      authorization: `Bearer ${token}`,
    },
  }
})

const mergeFunctionWithPageAndTotal = (existing: any, incoming: any, { args }: any) => {
  const merged = existing ? existing.page.slice(0) : []
  for (let i = 0; i < incoming?.page?.length; ++i) {
    merged[(args?.offset ?? 0) + i] = incoming.page[i]
  }
  return { page: merged, total: incoming.total }
}

const apolloClient = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache({
    typePolicies: {
      Query: {
        fields: {
          searchWorkItems: {
            keyArgs: ['filter'],
            merge: mergeFunctionWithPageAndTotal,
          },
          jobs: {
            keyArgs: ['filter'],
            merge: mergeFunctionWithPageAndTotal,
          },
          lots: {
            keyArgs: ['filter'],
            merge: mergeFunctionWithPageAndTotal,
          },
          reasons: {
            keyArgs: ['filter'],
            merge: mergeFunctionWithPageAndTotal,
          },
          positions: {
            keyArgs: ['filter'],
            merge: mergeFunctionWithPageAndTotal,
          },
          positionCarriers: {
            keyArgs: ['filter'],
            merge: mergeFunctionWithPageAndTotal,
          },
        },
      },
    },
  }),
})

const provider = new MpriseIdentityServer(Settings.login, Settings.clientId)

root.render(
  <React.StrictMode>
    <ApolloProvider client={apolloClient}>
      <MpriseTheme />
      <MpriseMuiThemeProvider>
        <MpriseAuthProvider mpriseId={provider}>
          <FlashAlerts>
            <Splash>
              <App />
            </Splash>
          </FlashAlerts>
        </MpriseAuthProvider>
      </MpriseMuiThemeProvider>
    </ApolloProvider>
  </React.StrictMode>,
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
