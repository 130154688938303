/*Copied from react-ui to resolve multipe beeping on Barcode Scan */
import { MAudio } from '@mprise/react-ui'
import { useScannerReducer, useZxing } from '@mprise/react-ui/dist/internals'
import { useEffect, useLayoutEffect, useState } from 'react'

export type BarcodeCompletedHandler = (arg: { code: string }) => void

export const useScannerDialogHooks = (
  open: boolean,
  dialog: React.RefObject<HTMLDivElement>,
  autoComplete: boolean,
  onCompleted: BarcodeCompletedHandler,
) => {
  const [viewportElement, setViewportElement] = useState<HTMLVideoElement | null>(null)
  const [state, dispatch] = useScannerReducer()

  const [pressedNext, setPressedNext] = useState(0)
  const { device, loading, error, retry, nextDevice, canNextDevice } = useZxing({
    enable: open,
    viewportElement,
    onDetected({ code, format }) {
      dispatch({ type: `SCAN`, code, format, time: Date.now() })
    },
  })

  const handleNextDevice = () => {
    setPressedNext(x => x + 1)
    nextDevice()
  }

  useAnimationFrame(open, () => {
    // Display latest results, this updates confidence and timeout
    dispatch({ type: `TICK`, time: Date.now() })
  })

  useEffect(() => {
    // Clear scan history when opening or closing the dialog
    dispatch({ type: `CLEAR` })
  }, [open])

  useEffect(() => {
    // Make a noise when there are multiple results
    if (state.result.length > 1) {
      MAudio.information()
    }
  }, [state.result.length > 1])

  useLayoutEffect(() => {
    // Scroll to the bottom for small screens
    dialog.current?.scrollTo({
      top: dialog.current.scrollHeight,
      behavior: 'smooth',
    })
  }, [state.result.length])

  useEffect(() => {
    // Got a result with a high confidence
    if (open && autoComplete && state.completed) {
      onCompleted(state.completed)
    }
  }, [state.completed])

  return {
    setViewportElement,
    state,
    dispatch,
    setPressedNext,
    loading,
    error,
    retry,
    canNextDevice,
    device,
    pressedNext,
    handleNextDevice,
  }
}

const useAnimationFrame = (enable: boolean, callback: () => void) => {
  useEffect(() => {
    if (enable) {
      let n: any
      const queueNext = () => {
        n = requestAnimationFrame(() => {
          queueNext()
          callback()
        })
      }

      queueNext()
      return () => cancelAnimationFrame(n)
    }
  }, [enable])
}
