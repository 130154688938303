import { FieldTitle } from '../../components/FieldTitle'
import { useTranslation } from 'react-i18next'
import { suppressEnter } from '../util/util'
import { FieldInteger } from '../../mprise-light/FieldInteger'

export const FieldQuantity = ({ disabled, title, max }: { disabled?: boolean; title?: string; max?: number }) => {
  const { t } = useTranslation()

  return (
    <>
      <FieldTitle title={title ?? t(`QUANTITY`)} />
      <div className='gh-field-quantity'>
        <FieldInteger onKeyDown={suppressEnter} max={max} step={1} disabled={disabled} />
      </div>
    </>
  )
}
