import { Formik, withFormikCompareFix } from '@mprise/react-ui'
import { useTranslation } from 'react-i18next'
import { useHistory } from '../../shared/use-history'
import { JobForm } from './JobForm'
import { useLocalState } from '../../shared/local-state'
import { DialogHeaderBack } from '../../components/Header'
import { Helmet } from 'react-helmet'
import { Maybe } from '../../shared/enums'

export interface JobEntryForm {
  job: Maybe<{ id: string; name: string; code: Maybe<string> }>
}

export const JobRoute = () => {
  const h = useHistory()
  const { t } = useTranslation()

  const handleClose = () => {
    h.goBack()
  }

  const [initialValues] = useLocalState((): JobEntryForm => {
    return withFormikCompareFix({ job: { id: '', name: '', code: '' } })
  }, [])

  return (
    <>
      <Helmet title={t('JOB')} />

      <DialogHeaderBack title={t('JOB')} onClose={handleClose} />
      <Formik.Formik initialValues={initialValues} onSubmit={handleClose}>
        <JobForm />
      </Formik.Formik>
    </>
  )
}
