import { FieldDecimalQuantity } from './FieldDecimalQuantity'
import { FieldQuantity } from './FieldQuantity'

export const FieldSelectionQuantity = ({ unitOfMeasure, title }: { unitOfMeasure?: string; title?: string }) => {
  return (
    <>
      {unitOfMeasure === 'Containers' ? (
        <FieldDecimalQuantity disabled={false} title={title} />
      ) : (
        <FieldQuantity disabled={false} title={title} />
      )}
    </>
  )
}
