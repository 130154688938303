import { useEffect, useState } from 'react'
import { Formik, useMField } from '@mprise/react-ui'
import { useAppSettingsContext } from '../../context/AppSettingsContext'
import { defined, fail } from '../typescript'
import { useHistory } from '../use-history'
import { useLazyQuery } from '@apollo/client'
import { JOB_INVENTORY_BY_FILTER } from '../../gql/query/jobInventories/jobInventoryByFilter'
import { JobInventoryDetail, JobInventoryGroup } from '../interfaces'
import { JobMutationsEntryForm } from '../../routes/job-mutations/Home'
import { SelectGroupedJobInventoryDialog } from '../dialog/SelectGroupedJobInventoryDialog'

export type EntryFormType = JobMutationsEntryForm

export const FieldJobInventorySmart = ({ title }: { title: string }) => {
  const f = useMField()
  const fc = Formik.useFormikContext<EntryFormType>()
  const h = useHistory()
  const { company: currentCompany } = useAppSettingsContext()
  const companyId = currentCompany?.id ? +currentCompany.id : h.push('/')

  const jobId = fc.values.job?.id

  const hasSelectedJid = !!fc.values.selectedJobInventories.length

  const [open, setOpen] = useState(false)
  const handleClose = () => setOpen(false)

  const [getJobInventoryDetails, { data }] = useLazyQuery(JOB_INVENTORY_BY_FILTER)

  useEffect(() => {
    if (hasSelectedJid) {
      return
    }
    if (jobId) {
      if (!companyId) {
        fail('expects company id')
      }

      getJobInventoryDetails({
        variables: {
          filter: {
            companyId: companyId,
            jobId: +jobId,
            positionId: null,
            includeCarrierCodes: true,
          },
        },
        fetchPolicy: 'network-only',
      })
    }
  }, [jobId, hasSelectedJid])

  const jobInventoryDetails = data?.jobInventoryDetails?.filter(defined) ?? []

  const [groupedJids, setGroupedJids] = useState<Array<JobInventoryGroup | JobInventoryDetail>>([])
  const [useGrouping, setUseGrouping] = useState(true)

  useEffect(() => {
    if (data && !hasSelectedJid) {
      if (jobInventoryDetails.length === 0) {
        f.onChange?.([])
      } else if (jobInventoryDetails.length === 1) {
        f.onChange?.(jobInventoryDetails)
      } else {
        if (!useGrouping) {
          setGroupedJids(jobInventoryDetails)
          setOpen(true)
          return
        }
        const groupedJids: any[] = []
        const nonGroupedJids: JobInventoryDetail[] = []
        // Split inventories into those with and without sortingCode
        jobInventoryDetails.forEach((jid: JobInventoryDetail) => {
          if (jid.sortingCode) {
            // Group only the ones that have a sortingCode
            const key = `${jid.job.id}-${jid.position.id}-${jid.productionPhase.productionPhase}-${jid.pillar}-${jid.carrierCode}-${jid.unitOfMeasure}`
            const existingGroup = groupedJids.find(group => group.key === key)

            if (existingGroup) {
              existingGroup.multipleJids.push(jid)
              existingGroup.remainingQuantity += jid.remainingQuantity ?? 0
              existingGroup.sortingCode += `, ${jid.sortingCode}`
            } else {
              groupedJids.push({
                ...jid,
                id: `group-${groupedJids.length}`,
                key: key, // Key to group them by
                remainingQuantity: jid.remainingQuantity ?? 0,
                sortingCode: jid.sortingCode,
                multipleJids: [jid],
              })
            }
          } else {
            // Collect job inventories that don't have sortingCode
            nonGroupedJids.push(jid)
          }
        })
        // Set grouped inventories (with sorting) and non-grouped ones
        setGroupedJids([...groupedJids, ...nonGroupedJids])
        setOpen(true)
      }
    }
  }, [data, useGrouping])

  /** Prefill job and fromPosition when jobInventory is selected. */
  useEffect(() => {
    if (fc.values.selectedJobInventories.length) {
      prefillForm(fc, fc.values.selectedJobInventories)
    }
  }, [fc.values.selectedJobInventories])

  return (
    <>
      {data && jobInventoryDetails.length !== 1 && (
        <SelectGroupedJobInventoryDialog
          open={open}
          jobInventoryGroups={groupedJids}
          onClose={handleClose}
          onSave={(selectedJids: JobInventoryGroup | JobInventoryDetail) => {
            let finalSelectedJids: JobInventoryDetail[]
            if (Object.hasOwn(selectedJids, 'multipleJids')) {
              //@ts-ignore
              finalSelectedJids = selectedJids.multipleJids
            } else {
              finalSelectedJids = [selectedJids]
            }
            f.onChange?.(finalSelectedJids)
            setOpen(false)
          }}
          title={title}
          useGrouping={useGrouping}
          setUseGrouping={setUseGrouping}
        />
      )}
    </>
  )
}

function prefillForm(fc: Formik.FormikContextType<EntryFormType>, jids: JobInventoryDetail[]) {
  const jid = fc.values.selectedJobInventories[0]
  if (jid?.job && jid.position) {
    fc.setValues(current => ({
      ...current,
      job: {
        id: jid.job.id!,
        name: jid.job.name!,
        code: jid.job.code!,
      },
      fromPosition: {
        id: jid.position.id!,
        name: jid.position.name!,
        code: jid.position.code!,
      },
      quantity: jid.remainingQuantity,
      originalQuantity: jids.reduce((a, b) => a + b.remainingQuantity, 0),
    }))
  }
}
