import React, { useState } from 'react'
import { LocalDate } from '@js-joda/core'
import { Locale as JsJodaLocale } from '@js-joda/locale_en-us'
import { Breakpoint } from '@mui/material'
import { DatePicker, DatePickerProps, LocalizationProvider } from '@mui/x-date-pickers'
import { parseDate, useMField } from '@mprise/react-ui'
import { DateContainer } from './DateContainer'
import { MuiAdapterJoda } from '@mprise/react-ui/dist/utils/MuiAdapterJoda'

export const FieldDate = ({
  dialogHeader,
  minWidth = 'sm',
  ...props
}: Partial<DatePickerProps<LocalDate>> & { dialogHeader?: React.ReactNode; minWidth?: number | Breakpoint }) => {
  const f = useMField()

  const [open, setOpen] = useState(false)
  const handleOpen = () => {
    setOpen(true)
  }
  const handleClose = (date?: LocalDate | null) => {
    f.onChange?.(date)
    setOpen(false)
  }

  const date = f.value instanceof LocalDate ? f.value : parseDate(f.value)

  return (
    <LocalizationProvider
      //@ts-ignore
      dateAdapter={MuiAdapterJoda}
      adapterLocale={JsJodaLocale.US}
      localeText={{
        fieldMonthPlaceholder: () => '',
        fieldDayPlaceholder: () => '',
      }}
    >
      <DatePicker
        value={date ?? null}
        onChange={e => f.onChange?.(e)}
        format='MMMM dd'
        {...props}
        slotProps={{
          textField: {
            fullWidth: true,
            variant: 'standard',
            onFocus: e => e.currentTarget.blur(),
            onClick: handleOpen,
            InputProps: {
              endAdornment: null,
            },
          },
        }}
        open={false}
        onOpen={handleOpen}
      />
      <DateContainer
        open={open}
        title={dialogHeader}
        value={date ?? undefined}
        onClose={handleClose}
        minWidth={minWidth}
      />
    </LocalizationProvider>
  )
}
