import { MColor, MFlexBlock, MText } from '@mprise/react-ui'
import { useTranslation } from 'react-i18next'
import { MDialog, MDialogContentText } from '../../mprise-light/Dialog'
import { MultiSelectItemClickHandler, MultiSelectJobInventoryCard } from './MultiSelectJobInventoryCard'
import IconWarning from '@mui/icons-material/Warning'
import { JobInventoryDetail } from '../interfaces'

export const MultiSelectJobInventoryDialog = ({
  open,
  jobInventoryDetails,
  selectedJids,
  task,
  onClose,
  onSave,
  onChange,
}: {
  open: boolean
  jobInventoryDetails: JobInventoryDetail[]
  selectedJids: Array<JobInventoryDetail>
  task: any
  onClose: () => void
  onSave: (values: JobInventoryDetail[]) => void
  onChange: (value: Array<JobInventoryDetail>) => void
}) => {
  const { t } = useTranslation()

  const selected = [selectedJids ?? []].flat().map(x => x.id)

  const handleToggle: MultiSelectItemClickHandler = i => {
    if (selectedJids.map(x => x.id).includes(i.id)) {
      onChange(selectedJids.filter(x => x.id !== i.id))
    } else {
      onChange([...selectedJids, i])
    }
  }

  const handleSubmit: React.FormEventHandler = async e => {
    e.stopPropagation()
    e.preventDefault()
    onSave(selectedJids)
    onClose()
  }

  const hasSelectableJids = jobInventoryDetails.some(jid => jid.job.item?.id === task?.workItem.item?.id)
  const sortedJids = jobInventoryDetails.sort(a => (a.job.item?.id === task?.workItem.item?.id ? -1 : 1))

  return (
    <>
      {hasSelectableJids ? (
        <MDialog open={open} title={t('ASSIGN_JOB_INVENTORY_DETAIL')} onSubmit={handleSubmit} onClose={onClose}>
          <MDialogContentText>
            <div>
              <div style={{ display: 'flex', gap: '1rem', justifyContent: 'center' }}>
                <IconWarning style={{ color: MColor.medium }} />
                <MText block textVariant='content'>
                  {t('MULTIPLE_JOB_INVENTORY')}
                </MText>
              </div>
            </div>
          </MDialogContentText>
          <MFlexBlock vertical style={{ overflow: `auto`, position: 'relative' }} padding={2}>
            {sortedJids.length > 0
              ? sortedJids.map(x => (
                  <MultiSelectJobInventoryCard
                    key={x.id}
                    selected={selected.includes(x.id)}
                    disabled={x.job.item?.id !== task?.workItem.item?.id}
                    jobInventoryDetail={x}
                    onClick={handleToggle}
                  />
                ))
              : null}
          </MFlexBlock>
        </MDialog>
      ) : (
        <MDialog open={true} title={t(`INCORRECT_ITEM`)} onSubmit={onClose} onClose={onClose}>
          <MDialogContentText>
            <div>
              <div style={{ display: 'flex', gap: '1rem', justifyContent: 'center' }}>
                <IconWarning style={{ color: MColor.medium }} />
                <MText block textVariant='content'>
                  {t('INCORRECT_ITEM')}
                </MText>
              </div>
            </div>
          </MDialogContentText>
          <MFlexBlock vertical style={{ overflow: `auto`, position: 'relative' }} padding={2}>
            {jobInventoryDetails.length > 0
              ? jobInventoryDetails.map(x => (
                  <MultiSelectJobInventoryCard
                    key={x.id}
                    selected={false}
                    disabled={true}
                    jobInventoryDetail={x}
                    onClick={() => {}}
                  />
                ))
              : null}
          </MFlexBlock>
        </MDialog>
      )}
    </>
  )
}
