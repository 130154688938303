import { Formik, MAudio, MFieldConnector, MFlexBlock, MFlexItem, MText, withFormikCompareFix } from '@mprise/react-ui'
import { useTranslation } from 'react-i18next'
import { useEffect, useState } from 'react'
import { TransferCarrierCodesEntryForm } from './Home'
import { IconButton } from '@mui/material'
import { DeleteScannedJobInventoryDialog } from '../../../shared/dialog/DeleteScannedJobInventoryDialog'
import { FlashAlerts } from '../../../shared/flash-alerts'
import { useLocalState } from '../../../shared/local-state'
import { DialogFormik } from '../../../mprise-light/DialogFormik'
import { Section } from '../../../components/Section'
import { MaterialIcon } from '../../../components/Icon'
import { formatUnitOfMeasure } from '../../../shared/formats'
import { ScannedJobInventory } from '../../../shared/interfaces'
import { i18n } from '../../../i18n/instance'
import { FieldSelectionQuantity } from '../../../shared/form/SelectionFieldQuantity'

export interface UpdateCarrierCodeForm {
  quantity: number
}

export const UpdateJobInventoryDialog = ({
  entry,
  open,
  handleClose,
}: {
  entry: ScannedJobInventory
  open: boolean
  handleClose: () => void
}) => {
  const { t } = useTranslation()

  const fc = Formik.useFormikContext<TransferCarrierCodesEntryForm>()
  const alerts = FlashAlerts.useAlert()

  const [showDeleteDialog, setShowDeleteDialog] = useState<boolean>(false)

  const handleDelete = () => setShowDeleteDialog(true)
  const handleStopDelete = () => {
    setShowDeleteDialog(false)
    handleClose()
  }
  const [initialValues] = useLocalState<UpdateCarrierCodeForm>(
    withFormikCompareFix({
      quantity: entry.quantity ?? 0,
    }),
    [],
  )

  const changeEntryInForm = async (input: ScannedJobInventory) => {
    const recordToUpdate = fc.values.scannedJobInventories?.find(i => i.jidId === input.jidId)
    if (!fc.values.scannedJobInventories || !recordToUpdate) {
      return
    }
    const updatedScannedJobInventories = [
      ...fc.values.scannedJobInventories.filter(i => i.jidId !== input.jidId),
      { ...input, timestamp: Date.now() },
    ]
    MAudio.scanSuccess()
    alerts.push(i18n.t('Quantity updated'), 'success')

    fc.setValues({ ...fc.values, scannedJobInventories: updatedScannedJobInventories })
  }

  return (
    <>
      <Formik.Formik
        initialValues={initialValues}
        onSubmit={async form => {
          const userInput = {
            ...entry,
            quantity: form.quantity,
          }
          changeEntryInForm(userInput)
          handleClose()
        }}
      >
        <DialogFormik minWidth='xs' title={t('EDIT')} onClose={handleClose} open={open}>
          <Section>
            <UpdateScannedQuantityForm entry={entry} />
            <MFlexBlock vertical>
              <MFlexItem style={{ padding: '0.5rem', alignItems: 'flex-end' }}>
                <IconButton onClick={handleDelete}>
                  <MaterialIcon value='delete' style={{ fontSize: `2rem` }} />
                </IconButton>
              </MFlexItem>
            </MFlexBlock>
          </Section>
        </DialogFormik>
      </Formik.Formik>
      <DeleteScannedJobInventoryDialog entry={entry} open={showDeleteDialog} handleClose={handleStopDelete} />
    </>
  )
}

const UpdateScannedQuantityForm = ({ entry }: { entry: ScannedJobInventory }) => {
  const fc = Formik.useFormikContext<UpdateCarrierCodeForm>()

  useEffect(() => {
    const quantity = fc.values.quantity
    if (quantity < 0) {
      fc.setValues({ quantity: 0 })
    }
  }, [fc.values.quantity])

  return (
    <>
      <EntryInfo entry={entry} />
      <MFlexItem style={{ padding: '0.5rem' }}>
        <Formik.Field component={MFieldConnector} name='quantity'>
          <FieldSelectionQuantity unitOfMeasure={entry.unitOfMeasure} />
        </Formik.Field>
      </MFlexItem>
    </>
  )
}

export const EntryInfo = ({ entry }: { entry: ScannedJobInventory }) => {
  const { t } = useTranslation()

  const unitOfMeasure = formatUnitOfMeasure(entry?.unitOfMeasure)

  return (
    <MFlexItem style={{ padding: '0.5rem', borderBottom: '1px #ccd1d4 solid' }}>
      <MFlexItem style={{ margin: '0.5rem', display: 'grid' }}>
        <MText inline textVariant='inherit'>
          {entry.carrierCode}
        </MText>
      </MFlexItem>
      <MFlexItem style={{ margin: '0.5rem', display: 'grid' }}>
        <MText inline textVariant='inherit'>
          {entry.jobName}
        </MText>
      </MFlexItem>
      <MFlexItem style={{ margin: '0.5rem', display: 'grid' }}>
        <MText inline textVariant='inherit'>
          {entry.itemName}
        </MText>
      </MFlexItem>
      <MFlexItem style={{ margin: '0.5rem' }}>
        <MText inline textVariant='inherit'>
          {t('QUANTITY')}:
        </MText>
        <MText inline> {entry.quantity}</MText>
        <MText inline> {unitOfMeasure}</MText>
      </MFlexItem>
    </MFlexItem>
  )
}
