import { gql } from '@apollo/client'
import { FragmentJobInventoryPutAway } from '../../fragment/jobInventoryPutAway'

export const WORKITEM_DETAILS_WITH_TASKS = gql`
  query workItemDetailsWithTasks($where: [OptionsWhere]) {
    nworkItem(where: $where) {
      id
      number
      name
      type
      description
      itemDescription
      plannedQuantity
      plannedQuantityBase
      unitOfMeasure
      createdAt
      createdBy {
        id
        name
        type
        code
      }
      activity {
        id
        code
        name
      }
      owner {
        id
        name
        type
        code
      }
      scheduledDate
      jobs {
        id
        code
        name
      }
      plannedPositions {
        id
        code
        name
      }
      tasks {
        id
        name
        order
        status
        statusOrder
        types
        taskOptions
        timeReg {
          summary {
            startTime
            totalMinutes
            totalTimeResources {
              namedResourcesTime {
                resourceId
                resourceName
                totalMinutes
              }
            }
          }
        }
      }
      jobInventoryPutAway(frontend: true) {
        ...FragmentJobInventoryPutAway
      }
    }
  }
  ${FragmentJobInventoryPutAway}
`
