import { gql } from '@apollo/client'

export const REPORT_TRANSITION_TO_PHASE = gql
`mutation reportTransitionToPhase(
    $originJobInventoryDetailId: ID!
    $destinationPhase: String!
    $destinationSortingCode: String
    $quantity: Float!
    $resourceId: ID!
  ) {
    transitionPhaseJobInventoryDetailGH(
      originJobInventoryDetailId: $originJobInventoryDetailId
      destinationProductionPhase: $destinationPhase
      destinationSortingCode: $destinationSortingCode
      quantity: $quantity
      resourceId: $resourceId
    ) {
      updatedOrigin {
        id
        remainingQuantity
      }
      updatedDestination {
        id
        remainingQuantity
      }
    }
  }
  `