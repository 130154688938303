import { FormattedDate, MFlexBlock, MTextColor } from '@mprise/react-ui'
import { TagStatus } from '../../components/TagStatus'

export const WorkItemCard = ({ workItem, onClick }: { workItem: any; onClick: (task: any) => void }) => {
  const { name, number, itemDescription, scheduledDate, jobs } = workItem

  return (
    <div key={workItem?.id} onClick={() => onClick(workItem)} className='gh-workItem-card'>
      <div className='gh-workItem-card-header'>
        <div>{name}</div>
      </div>
      <div className='gh-workItem-card-content'>
        <MFlexBlock justifyContent='space-between' textColor={MTextColor.dark}>
          <div>
            <FormattedDate date={scheduledDate?.toString()} />
          </div>
          <div style={{ minWidth: '100px', color: 'unset' }}>
            <TagStatus status={workItem.status} size='medium' />
          </div>
        </MFlexBlock>
        <MFlexBlock textColor={MTextColor.dark}>
          <div>{itemDescription}</div>
        </MFlexBlock>
        <MFlexBlock justifyContent='space-between' textColor={MTextColor.dark}>
          {jobs?.length > 0 && (
            <div title={jobs.map((j: { code: any }) => j?.code).join(', ')}>
              {jobs[0]?.code + (jobs.length > 1 ? ', ...' : '')}
            </div>
          )}
          <div>{number ?? ''}</div>
        </MFlexBlock>
      </div>
    </div>
  )
}
