import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Divider } from '@mui/material'
import { Formik, MField, MFieldConnector, Yup } from '@mprise/react-ui'
import { Section } from '../../../components/Section'
import { nameof } from '../../../shared/typescript'
import { FieldJobInventoryDetail } from '../../../shared/form/FieldJobInventoryDetail'
import { FieldPosition } from '../../../shared/form/FieldPosition'
import { SelectedJobInventoryDetails } from '../../../shared/form/selectionDetails'
import { TaskDetailsSection } from '../../../shared/form/TaskDetailsSection'
import { SortAndTransferEntryForm } from './Home'
import { FieldDecimalQuantity } from '../../../shared/form/FieldDecimalQuantity'
import { FieldQuantity } from '../../../shared/form/FieldQuantity'
import { ValidationFriendlyWarning } from '../../../shared/form/ValidationFriendlyWarning'
import { parseFloatQuantity } from '../../../shared/formats'
import { useAppSettingsContext } from '../../../context/AppSettingsContext'

const emptyValue = {
  quantity: 0,
  toPosition: null,
  quantityUnit: null,
  sorting: null,
  taskResultId: '',
  plannedQuantity: 0,
  reportedQuantity: 0,
  quantityPerUnitOfMeasure: 0,
}

export const SortAndTransferForm = () => {
  const { t } = useTranslation()
  const fc = Formik.useFormikContext<SortAndTransferEntryForm>()
  const { numberFormat } = useAppSettingsContext()
  const [exceedsTotalQuantity, setExceedsTotalQuantity] = useState(false)

  const task = fc.values.task
  const taskUnitOfMeasure = fc.values.jobInventoryDetail?.unitOfMeasure
  const totalPlannedQuantity = task?.workItem?.plannedQuantity ?? 0

  function handleChangeQuantity(newValue: number, index: number) {
    const value = fc.values.jobInventoryPutAways[index] ?? emptyValue

    value.quantity = parseFloatQuantity(newValue, numberFormat)
    const newValues = [...fc.values.jobInventoryPutAways]
    newValues[index] = value

    const newTotal = newValues.reduce((a, b) => a + ((b.quantity ?? 0) + (b.reportedQuantity ?? 0)), 0) ?? 0

    if (totalPlannedQuantity && newTotal > totalPlannedQuantity) {
      setExceedsTotalQuantity(true)
    } else if (exceedsTotalQuantity && newTotal < totalPlannedQuantity) {
      setExceedsTotalQuantity(false)
    }

    fc.setValues(current => ({
      ...current,
      jobInventoryPutAways: newValues,
      dirty: true,
    }))
  }

  function handleChangePosition(newValue: any, index: number) {
    const value = fc.values.jobInventoryPutAways[index] ?? emptyValue

    if (newValue) {
      value.toPosition = { id: newValue.id, name: newValue.name, code: newValue.code }
    } else {
      value.toPosition = { id: '', name: '', code: '' }
    }
    const newValues = [...fc.values.jobInventoryPutAways]
    newValues[index] = value

    fc.setValues(current => ({
      ...current,
      reportedItemConsumptions: newValues,
      dirty: true,
    }))
  }

  /** Set focus to the field when the form opens */
  const jobFieldRef = useRef<HTMLInputElement>(null)
  useEffect(() => {
    jobFieldRef?.current?.focus()
  }, [jobFieldRef])

  return (
    <>
      {task && <TaskDetailsSection task={task} />}
      <Section>
        {fc.values.jobInventoryPutAways?.map((value: any, i: number) => {
          const maxQuantity = value.plannedQuantity ?? 0
          const reportedQuantity = value.reportedQuantity ?? 0
          const quantityPerUnitofMeasure = value.quantityPerUnitOfMeasure ?? 1
          const exceedsQuantity =
            maxQuantity > 0 && value.quantity
              ? parseFloatQuantity(value.quantity, numberFormat) + reportedQuantity / (quantityPerUnitofMeasure || 1) >
                maxQuantity
              : false

          return (
            <React.Fragment key={i}>
              <MField
                vertical
                name=''
                label=''
                value={value.quantity}
                onChange={newValue => handleChangeQuantity(newValue, i)}
              >
                {taskUnitOfMeasure === 'Containers' ? (
                  <FieldDecimalQuantity title={`${t('SORTING')} ${value.sorting}`} />
                ) : (
                  <FieldQuantity title={`${t('SORTING')} ${value.sorting}`} />
                )}
                <ValidationFriendlyWarning
                  open={exceedsTotalQuantity || exceedsQuantity}
                  text={t(`WARNING_EXCEEDS_PLANNED_QUANTITY`)}
                />
              </MField>
              <MField
                vertical
                name=''
                label=''
                value={value.toPosition}
                onChange={newValue => handleChangePosition(newValue, i)}
              >
                <FieldPosition title={t(`TO_POSITION`)} label='TO_POSITION' />
              </MField>
              <Divider style={{ marginTop: '25px', marginBottom: '10px' }} />
            </React.Fragment>
          )
        })}

        <div style={{ display: 'none' }}>
          <Formik.Field component={MFieldConnector} name={nameof<SortAndTransferEntryForm>('jobInventoryDetail')}>
            <FieldJobInventoryDetail title={t('ASSIGN_JOB_INVENTORY_DETAIL')} />
          </Formik.Field>
        </div>
      </Section>
      <div style={{ marginTop: '1rem' }}>
        <Section>
          <SelectedJobInventoryDetails jobInventoryDetail={fc.values.jobInventoryDetail} showPosition={false} />
        </Section>
      </div>
    </>
  )
}

SortAndTransferForm.useSchema = () => {
  const { t } = useTranslation()

  const [schema] = useState(
    (): Yup.SchemaOf<SortAndTransferEntryForm> =>
      Yup.object().shape({
        jobInventoryPutAways: Yup.array()
          .of(
            Yup.object()
              .shape({
                toPosition: Yup.object()
                  .shape({
                    id: Yup.string().nullable(),
                    name: Yup.string().nullable(),
                    code: Yup.string().nullable(),
                  })
                  .nullable(),
                quantity: Yup.number().optional().nullable(),
                quantityUnit: Yup.string().optional(),
                sorting: Yup.string().required('Sorting is required'),
                plannedQuantity: Yup.number().optional().nullable(),
                reportedQuantity: Yup.number().optional().nullable(),
              })
              .test({
                name: 'position',
                exclusive: false,
                message: input => {
                  return `${t('SELECT_POSITION_FOR_SORTING')} ${input.value.sorting}`
                },
                test: input => {
                  if (input.quantity && input.quantity > 0 && !input.toPosition.id) {
                    return false
                  } else return true
                },
              })
              .optional(),
          )
          .test('quantity', t('ADD_QUANTITY_FOR_A_FIELD'), input => {
            const quantity = (input ?? []).reduce((a: any, b: any) => a + b.quantity, 0)
            return quantity > 0
          }),
        job: Yup.object()
          .shape({
            id: Yup.string().required('Job is a required field'),
            name: Yup.string().nullable(),
            code: Yup.string().nullable(),
          })
          .required('Job is a required field')
          .nullable()
          .label(t(`JOB`)),
        jobInventoryDetail: Yup.mixed().required().label(t(`JOB_INVENTORY_DETAIL`)),
        workItemId: Yup.string().required(),
        workItemCosmosKey: Yup.mixed().optional().nullable(),
        taskCosmosKey: Yup.mixed().optional().nullable(),
        task: Yup.mixed().optional().nullable(),
      }),
  )

  return schema
}
