import { useState } from 'react'
import { Formik, MFieldConnector, Yup } from '@mprise/react-ui'
import { useTranslation } from 'react-i18next'
import { CropMaintenanceEntryForm } from './Home'
import { Section } from '../../../components/Section'
import { nameof } from '../../../shared/typescript'
import { FieldSelectionQuantity } from '../../../shared/form/SelectionFieldQuantity'
import { TaskDetailsSection } from '../../../shared/form/TaskDetailsSection'

export const CropMaintenanceForm = ({ task }: { task: any }) => {
  const fc = Formik.useFormikContext<CropMaintenanceEntryForm>()

  const taskUnitOfMeasure = task.workItem?.unitOfMeasure ?? ''

  return (
    <>
      {task && <TaskDetailsSection task={task} reportedQuantityOverride={fc.values.reportedQuantity} />}
      <Section>
        <div style={{ padding: '0.5rem' }}>
          <Formik.Field component={MFieldConnector} name={nameof<CropMaintenanceEntryForm>('quantity')}>
            <FieldSelectionQuantity unitOfMeasure={taskUnitOfMeasure} />
          </Formik.Field>
        </div>
      </Section>
    </>
  )
}

CropMaintenanceForm.useSchema = () => {
  const { t } = useTranslation()

  const [schema] = useState(
    (): Yup.SchemaOf<CropMaintenanceEntryForm> =>
      Yup.object().shape({
        quantity: Yup.number()
          .transform((value, originalValue) => {
            if (originalValue && typeof originalValue === 'string') {
              const processedValue = originalValue.replace(',', '.')
              return parseFloat(processedValue)
            }
            return value
          })
          .min(0.5)
          .required()
          .label(t(`QUANTITY`))
          .typeError('Quantity is a required field'),
        reportedQuantity: Yup.number().required(),
      }),
  )

  return schema
}
