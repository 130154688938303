import { MFlexBlock, MFlexItem, MText } from '@mprise/react-ui'
import { useState } from 'react'
import { IconButton } from '@mui/material'
import { MaterialIcon } from '../../../components/Icon'
import { ScannedUploadCarrier } from '../../../shared/interfaces'
import { UpdateUploadCarrierDialog } from './UpdateUploadCarrierDialog'
import { Section } from '../../../components/Section'
import { useTranslation } from 'react-i18next'
import { Maybe } from '../../../shared/enums'
import { formatQuantity } from '../../../shared/formats'
import { useAppSettingsContext } from '../../../context/AppSettingsContext'

export const ScannedUploadCarriersSection = ({
  scanned,
  totalQuantity,
}: {
  scanned: ScannedUploadCarrier[]
  totalQuantity: Maybe<number>
}) => {
  return (
    <Section>
      <MFlexBlock vertical>
        <ScannedJobInventoryHeader totalQuantity={totalQuantity} carrierNumbers={scanned.length} />
        {scanned.length > 0 && scanned.map(entry => <ScannedUploadCarrierRow key={entry.carrierCode} entry={entry} />)}
      </MFlexBlock>
    </Section>
  )
}

const ScannedUploadCarrierRow = ({ entry }: { entry: ScannedUploadCarrier }) => {
  const [showEditDialog, setShowUpdateDialog] = useState<boolean>(false)

  const handleEdit = () => setShowUpdateDialog(true)
  const handleStopEdit = () => {
    setShowUpdateDialog(false)
  }

  return (
    <MFlexBlock
      key={entry.carrierCode}
      alignItems='center'
      justifyContent='space-between'
      padding={[0, 4]}
      style={{ borderBottom: '1px #ccd1d4 solid' }}
    >
      <RowTextItem text={entry.carrierCode} />
      <RowTextItem text={entry.quantity?.toLocaleString('nl-NL')} />
      <IconButton onClick={handleEdit}>
        <MaterialIcon value='edit' style={{ fontSize: `1.5rem` }} />
      </IconButton>
      <UpdateUploadCarrierDialog entry={entry} open={showEditDialog} handleClose={handleStopEdit} />
    </MFlexBlock>
  )
}

const RowTextItem = ({ text, width }: { text: string | number; width?: number }) => {
  return (
    <MFlexItem style={{ width: `${width ?? 40}%` }}>
      <MText inline textColor={'#000'} style={{ fontSize: '16px' }}>
        {text}
      </MText>
    </MFlexItem>
  )
}

const ScannedJobInventoryHeader = ({
  totalQuantity,
  carrierNumbers,
}: {
  totalQuantity: Maybe<number>
  carrierNumbers: Maybe<number>
}) => {
  const { t } = useTranslation()
  const { numberFormat } = useAppSettingsContext()

  return (
    <MFlexBlock alignItems='end' padding={[2, 2, 2, 4]} style={{ backgroundColor: '#e0e0e0' }}>
      <RowTextItem text={`${t(`CARRIER_CODE`)} (${carrierNumbers})`} width={35} />
      <RowTextItem
        text={`${t(`QUANTITY`)} (${formatQuantity(totalQuantity ? totalQuantity : 0, numberFormat)})`}
        width={65}
      />
    </MFlexBlock>
  )
}
