import React, { ForwardedRef, useState } from 'react'
import { useApolloClient } from '@apollo/client'
import { MAudio, useMField } from '@mprise/react-ui'
import { useTranslation } from 'react-i18next'
import { makeQueryCallback } from '../apollo'
import { SelectLotNumberDialog } from '../dialog/SelectLotNumberDialog'
import { useAppSettingsContext } from '../../context/AppSettingsContext'
import { FieldBarcode } from '../../mprise-light/FieldBarcode'
import { LOT_BY_FILTER } from '../../gql/query/lot/lotByFilter'
import { Lot } from '../interfaces'
import { FlashAlerts } from '../flash-alerts'

export const FieldLotNumber = React.forwardRef(
  (
    {
      title,
      label,
      plannedLotNumbers = [],
      itemId,
      plannedVariantCode,
      allowOverruleLots,
    }: {
      title: string
      label: string
      plannedLotNumbers?: string[]
      itemId: string
      plannedVariantCode: string
      allowOverruleLots: boolean
    },
    ref: ForwardedRef<HTMLInputElement>,
  ) => {
    const { t } = useTranslation()

    const { company, scanSetting } = useAppSettingsContext()
    const companyId = company?.id ?? ``
    const f = useMField()

    const [open, setOpen] = useState(false)

    const handleOpen = () => setOpen(true)
    const handleClose = () => setOpen(false)

    const alerts = FlashAlerts.useAlert()
    const apollo = useApolloClient()

    const validateLot = (lotNumber?: any | Lot | null) => {
      if (!lotNumber) {
        MAudio.scanError()
        alerts.push(t(`WRONG_LOT_OR_ITEM`), `error`)
        return false
      }

      if (plannedLotNumbers.length) {
        if (!allowOverruleLots && !plannedLotNumbers.includes(lotNumber.lotNumber ?? '')) {
          MAudio.scanError()
          alerts.push(t(`LOT_NOT_ASSIGNED`), `error`)
          return false
        }
      }

      MAudio.scanSuccess()
      return true
    }

    const handleSelected = async (lot: any | null) => {
      setOpen(false)

      if (validateLot(lot)) {
        f.onChange?.(lot)
      } else {
        return null
      }
    }

    const handleLookup = async (lotNumber: string) => {
      const lotQuery = await fetchLotByLotNumber(
        {
          filter: {
            companyId: +companyId,
            open: true,
            lotNumber: lotNumber,
            itemId: +itemId,
            variantCode: plannedVariantCode,
          },
        },
        apollo,
      )
      if (validateLot(lotQuery)) {
        return lotQuery
      } else {
        return null
      }
    }

    return (
      <>
        <SelectLotNumberDialog
          open={open}
          itemId={itemId}
          plannedVariantCode={plannedVariantCode}
          companyId={companyId}
          onClose={handleClose}
          onSave={handleSelected}
          title={title}
          plannedLotNumbers={plannedLotNumbers}
          allowOverruleLots={allowOverruleLots}
        />
        <FieldBarcode<Lot | any>
          title={label}
          scanningSetting={scanSetting}
          valueAsText={x => x.lotNumber ?? t(`PLACEHOLDER_NOT_SET`)}
          onLookup={handleLookup}
          onError={() => {
            MAudio.scanError()
          }}
          onSuccess={(text, item) => {
            if (text && item) {
              MAudio.scanSuccess()
            }
            if (text && !item) {
              MAudio.scanError()
            }
          }}
          onExplore={handleOpen}
          ref={ref}
        />
      </>
    )
  },
)

const fetchLotByLotNumber = makeQueryCallback<any, any>(LOT_BY_FILTER)(x => {
  return x.lot ?? null
})
