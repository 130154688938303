import { MouseEventHandler, useEffect, useRef, useState } from 'react'
import styled from '@emotion/styled'
import { ButtonBase, Input, InputProps } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { mdiMinus, mdiPlus } from '@mdi/js'
import Icon from '@mdi/react'
import { spacing } from '@mprise/react-ui/dist/controls/elements/shape/theme/size'
import { useMField } from '@mprise/react-ui/dist/controls/mfield/context'
import { MColor } from '@mprise/react-ui/dist/controls/elements/shape/theme/color'

// Copied and adjusted from react-ui MFieldInteger.
export const FieldInteger = (props: InputProps & { step?: number; max?: number }) => {
  const f = useMField()

  const max = props.max
  const step = props.step ?? 1

  const [focused, setFocused] = useState(false)
  const [plusDisabled, setPlusDisabled] = useState(max === 0)

  const inputRef = useRef<HTMLInputElement>(null)

  const handleMinus = (e: any) => {
    const currentValue = Number(f.value ?? 0)
    const newValue = currentValue - step

    if (currentValue > 0) {
      f.onChange?.(newValue, e)
    }

    if (max && newValue < max && plusDisabled) {
      setPlusDisabled(false)
    }
  }

  const handlePlus = (e: any) => {
    const currentValue = Number(f.value ?? 0)
    const newValue = currentValue + step

    if (max == null || newValue <= max) {
      f.onChange?.(newValue, e)
    } else if (!plusDisabled) {
      setPlusDisabled(true)
    }
  }

  const handleChange = (e: any) => {
    if (/^[0-9]*$/.test(e.target.value)) {
      f.onChange?.(e.target.value ? parseInt(e.target.value) : '', e)
    }
  }

  const handleFocus = () => {
    setTimeout(() => {
      inputRef.current?.setSelectionRange(-1, -1)
    })
    setFocused(true)
  }
  const handleBlur = () => setFocused(false)

  return (
    <FieldInteger.Container focused={focused} style={{ width: '161px' }}>
      <MinusButton handleMinus={handleMinus} />
      <Input
        name={f.name ?? undefined}
        id={f.id ?? undefined}
        value={f.value === 0 || f.value === '0' ? '' : f.value}
        type='text' // Use "text" instead of "number" to prevent browsers from allowing decimal input.
        inputMode='numeric'
        onChange={handleChange}
        disableUnderline
        onFocus={handleFocus}
        onBlur={handleBlur}
        inputProps={{
          pattern: '^(?:\\d{1,3}(?:,\\d{3})*|\\d+)(?:\\.\\d+)?$',
          style: { textAlign: 'center' },
          inputMode: 'numeric',
        }}
        inputRef={inputRef}
        {...props}
      />
      <PlusButton handlePlus={handlePlus} disabled={plusDisabled} />
    </FieldInteger.Container>
  )
}

export const MinusButton = ({ handleMinus }: { handleMinus: MouseEventHandler<HTMLButtonElement> }) => {
  const holdMinus = FieldInteger.useRepeater(handleMinus, 600, 25)

  const handleMinusPointerDown = (e: any) => {
    e.preventDefault()
    holdMinus.start()
  }
  const handleMinusPointerUp = (e: any) => {
    e.preventDefault()
    holdMinus.stop()
  }
  const handleMinusTouchEnd = (e: any) => {
    e.preventDefault()
    handleMinus(e)
  }

  const classes = FieldInteger.useClasses()

  return (
    <ButtonBase
      className={classes.minus}
      onClick={handleMinus}
      onPointerDown={handleMinusPointerDown}
      onPointerUp={handleMinusPointerUp}
      onPointerOut={handleMinusPointerUp}
      onTouchEnd={handleMinusTouchEnd}
    >
      <Icon size={spacing(8)} path={mdiMinus} />
    </ButtonBase>
  )
}

export const PlusButton = ({
  handlePlus,
  disabled,
}: {
  handlePlus: MouseEventHandler<HTMLButtonElement>
  disabled: boolean
}) => {
  const holdPlus = FieldInteger.useRepeater(handlePlus, 600, 25)

  const handlePlusPointerDown = (e: any) => {
    e.preventDefault()
    holdPlus.start()
  }
  const handlePlusPointerUp = (e: any) => {
    e.preventDefault()
    holdPlus.stop()
  }
  const handlePlusTouchEnd = (e: any) => {
    e.preventDefault()
    handlePlus(e)
  }

  const classes = FieldInteger.useClasses()

  return (
    <ButtonBase
      className={disabled ? classes.plusDisabled : classes.plus}
      onClick={handlePlus}
      onPointerDown={handlePlusPointerDown}
      onPointerUp={handlePlusPointerUp}
      onPointerOut={handlePlusPointerUp}
      onTouchEnd={handlePlusTouchEnd}
    >
      <Icon size={spacing(8)} path={mdiPlus} />
    </ButtonBase>
  )
}

FieldInteger.useRepeater = (fn: any, delay: number | undefined, rate: number) => {
  const state = useRef<{ timer: ReturnType<typeof setTimeout> | null; fn: any }>({ timer: null, fn })
  state.current.fn = fn

  const api = {
    start() {
      if (!state.current.timer) {
        state.current.timer = setTimeout(() => {
          state.current.fn()
          state.current.timer = setInterval(() => {
            state.current.fn()
          }, 1000 / rate)
        }, delay)
      }
    },
    stop() {
      if (state.current.timer) {
        clearTimeout(state.current.timer)
        clearInterval(state.current.timer)
        state.current.timer = null
      }
    },
  }
  useEffect(() => {
    return () => {
      api.stop()
    }
  }, [])

  return useState(api)[0]
}

FieldInteger.Container = styled.div<{ focused: boolean }>`
  border: ${spacing(0.5)} solid ${p => (p.focused ? MColor.primary : MColor.border)};
  height: ${spacing(10)};
  background-color: ${MColor.white};
  display: inline-flex;
  align-items: center;
  border-radius: ${spacing(5)};
  box-sizing: border-box;
`

FieldInteger.useClasses = makeStyles(() => ({
  minus: {
    flex: '0 0 auto',
    backgroundColor: MColor.white,
    color: MColor.primary,
    borderRadius: '50%',
    width: spacing(9),
    height: spacing(9),
  },
  plus: {
    flex: '0 0 auto',
    backgroundColor: MColor.primary,
    color: MColor.white,
    borderRadius: '50%',
    width: spacing(9),
    height: spacing(9),
  },
  plusDisabled: {
    flex: '0 0 auto',
    backgroundColor: MColor.primary,
    opacity: '50%',
    color: MColor.white,
    borderRadius: '50%',
    width: spacing(9),
    height: spacing(9),
  },
}))
