import { gql } from '@apollo/client'

export const REPORT_UPLOAD_AND_TRANSFER_CARRIERS_TO_POSITION = gql`
  mutation uploadAndTransferCarriersToPosition(
    $originJobInventoryDetailId: ID!
    $carrierCodesAndQuantities: [CarrierCodeAndQuantityInput!]!
    $destinationPositionId: ID!
    $resourceId: ID!
    $destinationPhase: String
    $workItemId: ID
  ) {
    uploadAndTransferCarriersToPositionGH(
      originJobInventoryDetailId: $originJobInventoryDetailId
      carrierCodesAndQuantities: $carrierCodesAndQuantities
      destinationPositionId: $destinationPositionId
      resourceId: $resourceId
      destinationPhase: $destinationPhase
      workItemId: $workItemId
    ) {
      problem
      succeeded
      failed
      destinationJidIds
      workItemId
      autoFinished
    }
  }
`
