import { gql } from '@apollo/client'

export const REPORT_SCRAP = gql`
mutation reportScrap(
    $jobInventoryDetailId: ID!
    $reasonId: ID!
    $quantity: Float!
    $resourceId: ID!
  ) {
    scrapJobInventoryDetailGH(
      jobInventoryDetailId: $jobInventoryDetailId
      reasonId: $reasonId
      quantity: $quantity
      resourceId: $resourceId
    ) {
      id
      remainingQuantity
    }
  }
  `