import React, { CSSProperties } from 'react'

export const List = ({ children }: { children?: React.ReactNode }) => <ul className={`ml-list`}>{children}</ul>
export const ListItem = ({
  icon,
  style,
  primary,
  secondary,
  action,
  onClick,
}: {
  icon?: React.ReactNode
  style?: CSSProperties
  primary: React.ReactNode
  secondary?: React.ReactNode
  action?: React.ReactNode
  onClick?: React.MouseEventHandler<HTMLLIElement>
}) => {
  return (
    <ListItem.Container style={style} onClick={onClick}>
      {icon && <ListItem.Icon>{icon}</ListItem.Icon>}
      <ListItem.Content>
        <ListItem.Primary>{primary}</ListItem.Primary>
        <ListItem.Secondary>{secondary}</ListItem.Secondary>
      </ListItem.Content>
      {action && <ListItem.Action>{action}</ListItem.Action>}
    </ListItem.Container>
  )
}
ListItem.Container = ({
  children,
  style,
  dataCy,
  onClick,
  onMouseDown,
}: {
  children?: React.ReactNode
  style?: CSSProperties
  dataCy?: string
  onMouseDown?: React.MouseEventHandler<HTMLLIElement>
  onClick?: React.MouseEventHandler<HTMLLIElement>
}) => (
  <li style={style} data-cy={dataCy} className={`ml-list-item`} onClick={onClick} onMouseDown={onMouseDown}>
    {children}
  </li>
)
ListItem.Icon = ({ children }: { children?: React.ReactNode }) => <div className={`ml-list-item-icon`}>{children}</div>
ListItem.Content = ({ children }: { children?: React.ReactNode }) => (
  <div className={`ml-list-item-content`}>{children}</div>
)
ListItem.Primary = ({ children }: { children?: React.ReactNode }) => (
  <div className={`ml-list-item-primary`}>{children}</div>
)
ListItem.Secondary = ({ children }: { children?: React.ReactNode }) => (
  <div className={`ml-list-item-secondary`}>{children}</div>
)
ListItem.Action = ({ children }: { children?: React.ReactNode }) => <div>{children}</div>
