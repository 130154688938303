import { useTranslation } from 'react-i18next'
import { Button } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { MDialogContentText } from '../../mprise-light/Dialog'
import { MColor, MFlexBlock, MText, MTextColor } from '@mprise/react-ui'
import { DialogWithoutButton } from '../../components/Dialog'

export const AARConfirmFinishDialog = ({
  open,
  onConfirmFinish,
  onCancelFinish,
}: {
  open: boolean
  onConfirmFinish: () => void
  onCancelFinish: () => void
}) => {
  const { t } = useTranslation()
  const classes = useStyles()

  return (
    <DialogWithoutButton open={open} title={t(`CONFIRM`)} onSubmit={() => {}} onClose={() => {}}>
      <MDialogContentText>
        <MFlexBlock padding={8} vertical style={{ color: MTextColor.dark }}>
          <MText block style={{ lineHeight: '2rem' }}>
            {t('CONFIRM_FINISH_ARE_YOU_SURE_AAR')}
          </MText>
          <MFlexBlock justifyContent='space-evenly' padding={6}>
            <Button
              variant='contained'
              onClick={onConfirmFinish}
              className={classes.confirmButton}
              style={{ backgroundColor: MColor.high }}
            >
              {t(`YES`)}
            </Button>
            <Button
              variant='contained'
              onClick={onCancelFinish}
              className={classes.confirmButton}
              style={{ backgroundColor: MColor.primary }}
            >
              {t(`NO`)}
            </Button>
          </MFlexBlock>
        </MFlexBlock>
      </MDialogContentText>
    </DialogWithoutButton>
  )
}

const useStyles = makeStyles(() => ({
  confirmButton: {
    backgroundColor: MColor.high,
    color: MTextColor.bright,
    fontSize: '1.5rem',
    borderRadius: '0.2rem',
    margin: '0.5rem',
    width: '6rem',
    padding: '0.2rem',
    '&:hover': {
      opacity: '0.8',
    },
  },
}))
