import { gql } from '@apollo/client'

export const FragmentItemConsumption = gql`
  fragment FragmentItemConsumption on WorkItemConsumption {
    id

    fulfilled
    planned {
      id
      trackingIds {
        id
        code
        warehouse {
          id
          status {
            id
            name
          }
        }
      }
      nextStatus {
        id
      }
      item {
        id
      }
      itemDescription
      itemNumber
      lotNumbers {
        lotNumber
        quantity
      }
      quantity
      quantityUnit
      lotNumbersRequired
      trackingIdRequired
      variantCode
    }
    reported {
      id
      trackingIds {
        id
        code
        status
        warehouse {
          id
          status {
            id
            name
          }
        }
      }
      itemDescription
      itemNumber
      lotNumbers {
        lot {
          id
        }
        lotNumber
        quantity
      }
      quantity
      quantityUnit
      trackingIdRequired
      variantCode
      resource {
        id
        name
        code
      }
    }
  }
`
