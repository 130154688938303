import { gql } from '@apollo/client'

export const SPACING_METHODS_BY_FILTER = gql`
  query spacingMethodsByFilter($filter: SpacingMethodFilter, $limit: Int, $offset: Int) {
    spacingMethods(filter: $filter, limit: $limit, offset: $offset) {
      page {
        id
        description
        code
      }
      total
    }
  }
`

export const SPACING_METHOD_BY_CODE = gql`
  query spacingMethodByCode($filter: SpacingMethodFilter) {
    spacingMethod(filter: $filter) {
      id
      description
      code
    }
  }
`
