import { gql } from '@apollo/client'

export const JOB_BY_CODE = gql`
  query jobByCode($filter: JobFilter) {
    job(filter: $filter) {
      id
      name
      code
      status
      item {
        id
        name
      }
    }
  }
`
