import { gql } from '@apollo/client'

export const REPORT_ITEM_OUTPUT = gql`
  mutation reportItemOutput($workItemId: ID!, $taskId: ID!, $itemOutputId: ID!, $values: WorkItemOutputInput!) {
    registerItemOutputNoTrid(workItemId: $workItemId, taskId: $taskId, itemOutputId: $itemOutputId, values: $values) {
      taskId
      autoFinished
    }
  }
`
