import { FormControl, FormControlLabel, FormGroup, FormLabel, Radio } from '@mui/material'
import { MFlexBlock } from '@mprise/react-ui'
import { FieldProps } from 'formik'

export const RadioButtonField = ({
  field: { name, onBlur, value },
  form,
  label,
  description,
}: FieldProps & { label?: string; description?: string }) => {
  return (
    <MFlexBlock>
      <FormControl margin='normal' component='fieldset' variant='standard' className='gh-formfield-title'>
        <FormLabel component='legend' style={{ fontSize: '1rem' }}>
          {label}
        </FormLabel>
        <FormGroup>
          <FormControlLabel
            control={
              <Radio
                name={name}
                color='primary'
                checked={value}
                onChange={(_, c) => form.setFieldValue(name, c, true)}
                onBlur={onBlur}
                style={{ padding: '1rem' }}
              />
            }
            label={description}
            labelPlacement='top'
          />
        </FormGroup>
      </FormControl>
    </MFlexBlock>
  )
}
