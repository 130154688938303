import { formatUnitOfMeasure } from '../../shared/formats'
import { Job, JobInventoryDetail } from '../../shared/interfaces'
import { DECIMAL_FRACTION_DIGITS } from '../../mprise-light/FieldDecimal'

export const JobDetailsCard = ({
  job,
  jobInventoryDetails,
}: {
  job: Job
  jobInventoryDetails: JobInventoryDetail[]
}) => {
  const remainingQuantity = jobInventoryDetails.map(jid => jid.remainingQuantity).reduce((x, y) => x + y, 0)
  const roundedRemainingQuantity = remainingQuantity.toFixed(DECIMAL_FRACTION_DIGITS)
  const unitOfMeasure = formatUnitOfMeasure(jobInventoryDetails[0]?.unitOfMeasure)

  return (
    <div className='gh-jobdetail-card'>
      <div className='gh-jobdetail-row'>
        <div>{job?.code}</div>
        <div>
          # {roundedRemainingQuantity}&nbsp;{unitOfMeasure}
        </div>
      </div>
      <div className='gh-jobdetail-row'>
        <div>{job?.name}</div>
      </div>
      <div className='gh-jobdetail-row'>
        <div>{job?.item?.name}</div>
      </div>
    </div>
  )
}
