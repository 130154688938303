import { gql } from '@apollo/client'

export const REASONS_BY_FILTER = gql`
  query reasonsByFilter($filter: ReasonFilter, $limit: Int, $offset: Int) {
    reasons(filter: $filter, limit: $limit, offset: $offset) {
      page {
        id
        code
        name
      }
      total
    }
  }
`

export const REASON_BY_CODE = gql`
  query reasonByCode($filter: ReasonFilter) {
    reason(filter: $filter) {
      id
      name
      code
    }
  }
`
