import { ReactNode } from 'react'
import { useTranslation } from 'react-i18next'
import { Helmet } from 'react-helmet'
import { mdiBarcodeScan } from '@mdi/js'
import { Icon } from '@mdi/react'
import { IconButton, Switch } from '@mui/material'
import { MpriseAuthProvider } from '@mprise/react-auth'
import { useAppSettingsContext } from '../../context/AppSettingsContext'
import { PageHeader } from '../../components/Header'
import { MaterialIcon } from '../../components/Icon'
import { List, ListItem } from '../../components/List'
import { Section, SectionList } from '../../components/Section'
import { useHistory } from '../../shared/use-history'
import { StatusText } from '../../components/status-text'
import { MColor } from '@mprise/react-ui'

export const SettingsRoute = () => {
  const h = useHistory()
  const { t } = useTranslation()

  const { mpriseId } = MpriseAuthProvider.useContext()
  const handleSignout = () => {
    localStorage.removeItem('__DEFAULT_COMPANY_GH_MS')
    localStorage.removeItem('__RESOURCE_GH_MS')
    localStorage.removeItem('filterValues')
    sessionStorage.clear()
    mpriseId?.logout()
  }

  const handleCancel = () => {
    h.push('/')
  }

  const { user } = MpriseAuthProvider.useContext()
  const {
    company,
    language,
    resource,
    scanSetting,
    numberFormat,
    defaultNumberInputMethod,
    showPositionWhileSorting,
    setShowPositionWhileSorting,
  } = useAppSettingsContext()

  const chevronRightIcon = (
    <MaterialIcon value='chevron_right' style={{ marginRight: '0.5rem', verticalAlign: 'middle' }} />
  )

  return (
    <>
      <Helmet title={t('TITLE_SETTINGS')} />

      <PageHeader title={t('TITLE_SETTINGS')} onCancel={handleCancel} />
      <SectionList>
        <Section>
          <List>
            <ListItem
              icon={
                <IconButton size='small'>
                  <MaterialIcon value='person' style={{ fontSize: '2rem' }} />
                </IconButton>
              }
              primary={user?.name ?? user?.email ?? '-'}
            />
          </List>
        </Section>

        <Section>
          <List>
            <ListItem
              icon={
                <IconButton>
                  <MaterialIcon value='language' style={{ fontSize: '2rem' }} />
                </IconButton>
              }
              primary={t('MENU_LANGUAGE')}
              secondary={
                language.name ?? language.id ?? <StatusText status='neutral'>{t('PLACEHOLDER_NOT_SET')}</StatusText>
              }
              action={chevronRightIcon}
              onClick={() => h.push('/settings/language')}
            />
            <ListItem
              icon={
                <IconButton>
                  <MaterialIcon value='business' style={{ fontSize: '2rem' }} />
                </IconButton>
              }
              primary={t('MENU_COMPANY')}
              secondary={
                company?.name ?? company?.id ?? <StatusText status='bad'>{t('PLACEHOLDER_NOT_SET')}</StatusText>
              }
              action={chevronRightIcon}
              onClick={() => h.push('/settings/company')}
            />
            <ListItem
              icon={
                <IconButton>
                  <MaterialIcon value='person' style={{ fontSize: '2rem' }} />
                </IconButton>
              }
              primary={t('MENU_RESOURCE')}
              secondary={
                resource?.name ?? resource?.id ?? <StatusText status='neutral'>{t('PLACEHOLDER_NOT_SET')}</StatusText>
              }
              action={chevronRightIcon}
              onClick={() => h.push('/settings/resource')}
            />
            <ListItem
              icon={
                <IconButton>
                  <MaterialIcon value='list' style={{ fontSize: '2rem' }} />
                </IconButton>
              }
              primary={t('MENU_NUMBER_FORMAT')}
              secondary={
                numberFormat.name ??
                numberFormat.id ?? <StatusText status='neutral'>{t('PLACEHOLDER_NOT_SET')}</StatusText>
              }
              action={chevronRightIcon}
              onClick={() => h.push('/settings/numberFormat')}
            />
            <ListItem
              icon={
                <IconButton>
                  <MaterialIcon value='calculate' style={{ fontSize: '2rem' }} />
                </IconButton>
              }
              primary={t('SETTING_DEFAULT_NUMBER_INPUT')}
              secondary={t(defaultNumberInputMethod)}
              action={chevronRightIcon}
              onClick={() => h.push('/settings/defaultNumberInput')}
            />
            <ListItemToggle
              icon={showPositionWhileSorting ? 'lock_open' : 'lock'}
              primary={t('SETTING_SHOW_OR_HIDE_POSITION')}
              secondary={showPositionWhileSorting ? t('ON') : t('OFF')}
              value={showPositionWhileSorting}
              onClick={() => setShowPositionWhileSorting(current => !current)}
            />
            <ListItem
              icon={
                <IconButton>
                  <Icon size={1.2} path={mdiBarcodeScan} style={{ paddingLeft: '2px' }} />
                </IconButton>
              }
              primary={t('SCANNING')}
              secondary={t(`SCANNING_${scanSetting}`)}
              action={chevronRightIcon}
              onClick={() => h.push('/settings/scanning')}
            />
            <ListItem
              icon={
                <IconButton>
                  <MaterialIcon value='description' style={{ fontSize: '2rem' }} />
                </IconButton>
              }
              primary={t('CURRENT_VERSION')}
              secondary={'v1.0.0'}
            />
          </List>
        </Section>

        <Section>
          <List>
            <ListItem
              icon={
                <IconButton size='small'>
                  <MaterialIcon value='exit_to_app' style={{ transform: 'rotate(180deg)' }} />
                </IconButton>
              }
              onClick={handleSignout}
              primary={t('ACTION_SIGN_OUT')}
            />
          </List>
        </Section>
      </SectionList>
    </>
  )
}

const ListItemToggle = ({
  icon: iconName,
  primary,
  secondary,
  value,
  onClick,
}: {
  icon: string
  primary: ReactNode
  secondary?: ReactNode
  value: boolean
  onClick?: () => void
}) => {
  return (
    <ListItem
      icon={
        <IconButton>
          <MaterialIcon value={iconName} style={{ fontSize: '2rem' }} />
        </IconButton>
      }
      primary={primary}
      secondary={secondary}
      style={{ cursor: 'pointer' }}
      action={
        <Switch
          checked={value}
          sx={{
            '&.MuiSwitch-root .Mui-checked': {
              color: MColor.primary,
            },
            '&.MuiSwitch-root .Mui-checked + .MuiSwitch-track': {
              backgroundColor: MColor.primary,
            },
          }}
        />
      }
      onClick={onClick}
    />
  )
}
