import { MFlexItem } from '@mprise/react-ui'
import { Section } from '../../components/Section'
import { Collapse, IconButton } from '@mui/material'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { MaterialIcon } from '../../components/Icon'
import { DateTimeFormatter, Instant, LocalDateTime } from '@js-joda/core'

const dateTimeFormat = DateTimeFormatter.ofPattern('yyyy-MM-dd HH:mm')

export const TimeRegDataCard = ({ timeRegData }: { timeRegData: any[] }) => {
  const { t } = useTranslation()

  const [open, setOpen] = useState(true)

  const firstTimeReg = timeRegData
    .map((x: any) => x?.summary)
    .reduce((a, b) => Date.parse(a?.startTime) - Date.parse(b?.startTime))

  const startedAt = firstTimeReg?.startTime
    ? LocalDateTime.ofInstant(Instant.parse(firstTimeReg.startTime)).format(dateTimeFormat)
    : ''
  const startedBy = firstTimeReg?.totalTimeResources?.namedResourcesTime?.[0]?.resourceName

  const totalTime = minutesToHHMM(
    timeRegData.map((x: any) => x?.summary?.totalMinutes ?? 0).reduce((acc: number, n: number) => acc + n, 0),
  )

  if (!startedAt) {
    return <></>
  }

  return (
    <Section>
      <div className='gh-taskItem-card'>
        <div className='gh-taskItem-card-header' onClick={() => setOpen(current => !current)}>
          <MFlexItem shrink={0}>
            <IconButton style={{ marginLeft: '-12px' }}>
              <MaterialIcon value={'access_time'} />
            </IconButton>
          </MFlexItem>
          <div style={{ flexGrow: 1 }}>{t('TIME_REGISTRATION')}</div>
        </div>
        <Collapse in={open}>
          <div className='gh-taskItem-card-content'>
            <div>{`${t('STARTED_AT')}: ${startedAt}`}</div>
            <div>{`${t('TOTAL_TIME')}: ${totalTime}`}</div>
            {startedBy && <div>{`${t('STARTED_BY')}: ${startedBy}`}</div>}
          </div>
        </Collapse>
      </div>
    </Section>
  )
}

const minutesToHHMM = (totalMinutes: number) => {
  let hours: number | string = Math.floor(totalMinutes / 60)
  let minutes: number | string = Math.floor(totalMinutes - (hours * 3600) / 60)

  hours = `${hours}`.padStart(2, '0')
  minutes = `${minutes}`.padStart(2, '0')

  return `${hours}:${minutes}`
}
