import React from 'react'
import { Tab, Tabs, styled } from '@mui/material'
import { MColor } from '@mprise/react-ui'

export const StyledTabs = styled(Tabs)(({ theme }) => ({
  root: {
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3),
    marginTop: theme.spacing(1),
  },
  indicator: {
    backgroundColor: MColor.primary,
  },
}))

export const StyledTab = styled((props: any) => <Tab disableRipple {...props} />)(({ theme }) => ({
  root: {
    color: '#364556',
    textTransform: 'none',
    minWidth: 100,
    fontFamily: 'Poppins',
    fontSize: '18px',
    fontWeight: 400,
    marginRight: theme.spacing(4),
    overflow: `visible` /* in order that <Badge/> will show over boundaries */,
    '&:hover': {
      color: MColor.primary,
    },
    '&$selected': {
      color: MColor.primary,
      fontWeight: 400,
    },
    '&:focus': {
      color: MColor.primary,
    },
  },
  selected: {},
}))

export const StyledTabPanel = <T extends unknown>({
  children,
  value,
  selected,
}: {
  children: React.ReactNode
  value: T
  selected: T
}) => {
  return (
    <div role='tabpanel' hidden={value !== selected}>
      {value === selected && children}
    </div>
  )
}
