import React from 'react'
import { useTranslation } from 'react-i18next'

export const SearchInput = ({ onChange, onFocus }: { onChange: (v: string) => void; onFocus?: () => void }) => {
  const { t } = useTranslation()

  const handleChange: React.ChangeEventHandler<HTMLInputElement> = e => {
    e.preventDefault()
    onChange(e.target.value)
  }

  return (
    <input
      type='text'
      className='ml-input'
      onChange={handleChange}
      placeholder={t('TYPE_TO_SEARCH')}
      onFocus={onFocus}
    />
  )
}
