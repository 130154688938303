import { useRef, useState } from 'react'
import { Input, InputProps } from '@mui/material'
import { useMField } from '@mprise/react-ui/dist/controls/mfield/context'
import { FieldInteger, MinusButton, PlusButton } from '../../mprise-light/FieldInteger'

// Copied and adjusted from react-ui MFieldInteger.
export const FieldPercentage = (props: InputProps & { step?: number; max?: number }) => {
  const f = useMField()

  const max = props.max
  const step = props.step ?? 1

  const [focused, setFocused] = useState(false)
  const [plusDisabled, setPlusDisabled] = useState(max === 0)

  const inputRef = useRef<HTMLInputElement>(null)

  const handleMinus = (e: any) => {
    const currentValue = Number(f.value ?? 0)
    const newValue = currentValue - step

    if (currentValue > 0) {
      f.onChange?.(newValue, e)
    }

    if (max && newValue < max && plusDisabled) {
      setPlusDisabled(false)
    }
  }

  const handlePlus = (e: any) => {
    const currentValue = Number(f.value ?? 0)
    const newValue = currentValue + step

    if (max == null || newValue <= max) {
      f.onChange?.(newValue, e)
    } else if (!plusDisabled) {
      setPlusDisabled(true)
    }
  }

  const handleChange = (e: any) => {
    if (/^[0-9]*$/.test(e.target.value)) {
      f.onChange?.(e.target.value ?? '', e)
    }
  }

  const handleFocus = () => {
    setTimeout(() => {
      inputRef.current?.setSelectionRange(-1, -1)
    })
    setFocused(true)
  }
  const handleBlur = () => setFocused(false)

  return (
    <FieldInteger.Container focused={focused} style={{ width: '161px' }}>
      <MinusButton handleMinus={handleMinus} />
      <Input
        name={f.name ?? undefined}
        id={f.id ?? undefined}
        value={f.value === 0 || f.value === '0' ? '' : f.value}
        type='text'
        inputMode='numeric'
        onChange={handleChange}
        onFocus={handleFocus}
        disableUnderline
        onBlur={handleBlur}
        inputProps={{
          pattern: '^[0-9]+$',
          style: { textAlign: 'center' },
          inputMode: 'numeric',
        }}
        endAdornment={[<div> %&nbsp;&thinsp; </div>]}
        inputRef={inputRef}
        {...props}
      />
      <PlusButton handlePlus={handlePlus} disabled={plusDisabled} />
    </FieldInteger.Container>
  )
}
