import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Divider, IconButton } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { mdiMagnify } from '@mdi/js'
import Icon from '@mdi/react'
import { MColor, MFlexBlock, MFlexItem, MText, useMField } from '@mprise/react-ui'
import { ScanningSetting, useAppSettingsContext } from '../../context/AppSettingsContext'
import { SelectPhaseDialog } from '../dialog/SelectPhaseDialog'
import { FieldTitle } from '../../components/FieldTitle'
import { JobInventoryDetail, JobProductionPhase } from '../interfaces'
import { Maybe } from '../enums'

export const FieldPhase = ({
  title,
  phase,
  jobInventoryDetail,
}: {
  title?: string
  phase: Maybe<JobProductionPhase> | undefined
  jobInventoryDetail: Maybe<JobInventoryDetail> | undefined
}) => {
  const { t } = useTranslation()

  const f = useMField()
  const disabled = !jobInventoryDetail
  const { scanSetting } = useAppSettingsContext()
  const enableInstantScan = [ScanningSetting.CAMERA].includes(scanSetting)

  const [open, setOpen] = useState(false)
  const handleOpen = (e: any) => {
    e.stopPropagation()
    e.preventDefault()
    if (!disabled) {
      setOpen(true)
    }
  }
  const handleClose = () => {
    setOpen(false)
  }
  const classes = useStyles()

  return (
    <>
      <FieldTitle disabled={disabled} title={title ?? t('TO_PHASE')} />

      <SelectPhaseDialog
        open={open}
        onClose={handleClose}
        onSave={(phase: any) => {
          f.onChange?.(phase)
          setOpen(false)
        }}
        title={t('ASSIGN_PHASE')}
        jobInventoryDetail={jobInventoryDetail}
      />
      <MFlexBlock bgColor={MColor.white} variant='rounded' alignItems='center' grow={1} gap={2}>
        <MFlexItem
          className={classes.fieldPhase}
          style={disabled ? { borderColor: '#e0e0e0' } : {}}
          onClick={handleOpen}
        >
          <MText
            inline
            style={{
              float: 'left',
              padding: '8px',
              fontSize: '1rem',
              fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
            }}
          >
            {phase?.productionPhase ?? ''}
          </MText>
        </MFlexItem>
        <Divider
          className={classes.divider}
          orientation='vertical'
          style={enableInstantScan ? { marginRight: '56px' } : {}}
        />
        <IconButton onClick={handleOpen}>
          <Icon path={mdiMagnify} size={1} style={disabled ? { color: '#e0e0e0' } : {}} />
        </IconButton>
      </MFlexBlock>
    </>
  )
}

const useStyles = makeStyles(() => ({
  divider: {
    height: 28,
    margin: 4,
  },
  fieldPhase: {
    border: '1px solid #839ab4',
    width: '97%',
    height: '33px',
    borderRadius: '3px',
  },
}))
