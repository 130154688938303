import styled from '@emotion/styled'
import { MColor, MFlexBlock, MFlexItem, MText, MTextColor } from '@mprise/react-ui'
import { formatUnitOfMeasure } from '../formats'
import { useTranslation } from 'react-i18next'
import IconRadioButtonChecked from '@mui/icons-material/RadioButtonChecked'
import IconRadioButtonUnchecked from '@mui/icons-material/RadioButtonUnchecked'
import IconWarning from '@mui/icons-material/Warning'
import { JobInventoryDetail } from '../interfaces'

export type MultiSelectItemClickHandler = (item: JobInventoryDetail, e?: React.MouseEvent) => void

export const MultiSelectJobInventoryCard = ({
  jobInventoryDetail,
  selected,
  disabled,
  onClick,
}: {
  jobInventoryDetail: JobInventoryDetail
  selected: boolean
  disabled: boolean
  onClick: (values: JobInventoryDetail, e?: React.MouseEvent) => void
}) => {
  const { t } = useTranslation()

  const {
    position,
    sortingCode,
    productionPhase,
    remainingQuantity,
    unitOfMeasure,
    carrierCode,
    remQtyPerUnitOfMeasure,
    job,
  } = jobInventoryDetail

  const handleClick: React.MouseEventHandler = e => {
    onClick(jobInventoryDetail, e)
  }

  const radio = selected ? <IconRadioButtonChecked color='primary' /> : <IconRadioButtonUnchecked color='action' />
  const textColor = disabled ? MTextColor.disabled : MTextColor.dark

  return (
    <StyledButton type='button' onClick={handleClick}>
      <MFlexBlock
        alignItems='center'
        gap={4}
        padding={3}
        style={{ position: 'relative', backgroundColor: disabled ? 'transparent' : MColor.white }}
      >
        <MFlexBlock margin={2} width={6}>
          <MFlexItem shrink={0} grow={0}>
            {!disabled && radio}
          </MFlexItem>
        </MFlexBlock>
        <MFlexBlock vertical style={{ width: '100%' }}>
          <MFlexBlock justifyContent='space-between'>
            <MFlexItem>
              <MText inline textVariant='small bold' textColor={textColor}>
                {`${job.code} | ${productionPhase.productionPhase}`}
              </MText>
              {sortingCode && (
                <MText inline textVariant='small' textColor={textColor}>
                  &nbsp; | &nbsp; {sortingCode}
                </MText>
              )}
            </MFlexItem>
            {disabled && (
              <MFlexItem style={{ display: 'flex', justifyContent: 'center', marginRight: 0 }}>
                <IconWarning style={{ color: MColor.high, height: '1rem' }} />
                <MText block textVariant='small bold' textColor={MTextColor.dark}>
                  {t('INCORRECT_ITEM')}
                </MText>
              </MFlexItem>
            )}
          </MFlexBlock>

          <MFlexItem>
            <MText inline textVariant='small bold' textColor={textColor}>
              {remainingQuantity?.toLocaleString('nl-NL')}
            </MText>
            <MText inline textVariant='small' textColor={textColor}>
              {` ${formatUnitOfMeasure(unitOfMeasure)?.toLowerCase()} `}
            </MText>
            {(remQtyPerUnitOfMeasure === 0 || remQtyPerUnitOfMeasure > 1) && (
              <MText inline textVariant='small' textColor={textColor}>
                &nbsp; | &nbsp; {`${remQtyPerUnitOfMeasure}`} {' per UOM'}
              </MText>
            )}
          </MFlexItem>
          <MFlexItem>
            <MText inline textVariant='small' textColor={textColor}>
              {jobInventoryDetail.job.item?.name} &nbsp;
            </MText>
          </MFlexItem>
          <MFlexItem>
            <MText inline textVariant='small bold' textColor={textColor}>
              {position.code} &nbsp;
            </MText>
            {carrierCode && (
              <MText inline textVariant='small' textColor={textColor}>
                | &nbsp; {carrierCode}
              </MText>
            )}
          </MFlexItem>
        </MFlexBlock>
      </MFlexBlock>
    </StyledButton>
  )
}

export const StyledButton = styled.button`
  border: 0;
  display: block;
  width: 100%;
  appearance: none;
  padding: 0;
  border-bottom: 1px lightgrey solid;
  cursor: pointer;
  text-align: inherit;
  position: 'relative';
`
