import { gql } from '@apollo/client'

export const REPORT_SORT_AND_TRANSFER = gql`
  mutation sortAndTransferPositionJobInventoryGH(
    $originJobInventoryDetailId: Int!
    $sortAndTransferDestinations: [SortAndTransferInput!]!
    $resourceId: Int!
    $workItemId: Int
    $taskId: Int
    $destinationPhase: String
  ) {
    sortAndTransferPositionJobInventoryGH(
      originJobInventoryDetailId: $originJobInventoryDetailId
      sortAndTransferDestinations: $sortAndTransferDestinations
      resourceId: $resourceId
      workItemId: $workItemId
      taskId: $taskId
      destinationPhase: $destinationPhase
    ) {
      problem
      succeeded
      failed
      destinationJidIds
      workItemId
      autoFinished
    }
  }
`
