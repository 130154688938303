import { gql } from '@apollo/client'

export const JOB_WITH_PHASES = gql`
  query jobWithProductionPhases($filter: JobFilter) {
    job(filter: $filter) {
      id
      name
      code
      cosmosKey
      item {
        id
        name
      }
      jobProductionPhases {
        id
        name
        productionPhase
        sortingCode
        startingDate
        endDate
        noOfSortSubLines
        parentLineNo
      }
    }
  }
`
