import { gql } from '@apollo/client'

export const GET_APPS = gql`
  query myApplications {
    myApplications {
      id
      name
      publicUrl
      logoUrl
    }
  }
`
