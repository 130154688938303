import { gql } from '@apollo/client'

export const UPDATE_JOB_PRODUCTION_PHASES = gql`
mutation updateJobProductionPhases(
  $jobId: Int!
  $jobProductionPhases: [JobProductionPhaseInput!]
) {
  editJob(id: $jobId, jobProductionPhases: $jobProductionPhases) {
    id
    jobProductionPhases {
      id
      name
      productionPhase
      sortingCode
      startingDate
      endDate
      noOfSortSubLines
      parentLineNo
    }
  }
}
`
