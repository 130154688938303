import React, { ForwardedRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useApolloClient } from '@apollo/client'
import { MAudio, useMField } from '@mprise/react-ui'
import { useAppSettingsContext } from '../../context/AppSettingsContext'
import { makeQueryCallback } from '../apollo'
import { FlashAlerts } from '../flash-alerts'
import { fail } from '../typescript'
import { FieldBarcode } from '../../mprise-light/FieldBarcode'
import { useHistory } from '../use-history'
import { SPACING_METHOD_BY_CODE } from '../../gql/spacing-methods'
import { SelectSpacingMethodDialog, SpacingMethodValue } from '../dialog/SelectSpacingMethodDialog'

export const FieldSpacingMethod = React.forwardRef(({}: {}, ref: ForwardedRef<HTMLInputElement>) => {
  const { t } = useTranslation()
  const h = useHistory()
  const f = useMField()
  const { company: currentCompany, scanSetting } = useAppSettingsContext()
  const companyId = currentCompany?.id ?? h.push('/')
  const apollo = useApolloClient()
  const alerts = FlashAlerts.useAlert()
  const [open, setOpen] = useState(false)
  const handleOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)
  const scanDialogTitle = t('SCAN_SPACING_METHOD')

  return (
    <>
      <SelectSpacingMethodDialog
        open={open}
        onClose={handleClose}
        onSave={(spacingMethod: SpacingMethodValue) => {
          f.onChange?.(spacingMethod)
          setOpen(false)
        }}
        title={t(`SPACING_METHOD`)}
      />
      <FieldBarcode<SpacingMethodValue>
        title={t(`SPACING_METHOD`)}
        scanningSetting={scanSetting}
        disabled={false}
        valueAsText={x => x.code ?? t(`PLACEHOLDER_NOT_SET`)}
        onLookup={async text => {
          if (!companyId) {
            fail('expects company id')
          }
          return await fetchSpacingMethodByCode(
            {
              filter: {
                companyId: +companyId,
                code: text,
              },
            },
            apollo,
          )
        }}
        onError={() => {
          MAudio.scanError()
        }}
        onSuccess={(text, item) => {
          if (text && !item) {
            MAudio.scanError()
            alerts.push(t('NOTIFICATION_NOT_FOUND'), `error`)
          }
        }}
        onExplore={handleOpen}
        scanDialogTitle={scanDialogTitle}
        ref={ref}
      />
    </>
  )
})

const fetchSpacingMethodByCode = makeQueryCallback<any, any>(SPACING_METHOD_BY_CODE)(x => x.spacingMethod ?? null)
