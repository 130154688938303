import { useEffect, useRef, useState } from 'react'
import { Maybe } from '../shared/enums'
import { mdiChevronRight } from '@mdi/js'
import Icon from '@mdi/react'
import { IconButton } from '@mui/material'

export const LineClamper = ({ text }: { text: string }) => {
  const [textIsOverFlowing, setTextIsOverFlowing] = useState(false)
  const [showMore, setShowMore] = useState(false)
  const textRef = useRef(null)

  function isOverflowActive(element: Maybe<HTMLElement>) {
    if (element) {
      return element.offsetHeight < element.scrollHeight || element.offsetWidth < element.scrollWidth
    }
  }

  useEffect(() => {
    if (isOverflowActive(textRef.current)) {
      setTextIsOverFlowing(true)
      return
    }
  }, [isOverflowActive])

  return (
    <div onClick={() => setShowMore(showMore => !showMore)} style={{ display: 'inherit' }}>
      <div ref={textRef} className={showMore ? '' : 'gh-line-clamp-2'}>
        {text}
      </div>
      {textIsOverFlowing && (
        <IconButton style={{ paddingLeft: '1' }}>
          <Icon path={mdiChevronRight} size={1} rotate={showMore ? 270 : 90} />
        </IconButton>
      )}
    </div>
  )
}
