import { useTranslation } from 'react-i18next'
import { MColor } from '@mprise/react-ui'
import { availableNumberFormats, NumberFormatItem, useAppSettingsContext } from '../../context/AppSettingsContext'
import { Card } from '../../components/Card'
import { PageHeader } from '../../components/Header'
import { MaterialIcon } from '../../components/Icon'
import { List, ListItem } from '../../components/List'
import { Section, SectionList } from '../../components/Section'
import { useHistory } from '../../shared/use-history'

export const SettingsNumberFormatRoute = () => {
  const { t } = useTranslation()
  const h = useHistory()

  const { numberFormat, setNumberFormat } = useAppSettingsContext()

  const handleCancel = () => h.goBack()

  const handleSave = (selected: NumberFormatItem) => {
    setNumberFormat(selected)
    h.goBack()
  }

  return (
    <>
      <PageHeader title={t('TITLE_SETTINGS')} onCancel={handleCancel} />

      <SectionList>
        <Section>
          <Card header={t('TITLE_CHOOSE_NUMBER_FORMAT')}>
            <List>
              {availableNumberFormats.map(x => (
                <ListItem
                  key={x.id}
                  icon={<MaterialIcon value='verified' style={{ fontSize: '2rem' }} />}
                  primary={x.name}
                  action={
                    numberFormat?.id === x.id ? <MaterialIcon value='check' style={{ color: MColor.primary }} /> : null
                  }
                  onClick={() => handleSave(x)}
                />
              ))}
            </List>
          </Card>
        </Section>
      </SectionList>
    </>
  )
}
