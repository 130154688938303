import { createTheme, ThemeOptions, ThemeProvider } from '@mui/material'
import { fontFamily, px } from '@mprise/react-ui'
import React, { useMemo } from 'react'

// Copied from react-ui because Theme was broken (empty page in browser). Hope to fix in future.

export const MpriseMuiThemeOptions: ThemeOptions = {
  typography: {
    fontFamily: fontFamily,
    fontWeightLight: 300,
    fontWeightMedium: 300,
    fontWeightRegular: 300,
    fontWeightBold: 500,
    body1: { fontSize: px(18) },
    body2: { fontSize: px(18) },
    h1: { fontSize: px(20) },
    h2: { fontSize: px(20) },
    h3: { fontSize: px(20) },
    h4: { fontSize: px(20) },
    h5: { fontSize: px(20) },
    h6: { fontSize: px(20) },
    caption: { fontSize: px(18) },
    subtitle1: { fontSize: px(20) },
    subtitle2: { fontSize: px(18) },
    button: { fontSize: px(18), textTransform: `none` },
  },
  palette: {
    primary: {
      // light: '#35ae6a',
      main: '#009a44',
      // dark: '#026b30'
    },
    secondary: {
      main: '#33cc33',
    },
    background: {
      default: '#F7F7F7',
      paper: '#F7F7F7',
    },
  },
  components: {
    MuiListSubheader: {
      styleOverrides: {
        root: {
          fontWeight: 500,
        },
      },
    },
  },
}

const themeInstance = createTheme(MpriseMuiThemeOptions)
export const MpriseMuiThemeProvider = ({ children }: { children: React.ReactNode }) => {
  const flexGapSupported = useMemo(checkFlexGap, [])
  return (
    <ThemeProvider theme={themeInstance}>
      <div className={`mprise-mui${flexGapSupported ? `` : ` mprise-mui--no-flex-gap`}`}>{children}</div>
    </ThemeProvider>
  )
}

function checkFlexGap() {
  // create flex container with row-gap set
  var flex = document.createElement('div')
  flex.style.display = 'flex'
  flex.style.flexDirection = 'column'
  flex.style.rowGap = '1px'

  // create two, elements inside it
  flex.appendChild(document.createElement('div'))
  flex.appendChild(document.createElement('div'))

  // append to the DOM (needed to obtain scrollHeight)
  document.body.appendChild(flex)
  var isSupported = flex.scrollHeight === 1 // flex container should be 1px high from the row-gap
  document.body.removeChild(flex)

  return isSupported
}
