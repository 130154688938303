import { gql } from '@apollo/client'

export const POSITIONS_BY_FILTER = gql`
  query positionsByFilter($filter: PositionFilter, $limit: Int, $offset: Int) {
    positions(filter: $filter, limit: $limit, offset: $offset) {
      page {
        id
        name
        code
        startPillar
        endPillar
      }
      total
    }
  }
`

export const POSITION_BY_CODE = gql`
  query positionByCode($filter: PositionFilter) {
    position(filter: $filter) {
      id
      name
      code
    }
  }
`
