import { useState } from 'react'
import { IconButton } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { MColor, MFlexBlock, MText, MTextColor } from '@mprise/react-ui'
import { UpdateJobInventoryDialog } from '../../routes/transfer/transfer-carriers/UpdateJobInventoryDialog'
import { MaterialIcon } from '../../components/Icon'
import { Section } from '../../components/Section'
import { useTranslation } from 'react-i18next'
import { ScannedJobInventory } from '../interfaces'

export const ScannedCarriersSection = ({ scanned }: { scanned: ScannedJobInventory[] }) => {
  const { t } = useTranslation()

  const totalCarriers = scanned.length
  const totalQuantity = scanned.reduce((a, b) => a + b.quantity, 0)

  return (
    <Section>
      <MFlexBlock vertical>
        <MFlexBlock vertical padding={[2, 2, 2, 3]} style={{ background: MColor.paper }}>
          <MText inline textColor={MTextColor.dark}>
            {`${t('SCANNED_ENTRIES')} (${totalCarriers}) - ${t('QUANTITY')}: ${totalQuantity}`}
          </MText>
        </MFlexBlock>
        <MFlexBlock vertical padding={[0, 2]}>
          {scanned.length > 0 && scanned.map(entry => <ScannedJobInventoryRow key={entry.jidId} entry={entry} />)}
        </MFlexBlock>
      </MFlexBlock>
    </Section>
  )
}

const ScannedJobInventoryRow = ({ entry }: { entry: ScannedJobInventory }) => {
  const [showEditDialog, setShowUpdateDialog] = useState<boolean>(false)

  const handleEdit = () => setShowUpdateDialog(true)
  const handleStopEdit = () => setShowUpdateDialog(false)

  const classes = useStyles()

  return (
    <MFlexBlock alignItems='center' justifyContent='space-between' padding={[2]} gap={5} className={classes.divider}>
      <MFlexBlock justifyContent='space-between' gap={2} style={{ width: '100%' }}>
        <MFlexBlock vertical gap={1.2}>
          <MFlexBlock gap={8}>
            <RowTextItem text={entry.carrierCode} />
            <RowTextItem text={entry.jobName} />
          </MFlexBlock>
          <MFlexBlock>
            <RowTextItem text={entry.itemName} />
          </MFlexBlock>
        </MFlexBlock>
        <MFlexBlock alignContent='end' alignItems='center'>
          <RowTextItem text={entry.quantity.toLocaleString('nl-NL')} />
        </MFlexBlock>
      </MFlexBlock>
      <MFlexBlock style={{ width: 'fit-content' }}>
        <IconButton onClick={handleEdit}>
          <MaterialIcon value='edit' style={{ fontSize: '1.5rem' }} />
        </IconButton>
        <UpdateJobInventoryDialog entry={entry} open={showEditDialog} handleClose={handleStopEdit} />
      </MFlexBlock>
    </MFlexBlock>
  )
}

const RowTextItem = ({ text }: { text: string | number }) => {
  return (
    <MText inline textVariant='small bold' textColor={MTextColor.dark}>
      {text}
    </MText>
  )
}

const useStyles = makeStyles(() => ({
  divider: {
    '&:not(:first-child)': {
      borderTop: '1px #ccd1d4 solid',
    },
  },
}))
