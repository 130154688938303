import { gql } from '@apollo/client'

export const START_TASK = gql`
  mutation startTask($workItemId: Int!, $taskId: Int!, $currentResourceId: Int!) {
    startTask(workItemId: $workItemId, taskId: $taskId, currentResourceId: $currentResourceId) {
      id
      status
      cosmosKey
      workItem {
        id
        status
        cosmosKey
      }
    }
  }
`

export const STOP_TASK = gql`
  mutation stopTask($workItemId: Int!, $taskId: Int!, $currentResourceId: Int!) {
    stopTask(workItemId: $workItemId, taskId: $taskId, currentResourceId: $currentResourceId) {
      id
      status
      cosmosKey
      workItem {
        id
        status
        cosmosKey
      }
    }
  }
`

export const FINISH_TASK = gql`
  mutation finishTask($workItemId: Int!, $taskId: Int!, $currentResourceId: Int!) {
    finishTask(workItemId: $workItemId, taskId: $taskId, currentResourceId: $currentResourceId) {
      id
      status
      cosmosKey
      workItem {
        id
        status
        cosmosKey
      }
    }
  }
`
