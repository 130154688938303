import { gql } from '@apollo/client'

export const WORKITEM_FOR_REDIRECT = gql`
  query workItemForRedirect($where: [OptionsWhere]) {
    nworkItem(where: $where) {
      id
      phaseCode
      tasks {
        id
        status
        types
        taskOptions
      }
      jobInventoryPutAway(frontend: true) {
        planned {
          id
        }
      }
      itemConsumption(frontend: true) {
        planned {
          id
        }
      }
      itemOutput {
        planned {
          id
        }
      }
    }
  }
`
