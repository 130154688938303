import React, { ForwardedRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useApolloClient } from '@apollo/client'
import { MAudio, useMField } from '@mprise/react-ui'
import { useAppSettingsContext } from '../../context/AppSettingsContext'
import { makeQueryCallback } from '../apollo'
import { FlashAlerts } from '../flash-alerts'
import { fail } from '../typescript'
import { FieldBarcode } from '../../mprise-light/FieldBarcode'
import { CarrierValue, SelectCarrierCodeDialog } from '../dialog/SelectCarrierCode'
import { useHistory } from '../use-history'
import { CARRIER_BY_CODE } from '../../gql/carriers'

export const FieldCarrierCode = React.forwardRef(
  (
    {
      title,
    }: {
      title: string
    },
    ref: ForwardedRef<HTMLInputElement>,
  ) => {
    const { t } = useTranslation()
    const h = useHistory()
    const f = useMField()
    const { company: currentCompany, scanSetting } = useAppSettingsContext()
    const companyId = currentCompany?.id ?? h.push('/')
    const apollo = useApolloClient()
    const alerts = FlashAlerts.useAlert()
    const [open, setOpen] = useState(false)
    const handleOpen = () => setOpen(true)
    const handleClose = () => setOpen(false)
    const scanDialogTitle = t('SCAN_CARRIER_CODE')

    return (
      <>
        <SelectCarrierCodeDialog
          open={open}
          onClose={handleClose}
          onSave={(carrierCode: CarrierValue) => {
            f.onChange?.(carrierCode)
            setOpen(false)
          }}
          title={title}
        />
        <FieldBarcode<CarrierValue>
          title={t(`CARRIER_CODE`)}
          scanningSetting={scanSetting}
          disabled={false}
          valueAsText={x => x.code ?? t(`PLACEHOLDER_NOT_SET`)}
          onLookup={async text => {
            if (!companyId) {
              fail('expects company id')
            }
            return await fetchCarrierByCode(
              {
                filter: {
                  companyId: +companyId,
                  code: text,
                },
              },
              apollo,
            )
          }}
          onError={() => {
            MAudio.scanError()
          }}
          onSuccess={(text, item) => {
            if (text && !item) {
              MAudio.scanError()
              alerts.push(t('NOTIFICATION_NOT_FOUND'), `error`)
            }
          }}
          onExplore={handleOpen}
          scanDialogTitle={scanDialogTitle}
          ref={ref}
        />
      </>
    )
  },
)

const fetchCarrierByCode = makeQueryCallback<any, any>(CARRIER_BY_CODE)(x => x.positionCarrier ?? null)
