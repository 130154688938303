import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useAppSettingsContext } from '../../context/AppSettingsContext'
import { Card } from '../../components/Card'
import { MaterialIcon } from '../../components/Icon'
import { List, ListItem } from '../../components/List'
import { SearchInput } from '../../components/SearchInput'
import { Section, SectionList } from '../../components/Section'
import { useDebounceValue } from '../../shared/debounce-value'
import { DialogWithoutButton } from '../../components/Dialog'
import { FlashAlerts } from '../flash-alerts'
import { useLazyQuery } from '@apollo/client'
import { GET_RESOURCES } from '../../gql/resources'
import { QueryErrorMessage } from '../apollo'

export const SelectResourceDialog = () => {
  const { t } = useTranslation()
  const alerts = FlashAlerts.useAlert()

  const [search, setSearch] = useState(``)
  const debouncedSearch = useDebounceValue(search, 500)

  const [getResources, { error, data: resources, refetch, loading }] = useLazyQuery(GET_RESOURCES, {
    onError: console.error,
  })

  const { company, resource: defaultResource, setResource } = useAppSettingsContext()

  const [open, setOpen] = useState(!defaultResource)

  useEffect(() => {
    getResources({
      variables: {
        filter: {
          companyId: +company?.id!,
          ...(debouncedSearch && { searchString: debouncedSearch }),
          type: 'Person',
          blocked: false,
          removed: false,
        },
        limit: 50,
        offset: 0,
      },
    })
  }, [company?.id, debouncedSearch, getResources])

  return (
    <DialogWithoutButton open={open} title={t('TITLE_CHOOSE_RESOURCE')} onClose={() => {}} onSubmit={() => {}}>
      <QueryErrorMessage query={{ error, refetch, loading }} />
      <SectionList>
        <Section>
          <Card>
            <div className='ml-search'>
              <SearchInput onChange={setSearch} />
            </div>
            <List>
              {resources && resources.resources.page ? (
                resources.resources.page.map((resource: any, idx: number) => (
                  <ListItem
                    key={resource?.id ?? idx}
                    icon={<MaterialIcon value='person' style={{ fontSize: `2rem` }} />}
                    primary={resource?.name ?? resource?.name}
                    onClick={() => {
                      resource &&
                        setResource({
                          id: resource.id,
                          name: resource.name ?? ``,
                          code: resource.code ?? ``,
                          cosmosKey: resource.cosmosKey ?? ``,
                        })
                      alerts.push(`${t('RESOURCE')} ${resource.name} ${t('IS_SELECTED')}`, `success`)
                      setOpen(false)
                    }}
                  />
                ))
              ) : (
                <p>No resources found.</p>
              )}
            </List>
          </Card>
        </Section>
      </SectionList>
    </DialogWithoutButton>
  )
}
